import { action, computed, observable, runInAction } from 'mobx'
import TransportLayer, { MovieFromServer } from '#services/TransportLayer'

export class Movie {
  public _id: string
  public title: string
  public subtitle: string
  public posterArtworkPath: string
  public assetPath: string
  public year: number
  public durationInSeconds: number
  public description: string

  public constructor(
    _id: string,
    title: string,
    subtitle: string,
    posterArtworkPath: string,
    assetPath: string,
    year: number,
    durationInSeconds: number,
    description: string,
  ) {
    this._id = _id
    this.title = title
    this.subtitle = subtitle
    this.posterArtworkPath = posterArtworkPath
    this.assetPath = assetPath
    this.year = year
    this.durationInSeconds = durationInSeconds
    this.description = description
  }
}

export default class MovieStore {
  @observable public moviesByIdMap = observable.map<string, Movie>()

  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  @computed public get movies(): Movie[] {
    return Object.values(this.moviesByIdMap.toJSON())
  }

  @action public getMovies = async (): Promise<void> => {
    try {
      const movies = await this.transportLayer.getMovies()
      runInAction(
        (): void => {
          movies.forEach((movie): void => this.updateMovieFromServer(movie))
        },
      )
    } catch (e) {
      console.log('e: ', e)
      runInAction(
        (): void => {
          // this.loading = false
        },
      )

      // TODO: show error
    }
  }

  public getMovieById = (_id: string): Movie | undefined => {
    return this.moviesByIdMap.get(_id)
  }

  // TODO: any type
  private updateMovieFromServer = (movieFromServer: MovieFromServer): void => {
    const movieInStore = this.moviesByIdMap.get(movieFromServer._id)

    if (!movieInStore) {
      const movie = new Movie(
        movieFromServer._id,
        movieFromServer.title,
        movieFromServer.subtitle,
        movieFromServer.posterArtworkPath,
        movieFromServer.assetPath,
        movieFromServer.year,
        movieFromServer.durationInSeconds,
        movieFromServer.description,
      )

      // TODO: make sure these are alphatized
      this.moviesByIdMap.set(movie._id, movie)
      // this.albums.push(album)
    } else {
      // TODO:
      // albumInStore.updateFromServer(albumFromServer)
    }
  }
}
