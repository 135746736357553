import React from 'react'
import MenuCaret from './assets/menu-caret.png'
import * as S from './style'

interface Props {
  triggerElement: React.RefObject<any>
  onOutsideClick: () => void
}

export default class Menu extends React.PureComponent<Props, {}> {
  public componentDidMount(): void {
    window.addEventListener('click', this.handleOutsideClick)
  }

  public componentWillUnmount(): void {
    window.removeEventListener('click', this.handleOutsideClick)
  }

  public render(): JSX.Element {
    const { children } = this.props

    return (
      <S.Container>
        <S.MenuContainer>
          <img src={MenuCaret} alt="caret" />
          {children}
        </S.MenuContainer>
      </S.Container>
    )
  }

  private handleOutsideClick = (e: MouseEvent): boolean => {
    const { triggerElement } = this.props

    if (triggerElement.current && triggerElement.current.contains(e.target)) {
      return false
    }

    e.preventDefault()
    e.stopPropagation()
    this.props.onOutsideClick()

    return true
  }
}
