import styled, { keyframes } from 'styled-components'
import Colors from '#styles/colors'
// import Fonts from '#styles/fonts'

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const imageKenburns = keyframes`
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  95% {
      transform: scale(1.2, 1.2) translate(-5%, -3%);
      animation-timing-function: ease-in;
      opacity: 1;
  }
  100% {
      transform: scale(2, 2) translate(-4%, -2%);
      opacity: 0;
  }
`

const metaKenburns = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  95% {
      opacity: 0;
  }
  100% {
      opacity: 0;
  }
`

const metaTextKenburns = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    transform: scale(1, 1) translate(10%, 0);
  }
  15% {
    opacity: 1;
    transform: scale(1, 1) translate(0, 0);
  }
`

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: ${imageKenburns} 24s;
`

export const MetaContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${Colors.util.fade({ color: Colors.pureBlack, amount: 0.5 })};
  padding: 1vw;
  z-index: 10;
  animation: ${metaKenburns} 24s;
  opacity: 0;

  h1,
  h2,
  p {
    animation: ${metaTextKenburns} 24s;
    width: 75%;
  }
`
