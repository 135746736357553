import React from 'react'
import { AutoplayPOI } from '#components/Map'
import POIMeta from '#components/POIMeta'
import * as S from './style'

interface Props {
  poi: AutoplayPOI
  onCancel: () => void
}

interface State {
  loaded: boolean
}

class AutoplayPOIOverlay extends React.PureComponent<Props, State> {
  public state = {
    loaded: false,
  }

  public componentDidMount(): void {
    const image = new Image()

    image.addEventListener(
      'load',
      (): void => {
        this.setState({
          loaded: true,
        })
      },
      false,
    )

    image.src = this.props.poi.assetURL
    // image.src = 'http://gojiair.com:3003/poi/4k/us/fl/us_fl_orlando_is_21290339.jpg'
  }

  public render(): JSX.Element | null {
    const { poi } = this.props
    const { loaded } = this.state

    if (!loaded) {
      return null
    }

    return (
      <S.Container onClick={this.props.onCancel}>
        <S.Image src={this.props.poi.assetURL} />
        {/* <S.Image src="http://gojiair.com:3003/poi/4k/us/fl/us_fl_orlando_is_21290339.jpg" /> */}
        <S.MetaContainer>
          <POIMeta title={poi.title} subtitle={poi.subtitle} description={poi.description} />
        </S.MetaContainer>
      </S.Container>
    )
  }
}

export default AutoplayPOIOverlay
