import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled.button`
  ${Fonts.Body1Bold.Normal};
  color: ${Colors.offWhite};
  border-radius: 3px;
  background: ${Colors.accent2};
  border: none;
  padding: 8px 16px;
  display: flex;

  &:hover {
    background: ${Colors.util.hover(Colors.accent2)};
    cursor: pointer;
  }

  &:active {
    background: ${Colors.util.pressed(Colors.accent2)};
  }
`
