import styled from 'styled-components'
import { animated } from 'react-spring'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled.div`
  background: ${Colors.util.fade({ color: Colors.black1, amount: 0.25 })};
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: ${({ large }: { large: boolean }): string => (large ? '0 32px' : '0 16px')};
  height: ${({ large }: { large: boolean }): string => (large ? '64px' : '32px')};
  align-items: center;
`

export const Label = styled.div`
  ${({ large }: { large: boolean }): string =>
    large
      ? `${Fonts.H1.Normal}; padding-right: 16px;`
      : `${Fonts.Caption.Normal}; padding-right: 8px;`};
  color: ${Colors.util.fade({ color: Colors.white, amount: 0.4 })};
`

export const Value = styled.div`
  ${({ large }: { large: boolean }): string => (large ? Fonts.Display.Normal : Fonts.Body1.Normal)};
  color: ${Colors.white};
`

export const Section = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;

  &:nth-child(2) {
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
  }
`

export const AnimatedContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
`
