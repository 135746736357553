import * as dateFns from 'date-fns'
import React from 'react'
import { observer } from 'mobx-react'
import { connect, ConnectedComponent } from '#utils/index'
import MusicStore from '#stores/MusicStore'
import GeoRadioStore from '#stores/GeoRadioStore'
import Icon from '#components/Icon'
import * as S from './style'

interface Stores {
  musicStore: MusicStore
  geoRadioStore: GeoRadioStore
}

interface State {
  audioTrackPositionInSeconds: number
}

@connect('musicStore')
@observer
class MusicPlayer extends ConnectedComponent<{}, Stores, State> {
  public componentWillUnmount(): void {
    this.stores.musicStore.destroyAudioTrack()
  }

  public render(): JSX.Element {
    const {
      musicStore: {
        currentlySelectedAlbum: album,
        currentlySelectedTrack: track,
        previousTrackInAlbum,
        nextTrackInAlbum,
        isPlaying,
        audioTrackPositionInSeconds,
      },
    } = this.stores

    return (
      <S.Container>
        <div>
          <S.Track>{track && track.title}</S.Track>
          <S.Artist>{album && album.artistName}</S.Artist>
        </div>
        <S.ControlsContainer>
          <S.Controls>
            <S.PreviousNextButton
              disabled={!previousTrackInAlbum}
              onClick={this.handlePreviousClick}
            >
              <Icon type="previous-track" />
            </S.PreviousNextButton>
            <S.PlayPauseButton disabled={!track} onClick={this.handlePlayPauseClick}>
              <Icon type={isPlaying ? 'pause' : 'play'} />
            </S.PlayPauseButton>
            <S.PreviousNextButton disabled={!nextTrackInAlbum} onClick={this.handleNextClick}>
              <Icon type="next-track" />
            </S.PreviousNextButton>
          </S.Controls>
          <S.ScrubberContainer>
            <S.Timestamp>
              {dateFns.format(dateFns.addSeconds(0, audioTrackPositionInSeconds), 'm:ss')}
            </S.Timestamp>
            <S.Scrubber>
              {track && (
                <S.ScrubberHighlight
                  style={{
                    width: `${(audioTrackPositionInSeconds / track.durationInSeconds) * 100}%`,
                  }}
                />
              )}
            </S.Scrubber>
            <S.Timestamp>
              {dateFns.format(dateFns.addSeconds(0, track ? track.durationInSeconds : 0), 'm:ss')}
            </S.Timestamp>
          </S.ScrubberContainer>
        </S.ControlsContainer>
        <div />
      </S.Container>
    )
  }

  private handlePreviousClick = (): void => {
    const { changeTrack, previousTrackInAlbum } = this.stores.musicStore

    if (previousTrackInAlbum) {
      changeTrack(previousTrackInAlbum.albumId, previousTrackInAlbum._id)
    }
  }

  private handleNextClick = (): void => {
    const { changeTrack, nextTrackInAlbum } = this.stores.musicStore

    if (nextTrackInAlbum) {
      changeTrack(nextTrackInAlbum.albumId, nextTrackInAlbum._id)
    }
  }

  private handlePlayPauseClick = (): void => {
    const { currentlySelectedTrack, isPlaying, pauseTrack, resumeTrack } = this.stores.musicStore

    if (!currentlySelectedTrack) {
      return
    }

    if (isPlaying) {
      pauseTrack()
    } else {
      resumeTrack()
    }
  }
}

export default MusicPlayer
