import React from 'react'
import ReactPlayer from 'react-player'
import Constants from '#constants/index'
import { Movie } from '#stores/MovieStore'
import TopNav from '#components/TopNav'
import * as S from './style'

interface Props {
  movie?: Movie
}

class MoviePlayerView extends React.PureComponent<Props, {}> {
  public render(): JSX.Element | null {
    const { movie } = this.props

    if (!movie) {
      return null
    }

    return (
      <S.Container>
        <TopNav label="Back" to={`/entertainment/movies/${movie._id}`} />
        <S.ContentContainer>
          <ReactPlayer
            url={`${Constants.REACT_APP_MEDIA_ASSETS_URL}/${movie.assetPath}`}
            width="100%"
            height="100%"
            playing
            controls
          />
        </S.ContentContainer>
      </S.Container>
    )
  }
}

export default MoviePlayerView
