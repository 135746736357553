import React from 'react'
import * as S from './style'

interface Props {
  artworkURL: string
  showMetaData?: boolean
  showMenu?: boolean
  aspectRatio?: number
  title?: string
  subtitle?: string
}

class AVCard extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const {
      artworkURL,
      showMetaData = false,
      // showMenu = false,
      aspectRatio = 1 / 1,
      title = '',
      subtitle = '',
    } = this.props

    return (
      <S.Container aspectRatio={aspectRatio}>
        <S.Artwork style={{ backgroundImage: `url(${artworkURL})` }} />
        {showMetaData ? (
          <S.MetaContainer>
            {/* <Gradient /> */}
            <S.Title>{title}</S.Title>
            <S.Subtitle>{subtitle}</S.Subtitle>
            {/* {showMenu ? (
              <MenuIcon>
                <Icon type="overflow" />
              </MenuIcon>
            ) : null} */}
          </S.MetaContainer>
        ) : null}
      </S.Container>
    )
  }
}

export default AVCard
