import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Route } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
// @ts-ignore
import Div100vh from 'react-div-100vh'
import MusicStore from '#stores/MusicStore'
import MusicAlbumListView from '#views/MusicAlbumListView'
import MusicAlbumDetailView from '#views/MusicAlbumDetailView'
import MusicPlayer from '#components/MusicPlayer'
import MainNav from '#components/MainNav'
import * as S from './style'

interface Props extends RouteComponentProps {
  musicStore: MusicStore
}

@inject('musicStore')
@observer
class MusicView extends React.PureComponent<Props> {
  public componentDidMount(): void {
    this.props.musicStore.getAlbums()
  }

  public render(): JSX.Element | null {
    const {
      match,
      musicStore: { albums },
    } = this.props

    if (!albums.length) {
      return null
    }

    return (
      <Div100vh>
        <S.Container>
          <S.ContentContainer>
            <Route exact path={match.path} render={(): JSX.Element => <MusicAlbumListView />} />
            <Route path="/entertainment/music/:musicAlbum_id" component={MusicAlbumDetailView} />
          </S.ContentContainer>
          <MusicPlayer />
          <MainNav />
          {/* <Nav /> */}
        </S.Container>
      </Div100vh>
    )
  }
}

export default MusicView
