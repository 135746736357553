import Cesium from 'cesium'
import { action, observable, computed, autorun } from 'mobx'
import FlightDataStore from '#stores/FlightDataStore'

export type FlightPhaseName =
  | 'PREFLIGHT'
  | 'TAKEOFF'
  | 'ASCENT'
  | 'LEVEL_CRUISE'
  | 'DESCENT'
  | 'LANDING'
  | 'POSTFLIGHT'

class AutoCameraScene {
  public id: string
  public flightPhaseName: FlightPhaseName
  public subPhaseName: string
  public startTime: Cesium.JulianDate
  public endTime: Cesium.JulianDate
  public durationInSeconds: number
  // public totalFrames: number
  public startingHeading: number
  public startingPitch: number
  public startingRange: number
  public deltaHeading: number
  public deltaPitch: number
  public deltaRange: number

  public constructor({
    id,
    flightPhaseName,
    subPhaseName,
    startTime,
    endTime,
    durationInSeconds,
    // totalFrames,
    startingHeading,
    startingPitch,
    startingRange,
    deltaHeading,
    deltaPitch,
    deltaRange,
  }: {
    id: string
    flightPhaseName: FlightPhaseName
    subPhaseName: string
    startTime: Cesium.JulianDate
    endTime: Cesium.JulianDate
    durationInSeconds: number
    // totalFrames: number
    startingHeading: number
    startingPitch: number
    startingRange: number
    deltaHeading: number
    deltaPitch: number
    deltaRange: number
  }) {
    this.id = id
    this.flightPhaseName = flightPhaseName
    this.subPhaseName = subPhaseName
    this.startTime = startTime
    this.endTime = endTime
    this.durationInSeconds = durationInSeconds
    // this.totalFrames = totalFrames
    this.startingHeading = startingHeading
    this.startingPitch = startingPitch
    this.startingRange = startingRange
    this.deltaHeading = deltaHeading
    this.deltaPitch = deltaPitch
    this.deltaRange = deltaRange
  }
}

export default class AutoCameraStore {
  public get isRunning(): boolean {
    return this.running
  }

  @computed public get loaded(): boolean {
    return Boolean(this.scenes.length)
  }

  private running = true
  private flightDataStore: FlightDataStore
  private scenes = observable.array<AutoCameraScene>()

  public constructor(flightDataStore: FlightDataStore) {
    this.flightDataStore = flightDataStore

    autorun(
      (): void => {
        // console.log('inside autocamerastore autorun', this.flightDataStore.loaded)

        if (this.flightDataStore.loaded) {
          this.compileScenesFromFlightData()
        }
      },
    )
  }

  @action public play = (): void => {
    this.running = true
  }

  @action public pause = (): void => {
    this.running = false
  }

  public getCurrentHeadingPitchRangeByTimeAndCurrentAircraftHeading = (
    time: Cesium.JulianDate,
    currentAircraftHeading: number,
  ): Cesium.HeadingPitchRange | void => {
    const scene = this.getSceneByTime(time)

    // console.log('scene: ', scene)

    if (!scene) {
      return undefined
    }

    const progress = this.getNSecondsIntoScene(scene, time) / scene.durationInSeconds

    return new Cesium.HeadingPitchRange(
      currentAircraftHeading + scene.startingHeading + scene.deltaHeading * progress,
      scene.startingPitch + scene.deltaPitch * progress,
      scene.startingRange + scene.deltaRange * progress,
    )
  }

  private getNSecondsIntoScene = (scene: AutoCameraScene, time: Cesium.JulianDate): number =>
    Cesium.JulianDate.secondsDifference(time, scene.startTime)

  private getSceneByTime = (time: Cesium.JulianDate): AutoCameraScene | undefined => {
    return this.scenes.find(
      (scene): boolean => {
        if (
          Cesium.JulianDate.compare(scene.startTime, time) <= 0 &&
          Cesium.JulianDate.compare(time, scene.endTime) <= 0
        ) {
          return true
        }

        return false
      },
    )
  }

  private compileScenesFromFlightData = (): void => {
    if (this.flightDataStore.autoCameraFlightPhases) {
      // console.log('compiling scenes: ', this.flightDataStore.autoCameraFlightPhases)
      this.flightDataStore.autoCameraFlightPhases.forEach(
        (phaseDataItem): void => {
          this.scenes.push(
            new AutoCameraScene({
              id: phaseDataItem.id,
              flightPhaseName: phaseDataItem.flightPhaseName,
              subPhaseName: phaseDataItem.subPhaseName,
              startTime: Cesium.JulianDate.fromDate(new Date(phaseDataItem.startTime)),
              // phaseDataItem.startTime.dayNumber,
              // phaseDataItem.startTime.secondsOfDay,
              // Cesium.TimeStandard.TAI,
              // ),
              endTime: Cesium.JulianDate.fromDate(new Date(phaseDataItem.endTime)),
              // endTime: new Cesium.JulianDate(
              //   phaseDataItem.endTime.dayNumber,
              //   phaseDataItem.endTime.secondsOfDay,
              //   Cesium.TimeStandard.TAI,
              // ),
              durationInSeconds: phaseDataItem.durationInSeconds,
              // totalFrames:
              //   (phaseDataItem.durationInSeconds * Constants.MAP_FRAME_RATE) /
              //   Constants.MAP_NORMAL_SPEED_MULTIPLIER,
              startingHeading: phaseDataItem.startingHeading,
              startingPitch: phaseDataItem.startingPitch,
              startingRange: phaseDataItem.startingRange,
              deltaHeading: phaseDataItem.deltaHeading,
              deltaPitch: phaseDataItem.deltaPitch,
              deltaRange: phaseDataItem.deltaRange,
            }),
          )
        },
      )
    }
  }
}
