import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

const nNavItems = 2
const navItemWidth = 104
const tabContainerWidth = 48
const navHeight = Math.max(nNavItems * 56 + 16 + (nNavItems - 1) * 8, 176)

export const Container = styled.div`
  position: absolute;
  top: calc(50% - ${navHeight}px / 2);
  left: 100%;
  transform: translateX(
    ${({ expanded }: { expanded: boolean }): string =>
      expanded ? `-${navItemWidth + tabContainerWidth}px` : `-${tabContainerWidth}px`}
  );
  transition: transform 0.5s ease-out;
  z-index: 10;
  height: ${navHeight}px;
  display: flex;
  box-sizing: border-box;
`

export const ContentContainer = styled.div`
  /* padding: 8px; */
  padding: 8px 0;
  background: ${Colors.util.fade({ color: Colors.pureBlack, amount: 0 })};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
  width: ${navItemWidth}px;
  box-sizing: border-box;
  color: ${Colors.white};
`

export const TabContainer = styled.div`
  position: relative;
  height: 160px;
  top: calc(50% - 160px / 2);
  width: ${tabContainerWidth}px;
  -webkit-tap-highlight-color: transparent;
`

export const TabIconContainer = styled.div`
  width: 16px;
  height: 16px;
  color: ${Colors.util.fade({ color: Colors.white, amount: 0.25 })};
  position: absolute;
  top: calc(50% - 8px);
  right: 2px;
`

export const NavItems = styled('div')`
  margin: 0 auto;
  display: inline-block;
`

export const MenuItem = styled.div`
  width: ${navItemWidth}px;
  padding: 8px;
  box-sizing: border-box;
`

export const NavItem = styled(NavLink)`
  width: ${navItemWidth}px;
  height: 56px;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const IconContainer = styled('div')`
  color: ${Colors.offWhite};
  width: 40px;
  height: 24px;
  margin-bottom: 4px;
`

export const ItemLabel = styled('div')`
  ${Fonts.Caption.Normal};
  color: ${Colors.offWhite};
`
