import { NavLink } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

const nNavItems = 5
const navItemWidth = 104
const navHeight = nNavItems * 56 + 16 + (nNavItems - 1) * 8

export const Container = styled.div`
  position: absolute;
  top: calc(50% - ${navHeight}px / 2);
  transform: translateX(
    ${({ expanded }: { expanded: boolean }): string => (expanded ? '0px' : `-${navItemWidth}px`)}
  );
  transition: transform 0.5s ease-out;
  z-index: 1000;
  height: ${navHeight}px;
  display: flex;
  box-sizing: border-box;
`

export const ContentContainer = styled.div`
  /* padding: 8px; */
  padding: 8px 0;
  background: ${Colors.util.fade({ color: Colors.pureBlack, amount: 0 })};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  width: ${navItemWidth}px;
  box-sizing: border-box;
  color: ${Colors.white};
`

export const TabContainer = styled.div`
  position: relative;
  height: 160px;
  top: calc(50% - 160px / 2);
  width: 48px;
  -webkit-tap-highlight-color: transparent;
`

export const NavItems = styled('div')`
  margin: 0 auto;
  display: inline-block;
`

export const NavItem = styled(NavLink)`
  width: ${navItemWidth}px;
  height: 56px;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const IconContainer = styled('div')`
  color: ${Colors.offWhite};
  width: 24px;
  height: 24px;
  margin: 8px auto 4px auto;
`

export const NavItemLabel = styled('div')`
  ${Fonts.Caption.Normal};
  color: ${Colors.offWhite};
  text-align: center;
`

export const activeNavItemClassName = 'active-nav-item'

export const GlobalActiveNavStyle = createGlobalStyle`
  .active-nav-item {
    ${NavItemLabel}, ${IconContainer} {
     color: ${Colors.accent1};
    }
  }
`
