// import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Colors from '#styles/colors'
import Dimensions from '#styles/dimensions'
import Fonts from '#styles/fonts'

export const Container = styled.div`
  display: grid;
  color: ${Colors.offWhite};
  max-height: 100%;
  height: 100%;
  grid-template-areas:
    'top-nav'
    'scroll-container';
  grid-template-rows: min-content 1fr;
`

export const ContentContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 0;
  margin: 0 16px 16px 40px;
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${Dimensions.tablet`
    grid-template-columns: 1fr 2fr;
  `};

  ${Dimensions.desktop`
    max-width: 1024px;
  `};
`

export const Title = styled.div`
  ${Fonts.DisplayBold.Normal};
  color: ${Colors.offWhite};
`

export const Row = styled.div`
  display: flex;
  margin: 0 0 8px;
`

export const Year = styled.div`
  ${Fonts.Body2.Normal};
  color: ${Colors.offWhite};
`

export const Duration = styled.div`
  ${Fonts.Body2.Secondary};
`

export const Description = styled.div`
  ${Fonts.Body2.Normal};
  color: ${Colors.offWhite};
  margin-bottom: 8px;
`
