import { action, computed, observable, runInAction } from 'mobx'
import { Howl } from 'howler'
import Constants from '#constants/index'
// import albumData from '#constants/albums'
import TransportLayer, {
  MusicAlbumFromServer,
  MusicTrackFromServer,
} from '#services/TransportLayer'

// export interface MusicTrack {
//   id: string
//   title: string
//   album: string
//   duration: number
// }

// export interface MusicAlbum {
//   id: string
//   title: string
//   artistName: string
//   artworkURL: string
//   trackIds: string
// }

export class Track {
  public _id: string
  public assetPath: string
  public title: string
  public albumId: string
  public durationInSeconds: number

  private store: MusicStore

  @computed public get isSelected(): boolean {
    return Boolean(
      this.store.currentlySelectedTrack && this.store.currentlySelectedTrack._id === this._id,
    )
  }

  public constructor(
    store: MusicStore,
    id: string,
    assetPath: string,
    title: string,
    albumId: string,
    durationInSeconds: number,
  ) {
    this.store = store
    this._id = id
    this.assetPath = assetPath
    this.title = title
    this.albumId = albumId
    this.durationInSeconds = durationInSeconds
  }
}

export class Album {
  public _id: string
  public title: string
  public artistName: string
  public albumArtworkPath: string
  @observable public tracks = observable<Track>([])

  private store: MusicStore

  public constructor(
    store: MusicStore,
    id: string,
    title: string,
    artistName: string,
    albumArtworkPath: string,
    tracks: MusicTrackFromServer[],
  ) {
    this.store = store
    this._id = id
    this.title = title
    this.artistName = artistName
    this.albumArtworkPath = albumArtworkPath

    tracks.forEach(
      (track): void => {
        const newTrack = new Track(
          store,
          track._id,
          track.assetPath,
          track.title,
          track.musicAlbum_id,
          track.durationInSeconds,
        )

        this.tracks.push(newTrack)
      },
    )
  }

  @computed public get duration(): number {
    return this.tracks.reduce((sum, track): number => sum + track.durationInSeconds, 0)
  }

  @computed public get isSelected(): boolean {
    return Boolean(
      this.store.currentlySelectedAlbum && this.store.currentlySelectedAlbum._id === this._id,
    )
  }

  public getTrackById(trackId: string): Track | undefined {
    return this.tracks.find((track): boolean => track._id === trackId)
  }

  public getPreviousTrackInAlbum(track: Track): Track | undefined {
    const trackIndex = this.tracks.findIndex((t): boolean => t._id === track._id)
    const previousTrack = this.tracks[trackIndex - 1]

    return previousTrack
  }

  public getNextTrackInAlbum(track: Track): Track | undefined {
    const trackIndex = this.tracks.findIndex((t): boolean => t._id === track._id)
    const previousTrack = this.tracks[trackIndex + 1]

    return previousTrack
  }
}

export default class MusicStore {
  @observable public albumsByIdMap = observable.map<string, Album>()
  @observable public isPlaying = false
  @observable public currentlySelectedAlbum?: Album
  @observable public currentlySelectedTrack?: Track
  @observable public audioTrackPositionInSeconds = 0

  private audioTrack?: Howl
  private audioTrackPositionInterval?: number
  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  @computed public get previousTrackInAlbum(): Track | undefined {
    if (this.currentlySelectedAlbum && this.currentlySelectedTrack) {
      const previousTrack = this.currentlySelectedAlbum.getPreviousTrackInAlbum(
        this.currentlySelectedTrack,
      )

      return previousTrack
    }

    return undefined
  }

  @computed public get nextTrackInAlbum(): Track | undefined {
    if (this.currentlySelectedAlbum && this.currentlySelectedTrack) {
      const nextTrack = this.currentlySelectedAlbum.getNextTrackInAlbum(this.currentlySelectedTrack)

      return nextTrack
    }

    return undefined
  }

  @computed public get albums(): Album[] {
    return Object.values(this.albumsByIdMap.toJSON())
  }

  @action public getAlbums = async (): Promise<void> => {
    try {
      const albums = await this.transportLayer.getMusicAlbums()

      runInAction(
        (): void => {
          albums.forEach((album): void => this.updateAlbumFromServer(album))
        },
      )
    } catch (e) {
      console.log('e: ', e)
      runInAction(
        (): void => {
          // this.loading = false
        },
      )

      // TODO: show error
    }
  }

  public getAlbumById(id: string): Album | undefined {
    return this.albumsByIdMap.get(id)
  }

  @action public destroyAudioTrack = (): void => {
    this.pauseTrack()
    this.unloadAudioTrack()
    this.currentlySelectedAlbum = undefined
    this.currentlySelectedTrack = undefined
  }

  @action public pauseTrack = (): void => {
    if (this.audioTrack) {
      this.isPlaying = false

      this.audioTrack.pause()
      this.stopAudioTrackInterval()
    }
  }

  @action public resumeTrack = (): void => {
    if (this.audioTrack) {
      this.isPlaying = true

      this.audioTrack.play()
      this.startAudioTrackInterval()
    }
  }

  @action public changeTrack = (albumId: string, trackId: string): void => {
    this.unloadAudioTrack()

    const album = this.getAlbumById(albumId)

    if (!album) {
      return
    }

    this.currentlySelectedAlbum = album

    const track = this.currentlySelectedAlbum.getTrackById(trackId)

    if (!track) {
      return
    }

    this.currentlySelectedTrack = track
    this.isPlaying = true

    this.startAudioTrack()
  }

  private unloadAudioTrack = (): void => {
    if (this.audioTrack) {
      this.audioTrack.unload()
      this.audioTrack = undefined

      this.resetAudioTrackPosition()
    }
  }

  private startAudioTrack = (): void => {
    if (this.currentlySelectedTrack) {
      this.audioTrack = new Howl({
        src: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/${this.currentlySelectedTrack.assetPath}`,
      })

      this.resetAudioTrackPosition()
      this.audioTrack.play()

      this.audioTrack.on(
        'load',
        (): void => {
          this.startAudioTrackInterval()
        },
      )

      // TODO:
      this.audioTrack.on(
        'end',
        (): void => {
          this.unloadAudioTrack()
          const nextTrack = this.nextTrackInAlbum

          if (nextTrack) {
            this.changeTrack(nextTrack.albumId, nextTrack._id)
          } else {
            runInAction(
              (): void => {
                this.destroyAudioTrack()
              },
            )
          }
        },
      )
    }
  }

  private startAudioTrackInterval = (): void => {
    this.stopAudioTrackInterval()
    this.tickAudioTrackInterval()
    this.audioTrackPositionInterval = setInterval(this.tickAudioTrackInterval, 1000)
  }

  private stopAudioTrackInterval = (): void => {
    if (this.audioTrackPositionInterval) {
      clearInterval(this.audioTrackPositionInterval)
      this.audioTrackPositionInterval = undefined
    }
  }

  @action private tickAudioTrackInterval = (): void => {
    this.audioTrackPositionInSeconds += 1
  }

  @action private resetAudioTrackPosition = (): void => {
    this.audioTrackPositionInSeconds = 0
  }

  private updateAlbumFromServer = (albumFromServer: MusicAlbumFromServer): void => {
    const albumInStore = this.albumsByIdMap.get(albumFromServer._id)

    if (!albumInStore) {
      const album = new Album(
        this,
        albumFromServer._id,
        albumFromServer.title,
        albumFromServer.artistName,
        albumFromServer.albumArtworkPath,
        albumFromServer.tracks,
      )

      // TODO: make sure these are alphatized
      this.albumsByIdMap.set(album._id, album)
      // this.albums.push(album)
    } else {
      // TODO:
      // albumInStore.updateFromServer(albumFromServer)
    }
  }
}
