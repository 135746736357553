import React from 'react'
import Icon, { IconType } from '#components/Icon'
import * as S from './style'

interface Props {
  onClick: () => void
  leftIcon?: IconType
  rightIcon?: IconType
}

export default class MenuItem extends React.PureComponent<Props, {}> {
  public render(): JSX.Element {
    const { children } = this.props

    return (
      <S.Container onClick={this.handleClick}>
        {this.renderLeftIcon()}
        <S.Content>{children}</S.Content>
        {this.renderRightIcon()}
      </S.Container>
    )
  }

  private handleClick = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    this.props.onClick()
  }

  private renderLeftIcon = (): JSX.Element | null => {
    const { leftIcon } = this.props

    if (!leftIcon) {
      return null
    }

    return (
      <S.LeftIconContainer>
        <Icon type={leftIcon} />
      </S.LeftIconContainer>
    )
  }

  private renderRightIcon = (): JSX.Element | null => {
    const { rightIcon } = this.props

    if (!rightIcon) {
      return null
    }

    return (
      <S.RightIconContainer>
        <Icon type={rightIcon} />
      </S.RightIconContainer>
    )
  }
}
