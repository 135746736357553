import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${Colors.black1};
  overflow: hidden;
`

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (min-aspect-ratio: 16/9) {
    height: 300%;
    top: -100%;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 300%;
    left: -100%;
  }
`

export const Static = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
`

export const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const MainText = styled.div`
  width: 75%;
  ${Fonts.Display.Normal};
  font-size: 6vh;
  line-height: 1.2em;
  color: ${Colors.black1};
  text-align: center;
  margin-bottom: 6vh;
`

export const Text = styled.div`
  ${Fonts.Display.Normal};
  font-size: 4vh;
  line-height: 1.2em;
  color: ${Colors.util.fade({ color: Colors.black1, amount: 0.25 })};
  text-align: center;
  margin-bottom: 1vh;
`

export const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 10vh;

  img {
    width: 50%;
    opacity: 1;
  }
`
