import icons from './icons'

export type IconType = keyof typeof icons

const Icon = ({
  type,
  width = '100%',
  height = '100%',
}: {
  type: IconType
  width?: string
  height?: string
}): JSX.Element => icons[type]({ width, height })

export default Icon
