import uuid from 'uuid/v4'
import Constants from '#constants/index'

export default [
  {
    id: uuid(),
    title: 'Show Preview',
    description: 'GeoRadio Van Nuys-Seattle',
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/GeoRadio_VanNuys_Seattle_promo.mp3`,
    durationInSeconds: 41,
  },
  {
    id: uuid(),
    title: 'Welcome to California',
    description: 'Hotel California',
    // subtitle: 'The Golden State',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/CAsong.mp3`,
    durationInSeconds: 30,
  },
  {
    id: uuid(),
    title: 'Los Angeles',
    description: 'Travel the world on an ice cream tour in Los Angeles',
    // subtitle: 'The City of Angels',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_ca_los_an_pri_170040.mp3`,
    durationInSeconds: 308,
  },
  {
    id: uuid(),
    title: 'Hollywood',
    description: "The legacy of Stan Lee: 'Marvel let us dream'",
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_ca_hollyw_16_pri_181364.mp3`,
    durationInSeconds: 612,
  },
  {
    id: uuid(),
    title: 'Southern California',
    description: 'How wartime prejudice brought young Japanese Americans together for life',
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_ca_southe_pri_69297.mp3`,
    durationInSeconds: 292,
  },
  {
    id: uuid(),
    title: 'Bakersfield',
    description: '1952 Earthquake',
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_ca_bakers_1_cspan_goF6EXVedUs.mp3`,
    durationInSeconds: 443,
  },
  {
    id: uuid(),
    title: 'California',
    description: "Nuevo mariachi is telling the story of one man's California experience",
    // subtitle: 'The Golden State',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_ca_califo_5_pri_172044.mp3`,
    durationInSeconds: 400,
  },
  {
    id: uuid(),
    title: 'San Joaquin',
    description: "San Joaquin's Salted Fields",
    // subtitle: 'Valley',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_ca_san_jo_37_pri_23670.mp3`,
    durationInSeconds: 378,
  },

  {
    id: uuid(),
    title: 'Fresno',
    description: 'How groundwater is saving California farmers during drought',
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_ca_fresno_13_pri_67091.mp3`,
    durationInSeconds: 470,
  },

  {
    id: uuid(),
    title: 'Welcome to Nevada',
    description: 'Viva Las Vegas',
    // subtitle: 'The Silver State',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/NVsong.mp3`,
    durationInSeconds: 30,
  },
  {
    id: uuid(),
    title: 'Carson City',
    description: 'Carson City Mint',
    // subtitle: '',

    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/audio/us_nv_carson_3527_cspan_SqG97be8pvM.mp3`,
    durationInSeconds: 465,
  },
  {
    id: uuid(),
    title: 'Yosemite',
    description: 'Using forest fires to prevent forest fires',
    // subtitle: 'National Park',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_ca_yosemi_48_pri_83686.mp3`,
    durationInSeconds: 1046,
  },
  {
    id: uuid(),
    title: 'Nevada',
    description: 'History of Legalized Prostitution in Nevada',
    // subtitle: 'The Silver State',

    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/audio/us_nv_nevada_3536_cspan_6MUbtu_GMkI.mp3`,
    durationInSeconds: 465,
  },

  {
    id: uuid(),
    title: 'Welcome to Oregon',
    description: "Singin' In The Rain",
    // subtitle: 'The Beaver State',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/ORsong.mp3`,
    durationInSeconds: 30,
  },

  {
    id: uuid(),
    title: 'Portland',
    description: 'How Oregon became a paradise for beer drinkers',
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_or_portla_3569_pri_66922.mp3`,
    durationInSeconds: 988,
  },
  {
    id: uuid(),
    title: 'Bend',
    description: "Bend's Lumber Industry",
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_or_bend_3546_cspan_VFNzN8j9BBc.mp3`,
    durationInSeconds: 812,
  },
  {
    id: uuid(),
    title: 'Central Oregon',
    description:
      'An ornamental garden grass is causing problems along a wild scenic river in Oregon',
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_or_centra_3550_pri_70417.mp3`,
    durationInSeconds: 210,
  },

  {
    id: uuid(),
    title: 'Vancouver',
    description:
      'Washington activist wins a Goldman Environmental Prize for defeating a dangerous oil-by-rail project',
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_wa_vancou_3811_pri_182565.mp3`,
    durationInSeconds: 666,
  },

  {
    id: uuid(),
    title: 'Gresham',
    description: "State Flags All Look the Same &ndash; Let's Update Them",
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_or_gresha_6408_pri_40996.mp3`,
    durationInSeconds: 281,
  },

  {
    id: uuid(),
    title: 'Willamette Valley',
    description: 'Oregon Minnow Thriving and No Longer Endangered',
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_or_willam_3577_pri_63545.mp3`,
    durationInSeconds: 343,
  },
  {
    id: uuid(),
    title: 'Olympia',
    description: 'Washington State Capitol',
    // subtitle: '',

    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/audio/us_wa_olympi_3633_cspan_CHcJvaktZEA.mp3`,
    durationInSeconds: 667,
  },
  {
    id: uuid(),
    title: 'Columbia Gorge',
    description: 'Ice Climbing',
    // subtitle: '',

    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_or_columb_3551_pri_63663.mp3`,
    durationInSeconds: 317,
  },
]

// export default [
//   {
//     id: uuid(),
//     title: 'Orlando',
//     description: 'Flight preview',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/GeoRadio_Orlando_Teterboro_promo.mp3`,
//     distancePercentage: 0.0,
//     durationInSeconds: 41,
//   },
//   {
//     id: uuid(),
//     title: 'Celebration',
//     description: 'The town that Disney built',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_fl_celebration_celebration__1924-seg.mp3`,
//     distancePercentage: 0.051,
//     durationInSeconds: 455,
//   },
//   {
//     id: uuid(),
//     title: 'Saint Augustine',
//     description: 'Alligators All Around',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_fl_st_augustine_alligators__all-around.mp3`,
//     distancePercentage: 0.077,
//     durationInSeconds: 160,
//   },
//   {
//     id: uuid(),
//     title: 'Beaufort',
//     description: 'Oh, the Buford Boat Done Come',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_sc_beaufort_buford__3138a2.mp3`,
//     distancePercentage: 0.103,
//     durationInSeconds: 66,
//   },
//   {
//     id: uuid(),
//     title: 'Charleston',
//     description: 'Rising tides',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_sc_charleston_risingtides__loe180706b1.mp3`,
//     distancePercentage: 0.128,
//     durationInSeconds: 386,
//   },
//   {
//     id: uuid(),
//     title: 'Pickens',
//     description: 'A world-class tea-growing region',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_sc_pickens_tea_pri_1024201706.mp3`,
//     distancePercentage: 0.154,
//     durationInSeconds: 409,
//   },
//   {
//     id: uuid(),
//     title: 'North Carolina',
//     description: 'My Carolina Lady (1902 recording)',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_nc_north_carolina_mycarolinalady_loc_0.mp3`,
//     distancePercentage: 0.179,
//     durationInSeconds: 156,
//   },
//   {
//     id: uuid(),
//     title: 'Moganton',
//     description: 'Long Lost Spanish Fort',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_nc_moganton_fort_pri_72420136.mp3`,
//     distancePercentage: 0.205,
//     durationInSeconds: 250,
//   },
//   {
//     id: uuid(),
//     title: 'High Point',
//     description: 'Global capital of furniture buying',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_nc_high_point_globalization_pri_2018050303.mp3`,
//     distancePercentage: 0.231,
//     durationInSeconds: 343,
//   },
//   {
//     id: uuid(),
//     title: 'North Carolina',
//     description: 'Keeping a foothold on furniture',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_nc_north_carolina_furniture_pri_2018050203.mp3`,
//     distancePercentage: 0.256,
//     durationInSeconds: 403,
//   },
//   {
//     id: uuid(),
//     title: 'Galax',
//     description: 'Fighting globalization',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_va_galax_furniture_pri_71520144.mp3`,
//     distancePercentage: 0.282,
//     durationInSeconds: 493,
//   },
//   {
//     id: uuid(),
//     title: 'Appomattox',
//     description: 'How the Civil War ended',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_va_appomattox_civilwar_pri_howe2.mp3`,
//     distancePercentage: 0.308,
//     durationInSeconds: 760,
//   },
//   {
//     id: uuid(),
//     title: 'Monticello',
//     description: "Jefferson's Dome",
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_va_monticello_jefferson_pri_070304d.mp3`,
//     distancePercentage: 0.333,
//     durationInSeconds: 398,
//   },
//   {
//     id: uuid(),
//     title: 'Shenandoah Valley',
//     description: 'Shenandoah - sung by Thomas Hampson',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_va_shenandoah_valley_song_loc_0.mp3`,
//     distancePercentage: 0.359,
//     durationInSeconds: 180,
//   },
//   {
//     id: uuid(),
//     title: 'Arlington',
//     description: 'Gold star families convene',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_va_arlington_goldstar_pri_914860.mp3`,
//     distancePercentage: 0.385,
//     durationInSeconds: 398,
//   },
//   {
//     id: uuid(),
//     title: 'Washington',
//     description: "Once an actual 'Cesspool'",
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_dc_washington_cesspool_pri_111714.mp3`,
//     distancePercentage: 0.41,
//     durationInSeconds: 511,
//   },
//   {
//     id: uuid(),
//     title: 'Washington',
//     description: 'Easter Egg Roll, 1938',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_dc_washington_easter_nara_afdr108.mp3`,
//     distancePercentage: 0.436,
//     durationInSeconds: 217,
//   },
//   {
//     id: uuid(),
//     title: 'Baltimore',
//     description: 'Story of the Star Spangled Banner',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_md_baltimore_starspangled_voa_c87ee25b.mp3`,
//     distancePercentage: 0.462,
//     durationInSeconds: 376,
//   },
//   {
//     id: uuid(),
//     title: 'Chespeake Bay',
//     description: "Chompin' Invasives",
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_md_chespeake_bay_chompin_pri_30711.mp3`,
//     distancePercentage: 0.487,
//     durationInSeconds: 363,
//   },
//   {
//     id: uuid(),
//     title: 'Takoma Park',
//     description: 'One Silo at a Time',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_md_takoma_park_corn_pri_30815.mp3`,
//     distancePercentage: 0.513,
//     durationInSeconds: 315,
//   },
//   {
//     id: uuid(),
//     title: 'Bethesda',
//     description: 'Good Vibrations',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_md_bethesda_toad_pri_40109.mp3`,
//     distancePercentage: 0.538,
//     durationInSeconds: 269,
//   },
//   {
//     id: uuid(),
//     title: 'Edmonston',
//     description: 'From Mean Streets to Green Streets',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_md_edmonston_greenstreets_pri_90807.mp3`,
//     distancePercentage: 0.564,
//     durationInSeconds: 451,
//   },
//   {
//     id: uuid(),
//     title: 'Annapolis',
//     description: 'Annapolis Archaeology',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_md_annapolis_arcaeology_pri_778825.mp3`,
//     distancePercentage: 0.59,
//     durationInSeconds: 260,
//   },
//   {
//     id: uuid(),
//     title: 'Maryland',
//     description: 'Return of the Brown Pelican',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_md_maryland_pelican_pri_20628.mp3`,
//     distancePercentage: 0.615,
//     durationInSeconds: 179,
//   },
//   {
//     id: uuid(),
//     title: 'Newark',
//     description: 'Hydrogen Feathers',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_de_newark_feathers_pri_90731.mp3`,
//     distancePercentage: 0.641,
//     durationInSeconds: 133,
//   },
//   {
//     id: uuid(),
//     title: 'Philadelphia',
//     description: 'A Venezuelan-owned island',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_pa_philadelphia_venezisland_pri_833447.mp3`,
//     distancePercentage: 0.667,
//     durationInSeconds: 194,
//   },
//   {
//     id: uuid(),
//     title: 'Philadelphia',
//     description: 'Bon Jovi and Ben Franklin',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_pa_philadelphia_bonjovi_fox_111616.mp3`,
//     distancePercentage: 0.692,
//     durationInSeconds: 32,
//   },
//   {
//     id: uuid(),
//     title: 'Gloucester',
//     description: 'Massive rooftop solar project',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_nj_gloucester_solar_pri_120420.mp3`,
//     distancePercentage: 0.718,
//     durationInSeconds: 238,
//   },
//   {
//     id: uuid(),
//     title: 'Camden',
//     description: 'Saving Camden',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_nj_camden_saving_pri_923116.mp3`,
//     distancePercentage: 0.744,
//     durationInSeconds: 466,
//   },
//   {
//     id: uuid(),
//     title: 'Landale',
//     description: 'White House Xmas tree',
//     assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/audio/us_pa_landale_xmastree_fox_112715.mp3`,
//     distancePercentage: 0.769,
//     durationInSeconds: 43,
//   },
//   {
//     id: uuid(),
//     title: 'Doylestown',
//     description: 'Photographer Suzanne Opton',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_pa_doylestown_opton_pri_090707d.mp3`,
//     distancePercentage: 0.795,
//     durationInSeconds: 393,
//   },
//   {
//     id: uuid(),
//     title: 'New Jersey',
//     description: 'Spanish rice from Jersey',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_nj_new_jersey_food_pri_2018010405.mp3`,
//     distancePercentage: 0.821,
//     durationInSeconds: 307,
//   },
//   {
//     id: uuid(),
//     title: 'Grovers Mills',
//     description: 'War of the Worlds',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_nj_grovers_mills_warofworlds_pri_102920133.mp3`,
//     distancePercentage: 0.846,
//     durationInSeconds: 234,
//   },
//   {
//     id: uuid(),
//     title: 'Wayne',
//     description: 'The Forgotten Circassian Nation',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_nj_wayne_circassian_pri_52120127.mp3`,
//     distancePercentage: 0.872,
//     durationInSeconds: 420,
//   },
//   {
//     id: uuid(),
//     title: 'Jersey City',
//     description: 'Jollibee: A Taste of Home for Filipinos',
//     assetURL: `${
//       Constants.REACT_APP_MEDIA_ASSETS_URL
//     }/audio/us_nj_jersey_city_jollibee_pri_102620126.mp3`,
//     distancePercentage: 0.897,
//     durationInSeconds: 348,
//   },
// ]
