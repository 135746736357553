import styled, { createGlobalStyle } from 'styled-components'
import Colors from '#styles/colors'

export const GlobalStyle = createGlobalStyle`
  #poi-view-large {
    .slick-slider {
      height: 100%;
    }

    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    .slick-slide {
      > div {
        height: 100%;
      }

      img {
        width: auto;
      }
    }
  }

  #poi-view-thumbnail {
    .slick-slide {
      width: 64px;
      margin: 0 4px;
      transform: scale(0.8);
      transition-property: transform;
      transition-duration: 0.2s;
    }

    .slick-current {
      transform: scale(1);
      img {
        border: 1px solid ${Colors.util.fade({ color: Colors.offWhite, amount: 0.75 })};
      }
    }
  }
`

export const Container = styled.div`
  background: ${Colors.pureBlack};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const LargeSliderContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const MetaAndThumbnailContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${Colors.util.fade({ color: Colors.pureBlack, amount: 0.5 })};
  padding: 16px;
  z-index: 10;
`

export const MetaContainer = styled.div`
  margin-bottom: 24px;
  color: ${Colors.offWhite};
`

export const LargeImageContainer = styled.div`
  height: 100%;
  background: ${Colors.pureBlack};
  overflow: hidden;
  position: relative;
`

export const LargeImage = styled.img`
  margin: 0 auto;
  transform-origin: left center;
  opacity: 0;
  height: 100%;
`

export const ThumbnailImageContainer = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0 8px 0 0;
  height: 64px;
  width: 64px;
`

export const ThumbnailImage = styled.div`
  height: 64px;
  background-position: center center;
  background-size: cover;
`

export const ControlsContainer = styled.div`
  position: absolute;
  z-index: 30;
  top: 16px;
  left: 16px;
  width: ${24 * 3 + 16 * 2}px;
  height: 24px;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 30;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
`

export const IconButton = styled.button`
  width: 24px;
  height: 24px;
  color: ${Colors.offWhite};
  border: none;
  padding: 0;
  margin: 0 16px 0 0;
  background: none;

  &:last-child {
    margin-right: 0;
  }
`
