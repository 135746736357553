import styled from 'styled-components'
import Colors from '#styles/colors'
import Dimensions from '#styles/dimensions'
import Fonts from '#styles/fonts'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  color: ${Colors.offWhite};
`

export const ScrollContainer = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  padding: 0 16px 0 40px;
`

export const AlbumAndMetaDataContainer = styled.div`
  margin-bottom: 24px;

  ${Dimensions.tablet`
    display: flex;
  `};
`

export const AlbumContainer = styled.div`
  height: calc(100vw - 32px);
  margin: 0 0 16px;

  ${Dimensions.tablet`
    width: 240px;
    height: 240px;
    margin: 0 16px 0 0;
  `};
`

export const AlbumMetaData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`

export const AlbumTitle = styled.div`
  ${Fonts.DisplayBold.Normal};
  color: ${Colors.offWhite};
`

export const ArtistName = styled.div`
  ${Fonts.Body1.Normal};
  color: ${Colors.offWhite};
`

export const AlbumTracksLength = styled.div`
  ${Fonts.Body1.Normal};
  color: ${Colors.lightWhite};
  margin-bottom: 24px;
`

export const TrackListing = styled.ul`
  margin: 0 0 16px;
  padding: 0;
  list-style: none;
`

export const Track = styled.li`
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${Colors.util.fade({ color: Colors.white, amount: 0.9 })};

  button {
    width: 100%;
    margin: 0;
    background: transparent;
    padding: 16px 0;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
  }
`

export const TrackIndex = styled.span`
  ${Fonts.Body2.Normal};
  color: ${Colors.lightWhite};
  width: 40px;
`

export const SpeakerIcon = styled.div`
  width: 16px;
  height: 16px;
  color: ${Colors.offWhite};
`

export const TrackTitle = styled.span`
  ${Fonts.Body2.Normal};
  color: ${(props: { selected: boolean }) => (props.selected ? Colors.accent2 : Colors.offWhite)};
  flex: 1;
`

export const TrackDuration = styled.span`
  ${Fonts.Body2.Normal};
  color: ${Colors.lightWhite};
`
