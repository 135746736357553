import React from 'react'
// @ts-ignore
import Div100vh from 'react-div-100vh'
import Nav from '#components/Nav'
import Icon from '#components/Icon'
import * as S from './style'

const EntertainmentView = (): JSX.Element => (
  <Div100vh>
    <S.Container>
      <S.ContentContainer>
        <S.Item to="/entertainment/music">
          <S.IconContainer>
            <Icon type="music" />
          </S.IconContainer>
          Music
        </S.Item>

        <S.Item to="/entertainment/movies">
          <S.IconContainer>
            <Icon type="movies" />
          </S.IconContainer>
          Movies
        </S.Item>
      </S.ContentContainer>
      <Nav />
    </S.Container>
  </Div100vh>
)

export default EntertainmentView
