import React from 'react'
import Constants from '#constants/index'
import TextronLogo from './assets/textron-logo.png'
import * as S from './style'

export default ({ isBulkhead }: { isBulkhead: boolean }): JSX.Element => (
  <S.Container>
    {isBulkhead ? (
      <S.Static style={{ backgroundImage: "url('/images/clouds.jpg')" }} />
    ) : (
      <S.Video autoPlay loop>
        <source src={`${Constants.REACT_APP_MEDIA_ASSETS_URL}/video/clouds.mp4`} type="video/mp4" />
      </S.Video>
    )}
    <S.ContentContainer>
      <S.LogoContainer>
        <img src={TextronLogo} alt="" />
      </S.LogoContainer>
      <S.MainText>Welcome to our flight to Boeing Field</S.MainText>
      <S.Text>Captain Clarence</S.Text>
      <S.Text>First Mate Roger</S.Text>
    </S.ContentContainer>
  </S.Container>
)
