import React from 'react'
import { autorun, IReactionDisposer } from 'mobx'
import { observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
// @ts-ignore
import { connect, ConnectedComponent } from '#utils/index'
import Cesium from 'cesium'
import Constants from '#constants/index'
import Camera from '#lib/Camera'
import FlightDataStore from '#stores/FlightDataStore'
import AutoCameraStore from '#stores/AutoCameraStore'
import GeoRadioStore from '#stores/GeoRadioStore'
import POIView from '#views/POIView'
import AutoplayPOIOverlay from '#components/AutoplayPOIOverlay'
import GeoRadioView from '#views/GeoRadioView'
// import Icon from '#components/Icon'
import ContextualNav from '#components/ContextualNav'
import Menu from '#components/Menu'
import MenuItem from '#components/MenuItem'
import FlightInfoBar from '#components/FlightInfoBar'
import WelcomeScreen from '#components/WelcomeScreen'
import poiData from '#constants/poi-lax_sea'
import * as S from './style'

const AUTOPLAY_POI_SLIDE_VISIBLE_DURATION = 25000

// @ts-ignore
Cesium.Ion.defaultAccessToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjMmMxMGRmOC1lZGFjLTRjMzgtYjIxNy05OThhZmZhZDQ1ZDYiLCJpZCI6MTYxNDksInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1Njk3MTA0ODF9.H6OCd7cNyUeybgRlCyq3gSPIVOzV3CKJm2eqAU__B6E'

interface Props extends RouteComponentProps {
  isBulkhead: boolean
  showGeoRadio: boolean
  showPOI: boolean
}
interface Stores {
  flightDataStore: FlightDataStore
  autoCameraStore: AutoCameraStore
  geoRadioStore: GeoRadioStore
}

interface State {
  autoCameraModeIsRunning: boolean
  currentAutoplayPOISlide?: AutoplayPOI
  shouldShowAutoplayPOISlide: boolean
  userHasCancelledThisAutoplayPOISlide: boolean
  geoRadioIsVisible: boolean
  poiIsVisible: boolean
  settingsMenuIsVisible: boolean
  // bordersAreVisible: boolean
  labelsAreVisible: boolean
  autoplayPOIsEnabled: boolean
  isAnimating: boolean
}

interface POI {
  type: string
  id: number
  photoId: number
  title: string
  latitude: number
  longitude: number
  geotype: string
  assetURL: string
  thumbnailURL: string
  description: string
  subtitle: string
}

export interface AutoplayPOI extends POI {
  startTime: Cesium.JulianDate
  endTime: Cesium.JulianDate
}

@connect(
  'flightDataStore',
  'autoCameraStore',
  'geoRadioStore',
)
@observer
export default class Map extends ConnectedComponent<Props, Stores, State> {
  // private handleGeoRadioTrackChangeDisposer?: IReactionDisposer
  private handleFlightIsRunningDisposer?: IReactionDisposer
  private settingsButtonRef: React.RefObject<HTMLButtonElement> = React.createRef()
  private viewer?: Cesium.Viewer
  private camera?: Camera
  private labelsImageryLayer?: Cesium.ImageryLayer
  private autoplayPOISlides: AutoplayPOI[] = []
  private nAutoplayPOISlides = 0
  private startAutoplayPOITimer?: number

  public constructor(props: Props) {
    super(props)

    this.state = {
      autoCameraModeIsRunning: true,
      currentAutoplayPOISlide: undefined,
      shouldShowAutoplayPOISlide: false,
      userHasCancelledThisAutoplayPOISlide: false,
      geoRadioIsVisible: props.showGeoRadio,
      poiIsVisible: props.showPOI,
      settingsMenuIsVisible: false,
      // bordersAreVisible: false,
      labelsAreVisible: false,
      autoplayPOIsEnabled: true, // TODO: this should be true
      isAnimating: false,
    }
  }

  /* eslint-disable */
  public UNSAFE_componentWillReceiveProps(newProps: Props): void {
    // console.log('newProps: ', newProps)
    if (this.props.showGeoRadio !== newProps.showGeoRadio) {
      this.setState({
        geoRadioIsVisible: newProps.showGeoRadio,
      })
    }

    if (this.props.showPOI !== newProps.showPOI) {
      this.setState({
        poiIsVisible: newProps.showPOI,
      })
    }
  }
  /* eslint-enable */

  public componentDidMount(): void {
    this.viewer = this.initViewer()
    this.setPositions()
    this.camera = this.initCamera()
    this.initAutoplayPOI()

    // console.log('mounted')

    // this.handleGeoRadioTrackChangeDisposer = autorun(
    //   (): void => {
    //     const {
    //       currentlySelectedTrack,
    //       isPlaying,
    //       currentlySelectedTrackSelectedByUserAction,
    //     } = this.stores.geoRadioStore

    //     if (
    //       currentlySelectedTrack &&
    //       currentlySelectedTrackSelectedByUserAction &&
    //       isPlaying &&
    //       this.camera
    //     ) {
    //       this.camera.flyToPercentage(currentlySelectedTrack.distancePercentage)
    //     }
    //   },
    // )

    autorun(
      (): void => {
        if (this.stores.flightDataStore.shouldRefresh) {
          console.log('SHOULD REFRESH')
        }
      },
    )

    this.handleFlightIsRunningDisposer = autorun(
      (): void => {
        // console.log(
        //   'this.stores.flightDataStore.flightIsRunning: ',
        //   this.stores.flightDataStore.flightIsRunning,
        // )
        if (this.viewer) {
          if (this.stores.flightDataStore.flightIsRunning) {
            this.viewer.clock.multiplier = Constants.MAP_NORMAL_SPEED_MULTIPLIER
          } else {
            this.viewer.clock.multiplier = 0
          }
        }
      },
    )
  }

  public componentWillUnmount(): void {
    if (this.viewer) {
      this.viewer.entities.removeAll()
      this.viewer.destroy()
    }

    // if (this.handleGeoRadioTrackChangeDisposer) {
    //   this.handleGeoRadioTrackChangeDisposer()
    // }

    // TODO: destroy camera
  }

  public get mapHasLoaded(): boolean {
    if (this.viewer) {
      // @ts-ignore
      return this.viewer.scene.globe.tilesLoaded
    }

    return false
  }

  public render(): JSX.Element {
    const { isBulkhead } = this.props
    console.log('phase: ', this.stores.flightDataStore.flightPhase)

    const {
      autoCameraModeIsRunning,
      currentAutoplayPOISlide,
      shouldShowAutoplayPOISlide,
      userHasCancelledThisAutoplayPOISlide,
      geoRadioIsVisible,
      poiIsVisible,
      // settingsMenuIsVisible,
      // isAnimating,
    } = this.state

    // TODO: this is garbage
    const autoplayActive = Boolean(
      autoCameraModeIsRunning &&
        currentAutoplayPOISlide &&
        shouldShowAutoplayPOISlide &&
        !userHasCancelledThisAutoplayPOISlide,
    )

    // if (this.viewer) {
    //   this.viewer.clock.multiplier = autoplayActive ? 0 : Constants.MAP_NORMAL_SPEED_MULTIPLIER
    // }

    return (
      <S.Container>
        <S.MapContainer>
          <S.CesiumAndSubNavContainer>
            <S.CesiumContainer id="cesiumContainer" autoplayActive={autoplayActive} />
            {/* <div
              id="range"
              style={{ position: 'absolute', top: 10, left: 0, background: 'red', color: 'white' }}
            >
              123
            </div> */}
            {isBulkhead ? null : (
              <ContextualNav
                autoplayPOIsEnabled={this.state.autoplayPOIsEnabled}
                onAutoplayPOIsToggle={this.handleAutoplayPOIsToggle}
                labelsAreVisible={this.state.labelsAreVisible}
                onLabelsToggle={this.handleLabelsClick}
              />
            )}
            {/* {isBulkhead ? null : (
              <S.SubNavContainer autoplayActive={autoplayActive}>
                <S.NavItem onClick={this.handleGeoRadioClick} active={geoRadioIsVisible}>
                  <S.IconContainer>
                    <Icon type="geo-radio" />
                  </S.IconContainer>
                  <S.NavItemLabel>GeoRadio</S.NavItemLabel>
                </S.NavItem>

                <S.NavItem onClick={this.handlePOIClick} active={poiIsVisible}>
                  <S.IconContainer>
                    <Icon type="poi" />
                  </S.IconContainer>
                  <S.NavItemLabel>Points of Interest</S.NavItemLabel>
                </S.NavItem>

                <S.NavItem
                  onClick={this.handleSettingsClick}
                  active={settingsMenuIsVisible}
                  ref={this.settingsButtonRef}
                >
                  <S.IconContainer>
                    <Icon type="settings" />
                  </S.IconContainer>
                  <S.NavItemLabel>Settings</S.NavItemLabel>
                  {this.renderSettingsMenu()}
                </S.NavItem>
              </S.SubNavContainer>
            )} */}
            <FlightInfoBar isBulkhead={isBulkhead} />
          </S.CesiumAndSubNavContainer>
          {autoplayActive && (
            <AutoplayPOIOverlay
              poi={currentAutoplayPOISlide as AutoplayPOI}
              onCancel={(): void => {
                this.setState({
                  userHasCancelledThisAutoplayPOISlide: true,
                })
              }}
            />
          )}
        </S.MapContainer>
        {geoRadioIsVisible && (
          <S.SideMenuContainer>
            <GeoRadioView onClose={this.handleGeoRadioClose} isAnimating={false} />
          </S.SideMenuContainer>
        )}
        {poiIsVisible && (
          <S.POIContainer>
            <POIView onClose={this.handlePOIClose} />
          </S.POIContainer>
        )}
        {this.stores.flightDataStore.flightPhase === 'PREFLIGHT' && (
          <S.WelcomeScreenContainer>
            <WelcomeScreen isBulkhead={isBulkhead} />
          </S.WelcomeScreenContainer>
        )}
      </S.Container>
    )
  }

  private initAutoplayPOI = (): void => {
    const N_SECONDS_TO_PAD_AUTOPLAY_POI_START_TIME = 20

    if (
      this.stores.flightDataStore.simulationStartTime &&
      this.stores.flightDataStore.simulationEndTime &&
      this.viewer
    ) {
      // pad the first poi by 20 seconds before we show it
      const paddedSimStartTime = Cesium.JulianDate.addSeconds(
        this.stores.flightDataStore.simulationStartTime,
        N_SECONDS_TO_PAD_AUTOPLAY_POI_START_TIME,
        new Cesium.JulianDate(),
      )

      // console.log('this poiData.length: ', poiData.length)
      // console.log('this.simStartTime: ', this.simStartTime)
      // console.log('this paddedSimStartTime: ', paddedSimStartTime)

      const simulationTotalSeconds = Cesium.JulianDate.secondsDifference(
        this.stores.flightDataStore.simulationEndTime,
        paddedSimStartTime,
      )
      // console.log('this simulationTotalSeconds: ', simulationTotalSeconds)

      const durationPerSlide = Math.floor(simulationTotalSeconds / poiData.length)

      // console.log('this durationPerSlide: ', durationPerSlide)

      // Create Cesium times for each slide
      this.autoplayPOISlides = poiData.map(
        (poi, index): AutoplayPOI => {
          const startTime = Cesium.JulianDate.addSeconds(
            paddedSimStartTime,
            durationPerSlide * index,
            new Cesium.JulianDate(),
          )
          const endTime = Cesium.JulianDate.addSeconds(
            startTime,
            durationPerSlide,
            new Cesium.JulianDate(),
          )

          return {
            ...poi,
            startTime,
            endTime,
          }
        },
      )

      this.nAutoplayPOISlides = this.autoplayPOISlides.length

      // console.log('this.autoplayPOISlides: ', this.autoplayPOISlides)

      // Listen to clock changes for showing POI images during autoplay
      // TODO: remove listener
      this.viewer.clock.onTick.addEventListener(this.handleClockTick)
    }
  }

  private handleClockTick = (): void => {
    if (this.viewer) {
      const { currentTime } = this.viewer.clock

      // Listen for a change in POI image

      // Find the current POI slide to display
      const poiSlide = this.autoplayPOISlides.find(
        (poi): boolean => {
          if (
            Cesium.JulianDate.compare(currentTime, poi.startTime) > 0 &&
            Cesium.JulianDate.compare(currentTime, poi.endTime) < 0
          ) {
            return true
          }

          return false
        },
      )

      // TODO: autoplayEnabled ?
      if (
        this.state.autoplayPOIsEnabled &&
        poiSlide &&
        poiSlide !== this.state.currentAutoplayPOISlide
      ) {
        this.setState({
          currentAutoplayPOISlide: poiSlide,
          shouldShowAutoplayPOISlide: true,
          userHasCancelledThisAutoplayPOISlide: false,
        })
        // console.log('this poiSlide: ', poiSlide)

        clearTimeout(this.startAutoplayPOITimer)

        this.startAutoplayPOITimer = setTimeout((): void => {
          if (this) {
            // console.log('this setting shouldShowAutoplayPOISlide to false')
            this.setState({ shouldShowAutoplayPOISlide: false })
          }
        }, AUTOPLAY_POI_SLIDE_VISIBLE_DURATION)
      }

      if (!this.state.autoplayPOIsEnabled) {
        clearTimeout(this.startAutoplayPOITimer)
      }
    }
  }

  private setPositions = (): void => {
    if (
      this.viewer &&
      this.stores.flightDataStore.simulationStartTime &&
      this.stores.flightDataStore.simulationEndTime &&
      this.stores.flightDataStore.aircraftEntity
    ) {
      this.viewer.clock.startTime = this.stores.flightDataStore.simulationStartTime.clone()
      this.viewer.clock.stopTime = this.stores.flightDataStore.simulationEndTime.clone()
      // console.log(
      //   'this.stores.flightDataStore.currentTime: ',
      //   this.stores.flightDataStore.currentTime,
      // )
      this.viewer.clock.currentTime = Cesium.JulianDate.fromDate(
        this.stores.flightDataStore.currentTime,
      )
      this.viewer.clock.clockRange = Cesium.ClockRange.UNBOUNDED

      if (this.stores.flightDataStore.flightIsRunning) {
        this.viewer.clock.multiplier = Constants.MAP_NORMAL_SPEED_MULTIPLIER
      } else {
        this.viewer.clock.multiplier = 0
      }

      // viewer.timeline.zoomTo(
      //   this.stores.flightDataStore.simulationStartTime,
      //   this.stores.flightDataStore.simulationEndTime,
      // ) // Set timeline to simulation bounds

      this.viewer.entities.add(this.stores.flightDataStore.aircraftEntity)

      // Position coin rings
      // this.stores.flightDataStore.positions.forEach(
      //   (position): void => {
      //     viewer.entities.add({
      //       position: position.flightPositionCartesian3,
      //       point: {
      //         pixelSize: 8,
      //         color: Cesium.Color.TRANSPARENT,
      //         outlineColor: Cesium.Color.YELLOW,
      //         outlineWidth: 3,
      //       },
      //     })
      //   },
      // )
    }
  }

  private initViewer = (): Cesium.Viewer => {
    const earthImageryProvider = Cesium.createTileMapServiceImageryProvider({
      url: `${Constants.REACT_APP_PILOT_URL}/static/tiles/earth`,
    })

    // const earthImageryProvider = new Cesium.TileMapServiceImageryProvider({
    //   url: `${Constants.REACT_APP_PILOT_URL}/static/tiles/earth`,
    // })

    // TODO: remove event listener
    earthImageryProvider.errorEvent.addEventListener((): void => {})

    const terrainProvider = new Cesium.CesiumTerrainProvider({
      url: `${Constants.REACT_APP_PILOT_URL}/static/terrain`,
    })

    // TODO: remove event listener
    terrainProvider.errorEvent.addEventListener((): void => {})

    const viewer = new Cesium.Viewer('cesiumContainer', {
      animation: false,
      shouldAnimate: true,
      fullscreenButton: false,
      requestRenderMode: true,
      // maximumRenderTimeChange: 1,
      useDefaultRenderLoop: true,
      targetFrameRate: Constants.MAP_FRAME_RATE,
      homeButton: false,
      sceneModePicker: false,
      navigationHelpButton: false,
      baseLayerPicker: false,
      geocoder: false,
      scene3DOnly: true,
      infoBox: false,
      selectionIndicator: false,
      globe: new Cesium.Globe(),
      // terrainShadows: Cesium.ShadowMode.RECEIVE_ONLY,
      sceneMode: Cesium.SceneMode.SCENE3D,
      // @ts-ignore
      terrainProvider: Cesium.createWorldTerrain(),
      // imageryProvider: earthImageryProvider,
      // @ts-ignore
      // imageryProvider: Cesium.createWorldImagery(),
      // terrainProvider,
      terrainExaggeration: 2,
      showRenderLoopErrors: false,
      // @ts-ignore
      // useBrowserRecommendedResolution: true,
      // contextOptions: {
      //   alpha: false,
      //   depth: false,
      //   stencil: false,
      //   antialias: true,
      //   premultipliedAlpha: false,
      //   preserveDrawingBuffer: false,
      // },
    })

    // Hide Cesium logo and timeline
    /* eslint-disable */
    // TODO: fix typescript errors
    // @ts-ignore
    viewer.scene._creditContainer.style = 'display: none'
    // TODO: fix typescript errors
    // @ts-ignore
    viewer.timeline.container.style = 'display: none'
    /* eslint-enable */

    // Add City tiles
    // const citiesImageryProvider = Cesium.createTileMapServiceImageryProvider({
    //   url: `${Constants.REACT_APP_PILOT_URL}/static/tiles/cities`,
    // })

    // TODO: remove event listener
    // citiesImageryProvider.errorEvent.addEventListener((): void => {})

    // viewer.scene.globe.imageryLayers.addImageryProvider(citiesImageryProvider)

    // Labels
    const labelsImageryProvider = Cesium.createTileMapServiceImageryProvider({
      url: `${Constants.REACT_APP_PILOT_URL}/static/tiles/labels`,
    })

    this.labelsImageryLayer = new Cesium.ImageryLayer(labelsImageryProvider, {})

    viewer.scene.globe.imageryLayers.add(this.labelsImageryLayer)

    this.labelsImageryLayer.show = this.state.labelsAreVisible

    viewer.scene.globe.enableLighting = true
    viewer.scene.globe.depthTestAgainstTerrain = true
    viewer.scene.skyBox.show = false

    // @ts-ignore
    viewer.scene.postProcessStages.bloom.enabled = false

    /* BEGIN LEGACY COMMENT */
    // this.viewer.scene.globe.depthTestAgainstTerrain = true
    // @ts-ignore
    // viewer.scene.postProcessStages.bloom.enabled = true
    // @ts-ignore
    // viewer.scene.postProcessStages.bloom.uniforms.glowOnly = false
    // @ts-ignore
    // viewer.scene.postProcessStages.bloom.uniforms.contrast = 128
    // // @ts-ignore
    // // viewer.scene.postProcessStages.bloom.uniforms.brightness = -0.3
    // // @ts-ignore
    // viewer.scene.postProcessStages.bloom.uniforms.delta = 1.0
    // // @ts-ignore
    // viewer.scene.postProcessStages.bloom.uniforms.sigma = 6.81
    // // @ts-ignore
    // viewer.scene.postProcessStages.bloom.uniforms.stepSize = 0.52
    /* END LEGACY COMMENT */

    const lowQuality = this.props.isBulkhead

    viewer.resolutionScale = 1 // lowQuality ? 0.9 : 1
    viewer.scene.backgroundColor = new Cesium.Color(55 / 255, 140 / 255, 219 / 255)
    // @ts-ignore
    viewer.scene.postProcessStages.fxaa.enabled = true
    // @ts-ignore
    viewer.scene.postProcessStages.ambientOcclusion.enabled = false
    // @ts-ignore
    viewer.scene.sunBloom = true
    viewer.scene.sun.glowFactor = 3
    viewer.scene.moon.show = false
    viewer.scene.skyAtmosphere.show = true
    // @ts-ignore
    viewer.scene.globe.loadingDescendantLimit = lowQuality ? 1000 : 20
    viewer.scene.globe.maximumScreenSpaceError = lowQuality ? 5 : 2
    // @ts-ignore
    viewer.scene.globe.preloadAncestors = !lowQuality
    // viewer.scene.globe.showGroundAtmosphere = true
    // viewer.scene.globe.enableLighting = true
    // @ts-ignore
    viewer.scene.skyAtmosphere.brightnessShift = 0
    viewer.scene.fog.enabled = false
    viewer.scene.fog.density = 0.00022
    // @ts-ignore
    viewer.scene.highDynamicRange = !lowQuality

    viewer.targetFrameRate = lowQuality ? 10 : Constants.MAP_FRAME_RATE

    // // @ts-ignore
    // viewer.scene.postProcessStages.removeAll()

    // viewer.scene.farToNearRatio = 500
    // viewer.scene.minimumDisableDepthTestDistance = -1

    // viewer.scene.sunBloom = false
    // viewer.scene.useDepthPicking = false
    // viewer.shadows = !lowQuality
    viewer.shadows = true
    // viewer.terrainShadows = lowQuality ? Cesium.ShadowMode.DISABLED : Cesium.ShadowMode.RECEIVE_ONLY
    // viewer.scene.debugShowFramesPerSecond = true

    return viewer
  }

  private initCamera = (): Camera | undefined => {
    const { flightDataStore } = this.stores

    if (flightDataStore.simulationStartTime && flightDataStore.simulationEndTime) {
      const camera = new Camera({
        viewer: this.viewer as Cesium.Viewer,
        flightDataStore: this.stores.flightDataStore,
        autoCameraStore: this.stores.autoCameraStore,
        onAnimationStart: this.handleAnimationStart,
        onAnimationEnd: this.handleAnimationEnd,
        onAutoCameraModeStart: this.handleAutoCameraModeStart,
        onAutoCameraModeStop: this.handleAutoCameraModeStop,
      })

      // camera.autoplayEnabled = true // true TODO: This may be dead code

      return camera
    }

    // TODO: handle error
    return undefined
  }

  private handleAnimationStart = (): void => {
    // console.log('this handleAnimationStart')
    this.setState({ isAnimating: true })
  }

  private handleAnimationEnd = (): void => {
    // console.log('this handleAnimationEnd')
    this.setState({ isAnimating: false })
  }

  private handleAutoCameraModeStart = (): void => {
    // console.log('this handleAutoCameraModeStart')
    // TODO: this can come from store
    this.setState({ autoCameraModeIsRunning: true })
  }

  private handleAutoCameraModeStop = (): void => {
    // console.log('this handleAutoCameraModeStop')
    this.setState({ autoCameraModeIsRunning: false, userHasCancelledThisAutoplayPOISlide: true })
  }

  private handleGeoRadioClick = (): void => {
    const { geoRadioIsVisible, isAnimating } = this.state

    if (isAnimating) {
      return
    }

    // TODO: I think this should be using the callback method
    this.setState(
      { geoRadioIsVisible: !geoRadioIsVisible },
      (): void => {
        if (this.viewer) {
          this.viewer.forceResize()
        }
      },
    )
  }

  private handleGeoRadioClose = (): void => {
    this.props.history.push('/map')
  }

  private handlePOIClick = (): void => {
    const { poiIsVisible, isAnimating } = this.state

    if (isAnimating) {
      return
    }

    this.setState({ poiIsVisible: !poiIsVisible })
  }

  private handlePOIClose = (): void => {
    // this.setState({ poiIsVisible: false })
    this.props.history.push('/map')
  }

  private handleSettingsClick = (): void => {
    const { settingsMenuIsVisible } = this.state

    this.setState({ settingsMenuIsVisible: !settingsMenuIsVisible })
  }

  private handleAutoplayPOIsToggle = (): void => {
    const { autoplayPOIsEnabled } = this.state

    this.setState({ autoplayPOIsEnabled: !autoplayPOIsEnabled })
  }

  // private handleBordersClick = (): void => {
  //   const { bordersAreVisible } = this.state

  //   // if (bordersAreVisible) {
  //   //   this.countryBordersDataSource.show = false
  //   // } else {
  //   //   this.countryBordersDataSource.show = true
  //   // }

  //   this.setState({ bordersAreVisible: !bordersAreVisible })
  // }

  private handleLabelsClick = (): void => {
    const { labelsAreVisible } = this.state

    if (labelsAreVisible && this.labelsImageryLayer) {
      this.labelsImageryLayer.show = false
    } else if (this.labelsImageryLayer) {
      this.labelsImageryLayer.show = true
    }

    // if (labelsAreVisible) {
    //   Object.values(this.placeNamesDataSources).forEach(ds => {
    //     ds.show = false
    //   })
    // } else {
    //   Object.values(this.placeNamesDataSources).forEach(ds => {
    //     ds.show = true
    //   })
    // }

    this.setState({ labelsAreVisible: !labelsAreVisible })
  }

  // private renderAutoplayPOI = (): JSX.Element | undefined => {
  //   const {
  //     autoCameraModeIsRunning,
  //     currentAutoplayPOISlide,
  //     shouldShowAutoplayPOISlide,
  //     userHasCancelledThisAutoplayPOISlide,
  //   } = this.state

  //   if (currentAutoplayPOISlide) {
  //     const duration = 1000
  //     const baseStyle = {
  //       // backgroundImage: currentAutoplayPOISlide
  //       //   ? `url(${currentAutoplayPOISlide.assetURL})`
  //       //   : 'none',
  //       transition: `opacity ${duration}ms ease-in-out`,
  //       opacity: 0,
  //     }

  //     const transitionStyles = {
  //       entering: { opacity: 0 },
  //       entered: { opacity: 1 },
  //     }

  //     return (
  //       <Transition
  //         in={
  //           autoCameraModeIsRunning &&
  //           currentAutoplayPOISlide &&
  //           shouldShowAutoplayPOISlide &&
  //           !userHasCancelledThisAutoplayPOISlide
  //         }
  //         timeout={1000}
  //         unmountOnExit
  //       >
  //         {(state): JSX.Element => (
  //           <S.AutoplayPOIContainer
  //             style={{ ...baseStyle, ...transitionStyles[state as 'entering' | 'entered'] }}
  //           >
  //             <S.AutoplayPOIImage src="http://gojiair.com:3003/poi/4k/us/fl/us_fl_orlando_is_21290339.jpg" />
  //             <S.AutoplayPOIMetaContainer>
  //               <POIMeta
  //                 title={currentAutoplayPOISlide.title}
  //                 subtitle={currentAutoplayPOISlide.subtitle}
  //                 description={currentAutoplayPOISlide.description}
  //               />
  //             </S.AutoplayPOIMetaContainer>
  //           </S.AutoplayPOIContainer>
  //         )}
  //       </Transition>
  //     )
  //   }

  //   return undefined
  // }

  private renderSettingsMenu = (): JSX.Element | null => {
    const {
      settingsMenuIsVisible,
      // bordersAreVisible,
      labelsAreVisible,
      autoplayPOIsEnabled,
    } = this.state

    if (!settingsMenuIsVisible) {
      return null
    }

    return (
      <Menu
        triggerElement={this.settingsButtonRef}
        onOutsideClick={(): void => {
          this.setState({ settingsMenuIsVisible: false })
        }}
      >
        <MenuItem
          leftIcon="autoplay"
          rightIcon={autoplayPOIsEnabled ? 'switch-on' : 'switch-off'}
          onClick={this.handleAutoplayPOIsToggle}
        >
          Autoplay POIs
        </MenuItem>
        {/* <MenuItem
          leftIcon="flag"
          rightIcon={bordersAreVisible ? 'switch-on' : 'switch-off'}
          onClick={this.handleBordersClick}
        >
          Borders
        </MenuItem> */}
        <MenuItem
          leftIcon="labels"
          rightIcon={labelsAreVisible ? 'switch-on' : 'switch-off'}
          onClick={this.handleLabelsClick}
        >
          Labels
        </MenuItem>
      </Menu>
    )
  }
}
