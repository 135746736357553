import React from 'react'
import Colors from '#styles/colors'
import Icon from '#components/Icon'
import * as S from './style'

interface Props {
  autoplayPOIsEnabled: boolean
  onAutoplayPOIsToggle: () => void
  labelsAreVisible: boolean
  onLabelsToggle: () => void
}

interface State {
  expanded: boolean
}

class ContextualNav extends React.PureComponent<Props, State> {
  public state = {
    expanded: false,
  }

  public render(): JSX.Element {
    const {
      autoplayPOIsEnabled,
      onAutoplayPOIsToggle,
      labelsAreVisible,
      onLabelsToggle,
    } = this.props
    const { expanded } = this.state
    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

    return (
      <S.Container expanded={expanded}>
        <S.TabContainer onClick={this.handleTabClick}>
          <svg width="24" height="160" style={{ position: 'absolute', right: 0 }}>
            <path
              fill={Colors.util.fade({ color: Colors.pureBlack, amount: 0 })}
              d="M 24,159.81 L 24,1.48 24,8.96 C 24,13.47 22.21,17.79 19.02,20.98 L 4.69,35.31 C 1.69,38.31 0,42.38 0,46.63 L 0,113.37 C 0,117.62 1.69,121.69 4.69,124.69 L 19.31,139.31 C 22.31,142.31 24,146.38 24,150.63 L 24,159.81 Z M 24,159.81"
            />
          </svg>
          <S.TabIconContainer>
            <Icon type="settings" />
          </S.TabIconContainer>
        </S.TabContainer>
        <S.ContentContainer>
          <S.MenuItem onClick={onAutoplayPOIsToggle}>
            <S.IconContainer>
              <Icon type={autoplayPOIsEnabled ? 'switch-on' : 'switch-off'} />
            </S.IconContainer>
            <S.ItemLabel>Autoplay POIs</S.ItemLabel>
          </S.MenuItem>

          {!isiOS && (
            <S.MenuItem onClick={onLabelsToggle}>
              <S.IconContainer>
                <Icon type={labelsAreVisible ? 'switch-on' : 'switch-off'} />
              </S.IconContainer>
              <S.ItemLabel>Labels</S.ItemLabel>
            </S.MenuItem>
          )}

          {/* <S.NavItem
            to="/map"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/map'
            }}
          >
            <S.IconContainer>
              <Icon type="map" />
            </S.IconContainer>
            <S.NavItemLabel>Map</S.NavItemLabel>
          </S.NavItem>
          <S.NavItem
            to="/map/geo-radio"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/map/geo-radio'
            }}
          >
            <S.IconContainer>
              <Icon type="geo-radio" />
            </S.IconContainer>
            <S.NavItemLabel>Audio Stories</S.NavItemLabel>
          </S.NavItem>
          <S.NavItem
            to="/map/poi"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/map/poi'
            }}
          >
            <S.IconContainer>
              <Icon type="map" />
            </S.IconContainer>
            <S.NavItemLabel>Visual Stories</S.NavItemLabel>
          </S.NavItem>

          <S.NavItem
            to="/entertainment/music"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/entertainment/music'
            }}
          >
            <S.IconContainer>
              <Icon type="music" />
            </S.IconContainer>
            <S.NavItemLabel>Music</S.NavItemLabel>
          </S.NavItem>
          <S.NavItem
            to="/entertainment/movies"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/entertainment/movies'
            }}
          >
            <S.IconContainer>
              <Icon type="movies" />
            </S.IconContainer>
            <S.NavItemLabel>Movies</S.NavItemLabel>
          </S.NavItem> */}
        </S.ContentContainer>
      </S.Container>
    )
  }

  private handleTabClick = (): void => {
    const { expanded } = this.state

    this.setState({
      expanded: !expanded,
    })
  }
}

export default ContextualNav
