import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled.span`
  position: relative;
  width: 100%;
  border-radius: 4px;
  display: block;
  overflow: hidden;

  ${(props: { aspectRatio: number }): string => `
    &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 0;
      padding-bottom: calc(100% / ${props.aspectRatio});
    }
  `}
`

export const Artwork = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

export const MetaContainer = styled.span`
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  padding: 34px 10px 10px;
  z-index: 2;
  display: grid;
  grid-template-areas:
    'title menuIcon'
    'subtitle menuIcon';
  grid-template-columns: 1fr auto;
  background-image: linear-gradient(
    -1deg,
    ${Colors.black1} 0%,
    ${Colors.util.fade({ color: Colors.black1, amount: 1 })} 100%
  );
`

export const Title = styled.span`
  display: block;
  ${Fonts.Body2Bold.Normal};
  color: ${Colors.white};
  text-shadow: 0 1px 4px ${Colors.black2};
  grid-area: title;
`

export const Subtitle = styled.span`
  display: block;
  ${Fonts.Caption.Normal};
  color: ${Colors.white};
  grid-area: subtitle;
`

export const MenuIcon = styled.button`
  width: 24px;
  height: 24px;
  grid-area: menuIcon;
  align-self: end;
  color: ${Colors.white};
  cursor: pointer;
  margin: 0;
  border: 0;
  padding: 0;
  background: none;
  display: block;
`
