import EventEmitter from 'events'
import CameraAnimation from '#lib/CameraAnimation'

interface Props {
  onComplete: () => void
}

export default class CameraAnimationQueue extends EventEmitter {
  public get isPlaying(): boolean {
    return this.playing
  }

  public get isCancelable(): boolean {
    return Boolean(this.currentAnimation && this.currentAnimation.isCancelable)
  }

  private get currentAnimation(): CameraAnimation | undefined {
    return this.animations[this.currentAnimationIndex]
  }

  private get totalAnimations(): number {
    return this.animations.length
  }

  public onComplete: () => void

  private animations: CameraAnimation[] = []
  private playing = false
  private currentAnimationIndex = 0

  public constructor(props?: Props) {
    super()

    this.onComplete = props ? props.onComplete : (): void => {}

    return this
  }

  public add = (animation: CameraAnimation | CameraAnimation[]): CameraAnimationQueue => {
    if (Array.isArray(animation)) {
      this.animations.push(...animation)
    } else {
      this.animations.push(animation)
    }

    return this
  }

  public play = (): CameraAnimationQueue => {
    if (this.playing || !this.currentAnimation) {
      return this
    }

    this.playing = true
    this.currentAnimation.play()

    return this
  }

  public empty = (): CameraAnimationQueue => {
    // TODO: destroy each CameraAnimation
    this.animations = []
    this.playing = false
    this.currentAnimationIndex = 0

    return this
  }

  public tick = (): CameraAnimationQueue => {
    if (!this.currentAnimation) {
      this.playing = false

      return this
    }

    this.currentAnimation.tick()

    if (this.currentAnimation.isComplete) {
      this.currentAnimationIndex += 1

      // Start next animation
      if (this.currentAnimation) {
        this.currentAnimation.play()
      }

      if (this.currentAnimationIndex === this.totalAnimations) {
        this.playing = false
        this.currentAnimationIndex = 0
        this.onComplete()
        this.emit('complete')
      }
    }

    return this
  }
}
