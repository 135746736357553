import React from 'react'
import { observer } from 'mobx-react'

import Constants from '#constants/index'
import { connect, ConnectedComponent } from '#utils/index'
import MusicStore from '#stores/MusicStore'
import AVCard from '#components/AVCard'
import * as S from './style'

interface Stores {
  musicStore: MusicStore
}

@connect('musicStore')
@observer
class MusicAlbumListView extends ConnectedComponent<{}, Stores> {
  public render(): JSX.Element {
    const {
      musicStore: { albums },
    } = this.stores

    return (
      <S.Container>
        {/* <TopNav label="Entertainment" to="/entertainment" /> */}
        <S.ScrollContainer>
          <S.AlbumList>
            {albums.map(
              (album): JSX.Element => (
                <S.AlbumLink to={`/entertainment/music/${album._id}`} key={album._id}>
                  <AVCard
                    artworkURL={`${Constants.REACT_APP_MEDIA_ASSETS_URL}/${album.albumArtworkPath}`}
                    title={album.artistName}
                    subtitle={album.title}
                    showMetaData
                  />
                </S.AlbumLink>
              ),
            )}
          </S.AlbumList>
        </S.ScrollContainer>
      </S.Container>
    )
  }
}

export default MusicAlbumListView
