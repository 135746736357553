import ColorModule from 'color'

enum Color {
  pureBlack = '#000000',
  black1 = '#1C293B',
  black2 = '#2C405A',
  white = '#fff',
  offWhite = '#ECF5FD',
  lightWhite = '#8DABC4',
  accent1 = '#0093EE',
  accent2 = '#4ECE3D',
  accent3 = '#FF151F',
  border1 = '#3F536E',
  backgroundGradient = 'radial-gradient(farthest-corner at 200px 70px, #39485a 0%, #1b2531 100%)',
  backgroundGradient1 = 'linear-gradient(0deg, #1C293B 0%, #223247 100%)',
}

// type valueof<T> = T[keyof T]

// type Color = typeof colors

// function hover(color: Color) {
//   return
// }

const util = {
  hover: (color: Color) =>
    ColorModule(color)
      .lighten(0.1)
      .string(),

  pressed: (color: Color) =>
    ColorModule(color)
      .darken(0.1)
      .string(),

  disabled: (color: Color) =>
    ColorModule(color)
      .grayscale()
      .negate()
      .fade(0.5)
      .string(),

  fade: ({ color, amount }: { color: Color; amount: number }) =>
    ColorModule(color)
      .fade(amount)
      .string(),

  lighten: ({ color, amount }: { color: Color; amount: number }) =>
    ColorModule(color)
      .lighten(amount)
      .string(),
}

export default {
  ...Color,
  util,
}
