import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Colors from '#styles/colors'
import Dimensions from '#styles/dimensions'

export const Container = styled.div`
  display: grid;
  color: ${Colors.offWhite};
  max-height: 100%;
  height: 100%;
  grid-template-areas:
    'top-nav'
    'scroll-container';
  grid-template-rows: min-content 1fr;
`

export const ScrollContainer = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  grid-area: scroll-container;
`

export const AlbumList = styled.ul`
  padding: 0;
  margin: 16px;
  margin-left: 40px;
  display: grid;
  grid-gap: 16px;

  ${Dimensions.tablet`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${Dimensions.desktop`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${Dimensions.desktopHD`
    grid-template-columns: repeat(6, 1fr);
  `};
`

export const AlbumLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
`
