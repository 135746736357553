import TransportLayer from '#services/TransportLayer'
import poiData from '#constants/poi-lax_sea'

export class PointOfInterest {
  public type: string
  public id: number
  public photoId: number
  public title: string
  public latitude: number
  public longitude: number
  public geotype: string
  public assetURL: string
  public thumbnailURL: string
  public description: string
  public subtitle: string

  public constructor(
    type: string,
    id: number,
    photoId: number,
    title: string,
    latitude: number,
    longitude: number,
    geotype: string,
    assetURL: string,
    thumbnailURL: string,
    description: string,
    subtitle: string,
  ) {
    this.type = type
    this.id = id
    this.photoId = photoId
    this.title = title
    this.latitude = latitude
    this.longitude = longitude
    this.geotype = geotype
    this.assetURL = assetURL
    this.thumbnailURL = thumbnailURL
    this.description = description
    this.subtitle = subtitle
  }
}

export default class PointOfInterestStore {
  public pointsOfInterest: PointOfInterest[] = []

  private transportLayer: TransportLayer

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer

    poiData.forEach(
      (poi): void => {
        const pointOfInterest = new PointOfInterest(
          poi.type,
          poi.id,
          poi.photoId,
          poi.title,
          poi.latitude,
          poi.longitude,
          poi.geotype,
          poi.assetURL,
          poi.thumbnailURL,
          poi.description,
          poi.subtitle,
        )

        this.pointsOfInterest.push(pointOfInterest)
      },
    )
  }
}
