import styled from 'styled-components'
import Colors from '#styles/colors'

export const Container = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  color: ${Colors.offWhite};
  background: ${Colors.pureBlack};
`

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
