import * as dateFns from 'date-fns'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import Constants from '#constants/index'
import { Movie } from '#stores/MovieStore'
import AVCard from '#components/AVCard'
import LinkButton from '#components/LinkButton'
import TopNav from '#components/TopNav'
import { getTimeDifferenceString } from '#utils/index'
import * as S from './style'

interface Props extends RouteComponentProps {
  movie?: Movie
}

class MovieDetailView extends React.PureComponent<Props> {
  public render(): JSX.Element | null {
    const { movie } = this.props

    if (!movie) {
      return null
    }

    return (
      <S.Container>
        <TopNav label="Movies" to="/entertainment/movies" />
        <S.ContentContainer>
          <div>
            <AVCard
              artworkURL={`${Constants.REACT_APP_MEDIA_ASSETS_URL}/${movie.posterArtworkPath}`}
              aspectRatio={Constants.MOVIE_POSTER_RATIO}
            />
          </div>
          <div>
            <S.Row>
              <S.Title>{movie.title}</S.Title>
            </S.Row>
            <S.Row>
              <S.Year>{movie.year}</S.Year>
            </S.Row>
            <S.Row>
              <S.Duration>
                {getTimeDifferenceString(
                  new Date(0),
                  dateFns.addSeconds(new Date(0), movie.durationInSeconds),
                  {
                    format: 'long',
                    showSeconds: false,
                  },
                )}
              </S.Duration>
            </S.Row>
            <S.Row>
              <S.Description>{movie.description}</S.Description>
            </S.Row>
            <S.Row>
              <LinkButton label="Play" to={`/entertainment/movies/${movie._id}/play`} />
            </S.Row>
          </div>
        </S.ContentContainer>
      </S.Container>
    )
  }
}

export default MovieDetailView
