import React from 'react'
import { observer } from 'mobx-react'
// @ts-ignore
import Div100vh from 'react-div-100vh'
import { connect, ConnectedComponent } from '#utils/index'
import FlightDataStore from '#stores/FlightDataStore'
import Nav from '#components/Nav'
import * as S from './style'

interface Stores {
  flightDataStore: FlightDataStore
}

@connect('flightDataStore')
@observer
class FlightInfoView extends ConnectedComponent<{}, Stores> {
  public render(): JSX.Element {
    const {
      flightDataStore: {
        airspeedInKnots,
        originName,
        destinationName,
        destinationTimeString,
        originTimeString,
        flightName,
        estimatedArrivalTimeString,
        timeUntilLanding,
        altitudeString,
        loaded,
      },
    } = this.stores

    return (
      <Div100vh>
        <S.Container>
          <S.ContentContainer>
            {loaded && (
              <>
                <S.CustomerLogo src="/images/partner-logo.png" alt="Gulfstream" />
                <S.Heading>
                  <S.FlightRoute>
                    {originName} to {destinationName}
                  </S.FlightRoute>
                  <S.FlightNumber>Flight {flightName}</S.FlightNumber>
                </S.Heading>
                <S.ColumnsContainer>
                  <div>
                    <S.Label>How long until we land?</S.Label>
                    <S.Value>{timeUntilLanding}</S.Value>

                    <S.Label>Time in {originName}</S.Label>
                    <S.Value>{originTimeString}</S.Value>

                    <S.Label>Altitude</S.Label>
                    <S.Value>{altitudeString}</S.Value>
                  </div>

                  <div>
                    <S.Label>{destinationName} landing time</S.Label>
                    <S.Value>{estimatedArrivalTimeString}</S.Value>

                    <S.Label>Time in {destinationName}</S.Label>
                    <S.Value>{destinationTimeString}</S.Value>

                    <S.Label>Airspeed</S.Label>
                    <S.Value>{airspeedInKnots.toLocaleString()} knots</S.Value>
                  </div>
                </S.ColumnsContainer>
                <S.BrandingContainer>
                  <img src="/images/powered-by-goji.png" alt="Powered by Goji" />
                </S.BrandingContainer>
              </>
            )}
          </S.ContentContainer>
          <Nav />
        </S.Container>
      </Div100vh>
    )
  }
}

export default FlightInfoView
