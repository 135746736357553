import React from 'react'
import * as S from './style'

interface Props {
  title: string
  subtitle: string
  description: string
}

const POIMeta = (props: Props): JSX.Element => (
  <div>
    <S.Title>{props.title}</S.Title>
    <S.Subtitle>{props.subtitle}</S.Subtitle>
    <S.Description>{props.description}</S.Description>
  </div>
)

export default POIMeta
