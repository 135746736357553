import { css } from 'styled-components'

export enum Size {
  desktopHD = 1440,
  desktop = 992,
  tablet = 768,
  phone = 376,
}

const dimensions = Object.keys(Size).reduce((sum: any, label: string) => {
  const newSum = Object.assign({}, sum)

  newSum[label] = (style: TemplateStringsArray, ...args: any) => css`
    @media (min-width: ${Size[(label as unknown) as Size]}px) {
      ${css(style, ...args)};
    }
  `

  return newSum
}, {})

export default dimensions
