import Cesium from 'cesium'
// import { autorun } from 'mobx'
import FlightDataStore from '#stores/FlightDataStore'
import AutoCameraStore from '#stores/AutoCameraStore'
import { clamp, getAircraftHeading, getCameraHeightByHeadingPitchRange } from '#utils/index'
import CameraAnimation from '#lib/CameraAnimation'
import CameraAnimationQueue from '#lib/CameraAnimationQueue'
import Constants from '#constants/index'

interface Props {
  viewer: Cesium.Viewer
  flightDataStore: FlightDataStore
  autoCameraStore: AutoCameraStore
  onAnimationStart: () => void
  onAnimationEnd: () => void
  onAutoCameraModeStart: () => void
  onAutoCameraModeStop: () => void
}

export default class Camera {
  public get currentAircraftHeading(): number {
    return getAircraftHeading(this.viewer, this.aircraftEntity)
  }

  private set userIsInteracting(value: boolean) {
    if (value === false) {
      this.startAutoCameraModeResumeTimer()
    } else {
      this.priorityAnimationQueue.empty()
      this.stopAutoCameraMode()
    }

    this._userIsInteracting = value
  }

  // private get userIsInteracting(): boolean {
  //   return this._userIsInteracting
  // }

  private set userInputDisabled(value: boolean) {
    this._userInputDisabled = value
  }

  private get userInputDisabled(): boolean {
    return this._userInputDisabled
  }

  public viewer: Cesium.Viewer
  public currentHeadingPitchRange = new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-20), 50)
  public currentTarget = new Cesium.Cartesian3()
  public aircraftEntity: Cesium.Entity
  public fixedTarget = false

  public currentPosition = new Cesium.Cartesian3()
  public currentDirection = new Cesium.Cartesian3()

  private flightDataStore: FlightDataStore
  private autoCameraStore: AutoCameraStore
  private priorityAnimationQueue = new CameraAnimationQueue()
  private mouseIsDown = false
  private autoCameraModeResumeTimer?: number

  // @ts-ignore
  private _userIsInteracting = false

  private _userInputDisabled = false
  private onAnimationStart: () => void
  private onAnimationEnd: () => void
  private onAutoCameraModeStart: () => void
  private onAutoCameraModeStop: () => void

  public constructor({
    viewer,
    flightDataStore,
    autoCameraStore,
    onAnimationStart,
    onAnimationEnd,
    onAutoCameraModeStart,
    onAutoCameraModeStop,
  }: Props) {
    this.viewer = viewer
    this.flightDataStore = flightDataStore
    this.autoCameraStore = autoCameraStore
    this.onAnimationStart = onAnimationStart
    this.onAnimationEnd = onAnimationEnd
    this.onAutoCameraModeStart = onAutoCameraModeStart
    this.onAutoCameraModeStop = onAutoCameraModeStop
    this.aircraftEntity = this.viewer.entities.getById('aircraft')
    this.currentTarget = this.getAircraftPosition()

    this.setEventListeners()
  }

  public flyToPercentage = async (destinationPercentage: number): Promise<void> => {
    this.userInputDisabled = true
    this.onAnimationStart()
    this.stopAutoCameraMode()
    this.priorityAnimationQueue
      .empty()
      .add(this.createOrbitToAircraftTopAnimation())
      .add(this.createZoomToFlightOverviewAnimations())
      .play()
      .once(
        'complete',
        async (): Promise<void> => {
          await this.animateAircraftToPercentage(destinationPercentage)

          this.priorityAnimationQueue
            .empty()
            .add(this.createZoomToAircraftAnimation())
            .play()
            .once(
              'complete',
              (): void => {
                this.userInputDisabled = false
                this.onAnimationEnd()
                this.autoCameraStore.play()
                this.onAutoCameraModeStart()
              },
            )
        },
      )
  }

  private mainRenderTick = (): void => {
    // Default to aircraft position
    if (!this.fixedTarget) {
      this.currentTarget = this.getAircraftPosition()
    }

    if (this.autoCameraStore.isRunning) {
      const autoCameraHPR = this.autoCameraStore.getCurrentHeadingPitchRangeByTimeAndCurrentAircraftHeading(
        this.viewer.clock.currentTime,
        this.currentAircraftHeading,
      )

      if (autoCameraHPR) {
        this.currentHeadingPitchRange = autoCameraHPR
      }
    } else if (this.priorityAnimationQueue.isPlaying) {
      // Animations may change this.currentTarget and this.currentHeadingPitchRange
      this.priorityAnimationQueue.tick()
    }

    this.viewer.camera.lookAt(this.currentTarget, this.currentHeadingPitchRange)
  }

  private startAutoCameraModeResumeTimer = (): void => {
    this.autoCameraModeResumeTimer = setTimeout(
      this.startAutoCameraMode,
      Constants.AUTO_CAMERA_MODE_RESUME_DELAY,
    )
  }

  private startAutoCameraMode = (): void => {
    const duration = 10
    const timeAtEndOfAnimation = Cesium.JulianDate.addSeconds(
      this.viewer.clock.currentTime,
      duration * Constants.MAP_NORMAL_SPEED_MULTIPLIER,
      new Cesium.JulianDate(),
    )
    const futureHPR = this.autoCameraStore.getCurrentHeadingPitchRangeByTimeAndCurrentAircraftHeading(
      timeAtEndOfAnimation,
      getAircraftHeading(this.viewer, this.aircraftEntity, timeAtEndOfAnimation),
    )

    if (futureHPR) {
      const animation = new CameraAnimation({
        camera: this,
        endingHeadingPitchRange: futureHPR,
        duration,
        headingEasingFunction: Cesium.EasingFunction.SINUSOIDAL_IN_OUT,
        pitchEasingFunction: Cesium.EasingFunction.QUINTIC_IN_OUT,
        rangeEasingFunction: Cesium.EasingFunction.QUINTIC_OUT,
      })

      this.onAnimationStart()

      this.priorityAnimationQueue
        .empty()
        .add(animation)
        .play()
        .once(
          'complete',
          (): void => {
            this.autoCameraStore.play()
            this.onAutoCameraModeStart()
            this.onAnimationEnd()
          },
        )
    }
  }

  private stopAutoCameraMode = (): void => {
    this.autoCameraStore.pause()
    clearTimeout(this.autoCameraModeResumeTimer)
    this.autoCameraModeResumeTimer = undefined
    this.onAutoCameraModeStop()
  }

  private getAircraftPosition = (atTime?: Cesium.JulianDate): Cesium.Cartesian3 => {
    const time = atTime || this.viewer.clock.currentTime

    // console.group()
    // console.log('startTime: ', this.viewer.clock.startTime)
    // console.log('time: ', time)
    // console.log('stopTime: ', this.viewer.clock.stopTime)
    // console.groupEnd()

    if (Cesium.JulianDate.lessThan(time, this.viewer.clock.startTime)) {
      // Return the origin position
      return this.flightDataStore.positions[0].flightPositionCartesian3
    }

    // TODO: destination position

    // console.log(
    //   'this.aircraftEntity.position.getValue(time): ',
    //   this.aircraftEntity.position.getValue(time),
    // )

    const currentAircraftPosition = this.aircraftEntity.position.getValue(time)

    if (!currentAircraftPosition) {
      // console.log('no aircraft position')
      return new Cesium.Cartesian3(10, 10, 10)
    }

    return currentAircraftPosition
  }

  private createZoomToAircraftAnimation = (): CameraAnimation => {
    const duration = 4
    const timeAtEndOfAnimation = Cesium.JulianDate.addSeconds(
      this.viewer.clock.currentTime,
      duration * Constants.MAP_NORMAL_SPEED_MULTIPLIER,
      new Cesium.JulianDate(),
    )

    const endingHeadingPitchRange = this.autoCameraStore.getCurrentHeadingPitchRangeByTimeAndCurrentAircraftHeading(
      timeAtEndOfAnimation,
      getAircraftHeading(this.viewer, this.aircraftEntity, timeAtEndOfAnimation),
    ) as Cesium.HeadingPitchRange

    // if (!endingHeadingPitchRange) {
    //   endingHeadingPitchRange = (currentTime: Cesium.JulianDate): Cesium.HeadingPitchRange => {
    //     const animationEndingTime = Cesium.JulianDate.addSeconds(
    //       currentTime,
    //       duration,
    //       new Cesium.JulianDate(),
    //     )

    //     return new Cesium.HeadingPitchRange(
    //       getAircraftHeading(this.viewer, this.aircraftEntity, animationEndingTime) +
    //         (45 / 180) * Math.PI,
    //       Cesium.Math.toRadians(-25),
    //       100,
    //     )
    //   }
    // }

    return new CameraAnimation({
      camera: this,
      endingHeadingPitchRange,
      duration,
      headingEasingFunction: Cesium.EasingFunction.SINUSOIDAL_OUT,
      pitchEasingFunction: Cesium.EasingFunction.SINUSOIDAL_OUT,
      rangeEasingFunction: Cesium.EasingFunction.QUARTIC_OUT,
      // endingTarget: (currentTime): Cesium.Cartesian3 => {
      //   const animationEndingTime = Cesium.JulianDate.addSeconds(
      //     currentTime,
      //     duration,
      //     new Cesium.JulianDate(),
      //   )

      //   return this.aircraftEntity.position.getValue(animationEndingTime)
      // },
    })
  }

  private createOrbitToAircraftTopAnimation = (): CameraAnimation => {
    const duration = 2
    const { currentTime } = this.viewer.clock
    const animationEndingTime = Cesium.JulianDate.addSeconds(
      currentTime,
      duration,
      new Cesium.JulianDate(),
    )

    // PLANE_TOP
    const endingHeadingPitchRange = new Cesium.HeadingPitchRange(
      getAircraftHeading(this.viewer, this.aircraftEntity, animationEndingTime),
      Cesium.Math.toRadians(-89),
      500,
    )

    return new CameraAnimation({
      camera: this,
      duration,
      endingHeadingPitchRange,
      rangeEasingFunction: Cesium.EasingFunction.QUADRATIC_IN,
    })
  }

  private createZoomToFlightOverviewAnimations = (): CameraAnimation[] => {
    // const rectangleDegrees = {
    //   west: Math.min(
    //     this.flightDataStore.originPosition.longitude,
    //     this.flightDataStore.destinationPosition.longitude,
    //   ),
    //   south: Math.min(
    //     this.flightDataStore.originPosition.latitude,
    //     this.flightDataStore.destinationPosition.latitude,
    //   ),
    //   east: Math.max(
    //     this.flightDataStore.originPosition.longitude,
    //     this.flightDataStore.destinationPosition.longitude,
    //   ),
    //   north: Math.max(
    //     this.flightDataStore.originPosition.latitude,
    //     this.flightDataStore.destinationPosition.latitude,
    //   ),
    // }

    // const widthPadding = (rectangleDegrees.east - rectangleDegrees.west) / 8
    // const heightPadding = (rectangleDegrees.north - rectangleDegrees.south) / 8

    // rectangleDegrees.west -= widthPadding
    // rectangleDegrees.south -= heightPadding
    // rectangleDegrees.east += widthPadding
    // rectangleDegrees.north += heightPadding

    // const finalRectangle = Cesium.Rectangle.fromDegrees(
    //   rectangleDegrees.west,
    //   rectangleDegrees.south,
    //   rectangleDegrees.east,
    //   rectangleDegrees.north,
    // )

    const duration = 3
    const rangeEasingFunction = Cesium.EasingFunction.CUBIC_IN_OUT

    // this.autoCameraModeIsRunning = false

    const animationEndingTime = Cesium.JulianDate.addSeconds(
      this.viewer.clock.currentTime,
      duration,
      new Cesium.JulianDate(),
    )

    return [
      // Zoom out to globe
      new CameraAnimation({
        camera: this,
        endingHeadingPitchRange: new Cesium.HeadingPitchRange(
          getAircraftHeading(this.viewer, this.aircraftEntity, animationEndingTime),
          Cesium.Math.toRadians(-40),
          10000000,
        ),
        rangeEasingFunction,
        duration: duration * 0.5,
      }),
      // Zoom in to rectangle of entire flight path
      // new CameraAnimation({
      //   camera: this,
      //   endingHeadingPitchRange,
      //   rangeEasingFunction,
      //   headingEasingFunction: Cesium.EasingFunction.CUBIC_IN_OUT,
      //   pitchEasingFunction: Cesium.EasingFunction.CUBIC_IN_OUT,
      //   duration: duration * 0.5,
      //   endingTarget,
      //   setFixedTargetAtEnd: true,
      //   onComplete: (): void => {
      //     setTimeout((): void => {
      //       this.fixedTarget = false
      //     }, 6000)
      //   },
      // }),
    ]
  }

  private animateAircraftToPercentage = (
    destinationPercentage: number,
    // @ts-ignore
    duration = 1000, // eslint-disable-line
  ): Promise<void> =>
    new Promise(
      (resolve): void => {
        if (!this.flightDataStore.simulationStartTime || !this.flightDataStore.simulationEndTime) {
          return resolve()
        }

        // TODO: this uses flightDataStore.flightDurationInSeconds which is no longer available

        // const currentPercentageOfFlight =
        //   Cesium.JulianDate.secondsDifference(
        //     this.viewer.clock.currentTime,
        //     this.flightDataStore.simulationStartTime,
        //   ) / this.flightDataStore.flightDurationInSeconds

        // this.viewer.clock.multiplier =
        //   this.flightDataStore.flightDurationInSeconds *
        //   (destinationPercentage - currentPercentageOfFlight)

        // setTimeout((): void => {
        //   this.viewer.clock.multiplier = Constants.MAP_NORMAL_SPEED_MULTIPLIER
        //   resolve()
        // }, duration)

        return undefined
      },
    )

  private setEventListeners = (): void => {
    // TODO: remove listener
    this.viewer.scene.postUpdate.addEventListener(this.mainRenderTick)
    // this.viewer.clock.onTick.addEventListener(this.mainRenderTick)

    this.viewer.screenSpaceEventHandler.setInputAction(
      // @ts-ignore
      this.handleMouseWheel,
      Cesium.ScreenSpaceEventType.WHEEL,
    )

    this.viewer.screenSpaceEventHandler.setInputAction(
      this.handleMouseLeftDown,
      Cesium.ScreenSpaceEventType.LEFT_DOWN,
    )

    this.viewer.screenSpaceEventHandler.setInputAction(
      this.handleMouseLeftUp,
      Cesium.ScreenSpaceEventType.LEFT_UP,
    )

    this.viewer.screenSpaceEventHandler.setInputAction(
      // @ts-ignore
      this.handleMouseMove,
      Cesium.ScreenSpaceEventType.MOUSE_MOVE,
    )

    this.viewer.screenSpaceEventHandler.setInputAction(
      this.handlePinchStart,
      Cesium.ScreenSpaceEventType.PINCH_START,
    )

    this.viewer.screenSpaceEventHandler.setInputAction(
      // @ts-ignore
      this.handlePinchMove,
      Cesium.ScreenSpaceEventType.PINCH_MOVE,
    )

    this.viewer.screenSpaceEventHandler.setInputAction(
      this.handlePinchEnd,
      Cesium.ScreenSpaceEventType.PINCH_END,
    )
  }

  private handleMouseWheel = (delta: number): void => {
    if (this.userInputDisabled) {
      return
    }
    // TODO: clamp max to distance from plane to surface so that the camera
    // doesn't go below the surface
    const currentRange = this.currentHeadingPitchRange.range
    const coefficient = (currentRange / 1000) * -1
    const newRange = delta * coefficient + currentRange
    const newCameraRange = clamp(Constants.CAMERA_MIN_RANGE, newRange, Constants.CAMERA_MAX_RANGE)

    this.userIsInteracting = true

    // only allow user to zoom if the camera's height isn't below the threshold
    const futureHeadingPitchRange = new Cesium.HeadingPitchRange(
      this.currentHeadingPitchRange.heading,
      this.currentHeadingPitchRange.pitch,
      newCameraRange,
    )

    if (
      getCameraHeightByHeadingPitchRange(
        this.viewer,
        this.currentTarget,
        futureHeadingPitchRange,
      ) <= Constants.CAMERA_MIN_HEIGHT
    ) {
      // Don't do anything
      this.userIsInteracting = false
      return
    }

    this.currentHeadingPitchRange.range = newCameraRange
    this.userIsInteracting = false
  }

  private handleMouseLeftDown = (): void => {
    if (this.userInputDisabled) {
      return
    }

    this.mouseIsDown = true
    this.userIsInteracting = true
  }

  private handleMouseLeftUp = (): void => {
    if (this.userInputDisabled) {
      return
    }

    this.mouseIsDown = false
    this.userIsInteracting = false
  }

  private handleMouseMove = ({
    startPosition,
    endPosition,
  }: {
    startPosition: {
      x: number
      y: number
    }
    endPosition: {
      x: number
      y: number
    }
  }): void => {
    if (this.userInputDisabled) {
      return
    }

    if (this.mouseIsDown) {
      const coefficient = 0.005
      const deltaX = (endPosition.x - startPosition.x) * coefficient
      const deltaY = (endPosition.y - startPosition.y) * coefficient

      const newHeading = this.currentHeadingPitchRange.heading + deltaX
      const newPitch = this.currentHeadingPitchRange.pitch - deltaY
      const newCameraPitch = clamp(Constants.CAMERA_MIN_PITCH, newPitch, Constants.CAMERA_MAX_PITCH)

      const futureHeadingPitchRange = new Cesium.HeadingPitchRange(
        newHeading,
        newCameraPitch,
        this.currentHeadingPitchRange.range,
      )

      if (
        getCameraHeightByHeadingPitchRange(
          this.viewer,
          this.currentTarget,
          futureHeadingPitchRange,
        ) <= Constants.CAMERA_MIN_HEIGHT
      ) {
        // Allow the user to pan, but not pitch down
        this.currentHeadingPitchRange.heading = newHeading
        return
      }

      this.currentHeadingPitchRange.heading = newHeading
      this.currentHeadingPitchRange.pitch = newCameraPitch
    }
  }

  private handlePinchStart = (): void => {
    if (this.userInputDisabled) {
      return
    }

    this.userIsInteracting = true
  }

  private handlePinchMove = ({
    distance: { startPosition, endPosition },
  }: {
    distance: {
      startPosition: {
        x: number
        y: number
      }
      endPosition: {
        x: number
        y: number
      }
    }
  }): void => {
    if (this.userInputDisabled) {
      return
    }

    const currentRange = this.currentHeadingPitchRange.range

    // const rangeEl = document.getElementById('range')
    // if (rangeEl) {
    //   rangeEl.innerHTML = currentRange
    // }
    const coefficient = currentRange < 50000 ? currentRange * 10 : currentRange * 3
    const delta = Cesium.Cartesian2.distance(
      new Cesium.Cartesian2(startPosition.x, startPosition.y),
      new Cesium.Cartesian2(endPosition.x, endPosition.y),
    )
    const direction = endPosition.y - startPosition.y > 0 ? -1 : 1
    const newRange = currentRange + Cesium.Math.toRadians(delta * coefficient * direction)
    const newCameraRange = clamp(Constants.CAMERA_MIN_RANGE, newRange, Constants.CAMERA_MAX_RANGE)

    this.userIsInteracting = true

    if (delta < 0) {
      // If we're zooming out, only allow it if the camera's height isn't below the threshold
      const futureHeadingPitchRange = new Cesium.HeadingPitchRange(
        this.currentHeadingPitchRange.heading,
        this.currentHeadingPitchRange.pitch,
        newCameraRange,
      )

      if (
        getCameraHeightByHeadingPitchRange(
          this.viewer,
          this.currentTarget,
          futureHeadingPitchRange,
        ) <= Constants.CAMERA_MIN_HEIGHT
      ) {
        // Don't do anything
        this.userIsInteracting = false
        return
      }
    }

    this.currentHeadingPitchRange.range = newCameraRange
  }

  private handlePinchEnd = (): void => {
    if (this.userInputDisabled) {
      return
    }

    this.userIsInteracting = false
  }
}
