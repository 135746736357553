import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTransition, config } from 'react-spring'
import { connect, ConnectedComponent } from '#utils/index'
import FlightDataStore from '#stores/FlightDataStore'
import * as S from './style'

interface Props {
  isBulkhead: boolean
}

interface Stores {
  flightDataStore: FlightDataStore
}

@connect('flightDataStore')
@observer
export default class Map extends ConnectedComponent<Props, Stores> {
  public state = {
    expanded: false,
  }

  public render(): JSX.Element {
    const { isBulkhead } = this.props
    const { flightDataStore } = this.stores
    const [index, set] = useState(0)
    const slides = [
      {
        id: 0,
        label: 'Time at destination',
        value: flightDataStore.destinationTimeString,
      },
      {
        id: 1,
        label: 'Airspeed',
        value: `${flightDataStore.airspeedInKnots} knots`,
      },
      {
        id: 2,
        label: 'Altitude',
        value: `${Math.round(flightDataStore.altitudeInFeet).toLocaleString()} ft`,
      },
      {
        id: 3,
        label: 'Distance to destination',
        value: `${Math.round(flightDataStore.distanceToDestinationInMiles).toLocaleString()} miles`,
      },
      {
        id: 4,
        label: 'Estimated time of arrival',
        value: flightDataStore.estimatedArrivalTimeString,
      },
      {
        id: 5,
        label: 'Local time at destination',
        value: flightDataStore.destinationTimeString,
      },
      {
        id: 6,
        label: 'Local time at origin',
        value: flightDataStore.originTimeString,
      },
    ]

    const transitions = useTransition(slides[index], (item): number => item.id, {
      from: { opacity: 0, transform: 'translate3d(0, 100%, 0)' },
      enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
      leave: { opacity: 0, transform: 'translate3d(0, -100%, 0)' },
      config: config.slow,
      // from: { opacity: 0 },
      // enter: { opacity: 1 },
      // leave: { opacity: 0 },
      // config: config.wobbly,
    })

    /* eslint-disable */
    useEffect(
      (): any =>
        void setInterval((): void => set((state): number => (state + 1) % slides.length), 10000),
      [],
    )
    /* eslint-enable */

    return (
      <S.Container large={isBulkhead}>
        <S.Section>
          <S.Label large={isBulkhead}>Time to destination</S.Label>
          <S.Value large={isBulkhead}>{this.stores.flightDataStore.timeUntilLanding}</S.Value>
        </S.Section>
        <S.Section>
          &nbsp;
          {/* <S.Label large={isBulkhead}>Time at destination</S.Label>
          <S.Value large={isBulkhead}>{this.stores.flightDataStore.destinationTimeString}</S.Value> */}
          {transitions.map(
            ({ item, props, key }): JSX.Element => (
              <S.AnimatedContainer
                key={key}
                style={{
                  ...props,
                }}
              >
                <S.Label large={isBulkhead}>{item.label}</S.Label>
                <S.Value large={isBulkhead}>{item.value}</S.Value>
              </S.AnimatedContainer>
            ),
          )}
        </S.Section>
      </S.Container>
    )
  }
}
