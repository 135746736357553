import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled.div`
  margin: 24px 16px;
  display: flex;
`

export const Link = styled(NavLink)`
  display: flex;
  text-decoration: none;
  color: ${Colors.offWhite};
  ${Fonts.H2.Normal};
  align-items: center;
`

export const BackIconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`
