import React from 'react'
import * as S from './style'

interface Props {
  label?: string
  onClick?: () => void
}

const Button = ({ label = '', onClick = (): void => {} }: Props): JSX.Element => (
  <S.Container onClick={onClick}>{label}</S.Container>
)

export default Button
