import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import Button from '#components/Button'

interface Props extends RouteComponentProps {
  label?: string
  to: string
}

class LinkButton extends React.Component<Props, {}> {
  public render(): JSX.Element {
    const { label } = this.props

    return <Button onClick={this.handleClick} label={label} />
  }

  private handleClick = (): void => {
    this.props.history.push(this.props.to)
  }
}

export default withRouter(LinkButton)
