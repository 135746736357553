import styled from 'styled-components'
import Colors from '#styles/colors'

export const Container = styled.div`
  position: absolute;
  width: 216px;
  top: 100%;
  left: 50%;
  margin-left: -108px;
  padding-top: 8px;
`

export const MenuContainer = styled.div`
  background: ${Colors.black2};
  border: 1px solid ${Colors.border1};
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(28, 41, 59, 0.5);
  padding: 8px 4px;
  position: relative;

  > img {
    position: absolute;
    width: 17px;
    height: 11px;
    top: -11px;
    left: 50%;
    margin-left: -8.5px;
  }
`
