import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import Colors from '#styles/colors'
import Icon from '#components/Icon'
import * as S from './style'

interface State {
  expanded: boolean
}

class MainNav extends React.PureComponent<RouteComponentProps, State> {
  public state = {
    expanded: false,
  }

  public render(): JSX.Element {
    const { expanded } = this.state

    return (
      <S.Container expanded={expanded}>
        <S.GlobalActiveNavStyle />
        <S.ContentContainer>
          <S.NavItem
            to="/map"
            exact
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              if (this.props.match.path !== '/map') {
                window.location.href = '/map'
              }
            }}
          >
            <S.IconContainer>
              <Icon type="map" />
            </S.IconContainer>
            <S.NavItemLabel>Map</S.NavItemLabel>
          </S.NavItem>
          <S.NavItem
            to="/map/geo-radio"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              if (this.props.match.path !== '/map') {
                window.location.href = '/map/geo-radio'
              }
            }}
          >
            <S.IconContainer>
              <Icon type="geo-radio" />
            </S.IconContainer>
            <S.NavItemLabel>Audio Stories</S.NavItemLabel>
          </S.NavItem>
          <S.NavItem
            to="/map/poi"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              if (this.props.match.path !== '/map') {
                window.location.href = '/map/poi'
              }
            }}
          >
            <S.IconContainer>
              <Icon type="map" />
            </S.IconContainer>
            <S.NavItemLabel>Photo Stories</S.NavItemLabel>
          </S.NavItem>

          <S.NavItem
            to="/entertainment/music"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/entertainment/music'
            }}
          >
            <S.IconContainer>
              <Icon type="music" />
            </S.IconContainer>
            <S.NavItemLabel>Music</S.NavItemLabel>
          </S.NavItem>
          <S.NavItem
            to="/entertainment/movies"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/entertainment/movies'
            }}
          >
            <S.IconContainer>
              <Icon type="movies" />
            </S.IconContainer>
            <S.NavItemLabel>Movies</S.NavItemLabel>
          </S.NavItem>
        </S.ContentContainer>
        <S.TabContainer onClick={this.handleTabClick}>
          <svg width="24" height="160">
            <path
              fill={Colors.util.fade({ color: Colors.pureBlack, amount: 0 })}
              d="M 0,159.81 L 0,1.48 0,8.96 C 0,13.47 1.79,17.79 4.98,20.98 L 19.31,35.31 C 22.31,38.31 24,42.38 24,46.63 L 24,113.37 C 24,117.62 22.31,121.69 19.31,124.69 L 4.69,139.31 C 1.69,142.31 0,146.38 0,150.63 L 0,159.81 Z M 0,159.81"
            />
          </svg>

          <svg
            width="6"
            height="16"
            style={{
              position: 'absolute',
              top: '72px',
              left: '9px',
            }}
          >
            <path
              fill={Colors.util.fade({ color: Colors.white, amount: 0.25 })}
              d="M 5,-0 C 4.45,-0 4,0.45 4,1 L 4,15 C 4,15.55 4.45,16 5,16 5.55,16 6,15.55 6,15 L 6,1 C 6,0.45 5.55,-0 5,-0 Z M 5,-0"
            />
            <path
              fill={Colors.util.fade({ color: Colors.white, amount: 0.25 })}
              d="M 1,-0 C 0.45,-0 0,0.45 0,1 L 0,15 C 0,15.55 0.45,16 1,16 1.55,16 2,15.55 2,15 L 2,1 C 2,0.45 1.55,-0 1,-0 Z M 1,-0"
            />
          </svg>
        </S.TabContainer>
      </S.Container>
    )
  }

  private handleTabClick = (): void => {
    const { expanded } = this.state

    this.setState({
      expanded: !expanded,
    })
  }
}

export default withRouter(MainNav)
