import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

// const cesiumContainerScale = 0.15
// const normalizeScaleToPixel = (pixelSize: number): string =>
//   `${Math.floor(pixelSize * (1 / cesiumContainerScale))}px`
// const cesiumContainerAutoplayStyle = {
//   transform: `scale(${cesiumContainerScale}, ${cesiumContainerScale}) translate(${normalizeScaleToPixel(
//     10,
//   )}, ${normalizeScaleToPixel(10)})`,
//   boxShadow: `0 0 0 ${normalizeScaleToPixel(1)} ${
//     Colors.offWhite
//   }, 0 24px 32px 0 ${Colors.util.fade({ color: Colors.pureBlack, amount: 0.9 })}`,
// }

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
`

export const MapContainer = styled.div`
  flex: 6;
  position: relative;
  width: 100%;
`

export const CesiumAndSubNavContainer = styled.div`
  background: ${Colors.pureBlack};
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const CesiumContainer = styled.div<{ autoplayActive: boolean }>`
  height: 100%;
  transform-origin: left top;
  transition-property: transform;
  transition-duration: 1.5s;
  transition-timing-function: ease-in-out;
  position: relative;
  /* opacity: ${({ autoplayActive }): number => (autoplayActive ? 0 : 1)}; */
`

// ${(props): FlattenSimpleInterpolation | null =>
//   props.autoplayActive
//     ? css`
//         ${cesiumContainerAutoplayStyle}
//       `
//     : null};

export const SubNavContainer = styled.div<{ autoplayActive: boolean }>`
  position: absolute;
  top: 8px;
  width: 248px;
  height: 56px;
  left: 50%;
  margin-left: -124px;
  background: ${Colors.util.fade({ color: Colors.black2, amount: 0.2 })};
  border-radius: 4px;
  display: ${(props): string => (props.autoplayActive ? 'none' : 'block')};
`

export const NavItem = styled.button`
  width: 104px;
  display: inline-block;
  text-decoration: none;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
  color: ${(props: { active: boolean }): string =>
    props.active ? Colors.accent1 : Colors.offWhite};

  &:nth-child(1) {
    width: 72px;
  }

  &:nth-child(2) {
    width: 104px;
  }

  &:nth-child(3) {
    width: 72px;
  }

  /* &:nth-child(4) {
    width: 72px;
  } */
`

export const IconContainer = styled('div')`
  width: 24px;
  height: 24px;
  margin: 8px auto 4px auto;
`

export const NavItemLabel = styled('div')`
  ${Fonts.Tag.Normal};
  text-align: center;
`

// const activeNavItemClassName = css`
//   ${NavItemLabel}, ${IconContainer} {
//     color: ${Colors.accent1};
//   }
// `

export const SideMenuContainer = styled.div`
  flex: 4;
  z-index: 10;
`

export const POIContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
`

export const WelcomeScreenContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
`
