import Cesium from 'cesium'

const getVarFromEnv = (key: string): string => {
  const value = process.env[key]

  if (!value || !value.length) {
    throw new Error(`${key} not set`)
  }

  return value
}

const varsFromEnv = {
  REACT_APP_MEDIA_ASSETS_URL: getVarFromEnv('REACT_APP_MEDIA_ASSETS_URL'),
  REACT_APP_PILOT_URL: getVarFromEnv('REACT_APP_PILOT_URL'),
  REACT_APP_PILOT_WEB_SOCKET_URL: getVarFromEnv('REACT_APP_PILOT_WEB_SOCKET_URL'),
}

const generalSettings = {
  MOVIE_POSTER_RATIO: 27 / 40,
  AUTOPLAY_POI_SLIDE_VISIBLE_DURATION: 25000,
  N_SECONDS_TO_PAD_AUTOPLAY_POI_START_TIME: 20,
  POI_SLIDE_DURATION: 30,
}

const mapSettings = {
  MAP_NORMAL_SPEED_MULTIPLIER: 1,
  MAP_FRAME_RATE: 60,
  MAP_LOADING_CHECK_FREQUENCY: 250,
}

const cameraSettings = {
  CAMERA_MIN_RANGE: 20,
  CAMERA_MAX_RANGE: 15000000,
  CAMERA_MIN_PITCH: Cesium.Math.toRadians(-85),
  CAMERA_MAX_PITCH: Cesium.Math.toRadians(85),
  CAMERA_MIN_HEIGHT: 10000,
}

const autoplaySettings = {
  AUTO_CAMERA_MODE_RESUME_DELAY: 2000,
}

export default {
  ...varsFromEnv,
  ...generalSettings,
  ...mapSettings,
  ...cameraSettings,
  ...autoplaySettings,
}
