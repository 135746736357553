import styled from 'styled-components'
import Colors from '#styles/colors'

export const Container = styled.div`
  /* display: flex;
  height: 100vh;
  max-height: 100vh;
  flex-direction: column;
  position: relative; */
  height: 100%;
  overflow: hidden;
  position: relative;
`

export const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1 1;
  align-items: stretch;
  position: relative;
  height: 100px; /* TODO: this is a hack */
`

export const LoadingContainerStyle = {
  background: Colors.backgroundGradient,
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: 20,
  transition: 'opacity 1000ms ease-in-out',
  opacity: 0,
}

export const LoadingContainerTransitionStyle = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
}
