import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled('div')`
  background: ${Colors.black2};
  display: grid;
  height: 100%;
  grid-template-rows: auto min-content;
  align-items: stretch;
  grid-template-areas:
    'content'
    'nav';
`

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 128px;
  justify-items: center;
  align-items: center;
  color: ${Colors.offWhite};
`

export const Item = styled(NavLink)`
  ${Fonts.H1.Normal};
  color: ${Colors.offWhite};
  text-align: center;
  text-decoration: none;
  justify-self: end;

  &:last-child {
    justify-self: start;
  }
`

export const IconContainer = styled.div`
  width: 128px;
  height: 128px;
  margin: 0 auto 16px;
`
