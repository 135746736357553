import React from 'react'
import Constants from '#constants/index'
import { Movie } from '#stores/MovieStore'
import AVCard from '#components/AVCard'
// import TopNav from '#components/TopNav'
import * as S from './style'

interface Props {
  movies: Movie[]
}

class MovieListView extends React.PureComponent<Props, {}> {
  public render(): JSX.Element {
    const { movies } = this.props

    return (
      <S.Container>
        {/* <TopNav label="Entertainment" to="/entertainment" /> */}
        <S.ScrollContainer>
          <S.MovieList>
            {movies.map(
              (movie): JSX.Element => (
                <S.MovieLink to={`/entertainment/movies/${movie._id}`} key={movie._id}>
                  <AVCard
                    artworkURL={`${Constants.REACT_APP_MEDIA_ASSETS_URL}/${
                      movie.posterArtworkPath
                    }`}
                    aspectRatio={Constants.MOVIE_POSTER_RATIO}
                    title={movie.title}
                    showMetaData
                  />
                </S.MovieLink>
              ),
            )}
          </S.MovieList>
        </S.ScrollContainer>
      </S.Container>
    )
  }
}

export default MovieListView
