import { configure } from 'mobx'
import TransportLayer from '#services/TransportLayer'
import MovieStore from '#stores/MovieStore'
import MusicStore from '#stores/MusicStore'
import FlightDataStore from '#stores/FlightDataStore'
import AutoCameraStore from '#stores/AutoCameraStore'
import PointOfInterestStore from '#stores/PointOfInterestStore'
import GeoRadioStore from '#stores/GeoRadioStore'

configure({
  enforceActions: 'observed',
})

const transportLayer = new TransportLayer()
const movieStore = new MovieStore(transportLayer)
const musicStore = new MusicStore(transportLayer)
const flightDataStore = new FlightDataStore(transportLayer)
const autoCameraStore = new AutoCameraStore(flightDataStore)
const pointOfInterestStore = new PointOfInterestStore(transportLayer)
const geoRadioStore = new GeoRadioStore(transportLayer)

export const RootStore = {
  movieStore,
  musicStore,
  flightDataStore,
  autoCameraStore,
  pointOfInterestStore,
  geoRadioStore,
}
