import { css } from 'styled-components'

const Secondary = css`
  opacity: 0.5;
`

const Disabled = css`
  opacity: 0.25;
`

const Tag = css`
  font-family: Heebo-Regular, sans-serif;
  font-size: 10px;
  line-height: 16px;
`

const Caption = css`
  font-family: Heebo-Regular, sans-serif;
  font-size: 13px;
  line-height: 19px;
`

const Body2 = css`
  font-family: Heebo-Regular, sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
`

const Body1 = css`
  font-family: Heebo-Regular, sans-serif;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 25px;
`

const H3 = css`
  font-family: Heebo-Regular, sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
`

const H2 = css`
  font-family: Heebo-Regular, sans-serif;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 22px;
`

const H1 = css`
  font-family: Heebo-Regular, sans-serif;
  font-size: 23px;
  letter-spacing: 0;
  line-height: 26px;
`

const Display = css`
  font-family: Heebo-Regular, sans-serif;
  font-size: 34px;
  letter-spacing: 0;
  line-height: 44px;
`

const baseFonts = [
  {
    name: 'Tag',
    css: css`
      ${Tag};
    `,
  },
  {
    name: 'TagBold',
    css: css`
      ${Tag};
      font-family: Heebo-Medium, sans-serif;
    `,
  },
  {
    name: 'Caption',
    css: css`
      ${Caption};
    `,
  },
  {
    name: 'CaptionBold',
    css: css`
      ${Caption};
      font-family: Heebo-Medium, sans-serif;
    `,
  },
  {
    name: 'Body2',
    css: css`
      ${Body2};
    `,
  },
  {
    name: 'Body2Bold',
    css: css`
      ${Body2};
      font-family: Heebo-Medium, sans-serif;
    `,
  },
  {
    name: 'Body1',
    css: css`
      ${Body1};
    `,
  },
  {
    name: 'Body1Bold',
    css: css`
      ${Body1};
      font-family: Heebo-Medium, sans-serif;
    `,
  },
  {
    name: 'H3',
    css: css`
      ${H3};
    `,
  },
  {
    name: 'H3Bold',
    css: css`
      ${H3};
      font-family: Heebo-Medium, sans-serif;
    `,
  },
  {
    name: 'H2',
    css: css`
      ${H2};
    `,
  },
  {
    name: 'H2Bold',
    css: css`
      ${H2};
      font-family: Heebo-Medium, sans-serif;
    `,
  },
  {
    name: 'H1',
    css: css`
      ${H1};
    `,
  },
  {
    name: 'H1Bold',
    css: css`
      ${H1};
      font-family: Heebo-Medium, sans-serif;
    `,
  },
  {
    name: 'Display',
    css: css`
      ${Display};
    `,
  },
  {
    name: 'DisplayBold',
    css: css`
      ${Display};
      font-family: Heebo-Medium, sans-serif;
    `,
  },
]

const fonts: {
  [key: string]: { Normal: string; Secondary: string; Disabled: string }
} = baseFonts.reduce(
  (sum, font) => ({
    ...sum,
    [font.name]: {
      Normal: css`
        ${font.css};
      `,
      Secondary: css`
        ${font.css};
        ${Secondary};
      `,
      Disabled: css`
        ${font.css};
        ${Disabled};
      `,
    },
  }),
  {},
)

export default fonts
