import React from 'react'
import { observer } from 'mobx-react'
import { connect, ConnectedComponent } from '#utils/index'
import FlightDataStore from '#stores/FlightDataStore'
import Icon from '#components/Icon'
import * as S from './style'

interface Props {
  forwardedRef?: React.RefObject<HTMLDivElement>
}

interface Stores {
  flightDataStore: FlightDataStore
}

@connect('flightDataStore')
@observer
class Nav extends ConnectedComponent<Props, Stores> {
  public state = {}

  public render(): JSX.Element {
    const {
      flightDataStore: { destinationName, estimatedArrivalTimeString, timeUntilLanding },
    } = this.stores

    return (
      <S.Container ref={this.props.forwardedRef}>
        <S.GlobalActiveNavStyle />
        <S.LeftContainer>
          <S.Title>Time to land</S.Title>
          <S.Value>{timeUntilLanding}</S.Value>
        </S.LeftContainer>
        <S.NavItems>
          <S.NavItem
            to="/map"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/map'
            }}
          >
            <S.IconContainer>
              <Icon type="map" />
            </S.IconContainer>
            <S.NavItemLabel>Map</S.NavItemLabel>
          </S.NavItem>

          <S.NavItem
            to="/entertainment"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/entertainment'
            }}
          >
            <S.IconContainer>
              <Icon type="entertainment" />
            </S.IconContainer>
            <S.NavItemLabel>Entertainment</S.NavItemLabel>
          </S.NavItem>

          <S.NavItem
            to="/flight-info"
            activeClassName={S.activeNavItemClassName}
            onClick={(): void => {
              // TODO: this is temporary
              window.location.href = '/flight-info'
            }}
          >
            <S.IconContainer>
              <Icon type="plane" />
            </S.IconContainer>
            <S.NavItemLabel>Flight Info</S.NavItemLabel>
          </S.NavItem>
        </S.NavItems>

        <S.RightContainer>
          <S.Title>{destinationName} landing time</S.Title>
          <S.Value>{estimatedArrivalTimeString}</S.Value>
        </S.RightContainer>
      </S.Container>
    )
  }
}

export default Nav
