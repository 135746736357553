import Cesium from 'cesium'
import { action, computed, observable, runInAction, reaction } from 'mobx'
import * as dateFnsTz from 'date-fns-tz'
import * as dateFns from 'date-fns'
import Constants from '#constants/index'
import { getTimeDifferenceString, metersToFeet, feetToMiles } from '#utils/index'
import TransportLayer, {
  FlightDataAutoCameraFlightPhaseFromServer,
  FlightDataTimingsFromServer,
} from '#services/TransportLayer'
import { FlightPhaseName } from '#stores/AutoCameraStore'

// import flightData from '#constants/flightData-jfk'

export class Position {
  public timeInSeconds: number
  public timeJulian: Cesium.JulianDate
  public flightPositionCartesian3: Cesium.Cartesian3
  public flightPhaseName?: FlightPhaseName

  public constructor({
    timeInSeconds,
    timeJulian,
    flightPositionCartesian3,
    flightPhaseName,
  }: {
    timeInSeconds: number
    timeJulian: Cesium.JulianDate
    flightPositionCartesian3: Cesium.Cartesian3
    flightPhaseName?: FlightPhaseName
  }) {
    this.timeInSeconds = timeInSeconds
    this.timeJulian = timeJulian
    this.flightPositionCartesian3 = flightPositionCartesian3
    this.flightPhaseName = flightPhaseName
  }
}

export default class FlightDataStore {
  // Timings from server
  @observable public shouldRefresh = false
  @observable public originName!: string
  public originCodeIATA!: string
  public originLatitude!: number
  public originLongitude!: number
  @observable public originTimezone!: string
  @observable public destinationName!: string
  public destinationCodeIATA!: string
  public destinationLatitude!: number
  public destinationLongitude!: number
  @observable public destinationTimezone!: string
  @observable public flightName!: string
  public estimatedDurationInSeconds!: number
  public departureTime!: Date
  @observable public altitudeInMeters!: number
  @observable public airspeedInKnots!: number
  @observable public currentTime!: Date
  public aircraftTimezone!: string
  public currentLatitude!: number
  public currentLongitude!: number
  public flightPhase!: FlightPhaseName
  public timeElapsedInSeconds!: number
  public verticalSpeedInMetersPerSecond!: number
  public headingInDegrees!: number
  public distanceFromOriginInMeters!: number
  @observable public distanceFromDestinationInMeters!: number
  @observable public estimatedArrivalTime!: Date

  // TODO: not sure about these
  @observable public loaded = false
  @observable public positions: Position[] = []
  @observable public simulationStartTime?: Cesium.JulianDate

  public aircraftEntity?: Cesium.Entity
  public autoCameraFlightPhases?: FlightDataAutoCameraFlightPhaseFromServer[]
  public simulationEndTime?: Cesium.JulianDate

  private transportLayer: TransportLayer
  // TODO: this should come from the server first, then look for user settings eventually
  private systemOfMeasurement = 'IMPERIAL'

  // TODO: is this needed?
  @computed public get flightIsRunning(): boolean {
    if (!this.simulationStartTime || !this.currentTime) {
      return false
    }

    return true
  }

  @computed public get originTimeString(): string {
    if (this.originTimezone) {
      const originDate = dateFnsTz.utcToZonedTime(this.currentTime, this.originTimezone)

      return dateFnsTz.format(originDate, 'h:mm bbbb zzz', {
        timeZone: this.originTimezone,
      })
    }

    return ''
  }

  @computed public get destinationTimeString(): string {
    if (this.destinationTimezone) {
      const destinationDate = dateFnsTz.utcToZonedTime(this.currentTime, this.destinationTimezone)

      return dateFnsTz.format(destinationDate, 'h:mm bbbb zzz', {
        timeZone: this.destinationTimezone,
      })
    }

    return ''
  }

  @computed public get flightPhaseString(): string {
    switch (this.flightPhase) {
      case 'ASCENT':
        return 'Ascent'

      case 'LEVEL_CRUISE':
        return 'Cruise'

      case 'DESCENT':
        return 'Descent'

      default:
        return ''
    }
  }

  @computed public get altitudeInFeet(): number {
    return metersToFeet(this.altitudeInMeters)
  }

  @computed public get distanceToDestinationInMiles(): number {
    return feetToMiles(metersToFeet(this.distanceFromDestinationInMeters))
  }

  @computed public get timeUntilLanding(): string {
    if (!this.estimatedArrivalTime || this.estimatedArrivalTime < this.currentTime) {
      return '--'
    }

    return getTimeDifferenceString(this.estimatedArrivalTime, this.currentTime, {
      format: 'long',
      showSeconds: false,
    })
  }

  @computed public get estimatedArrivalTimeString(): string {
    if (this.estimatedArrivalTime && this.destinationTimezone) {
      const estimatedArrivalDateTime = dateFnsTz.utcToZonedTime(
        this.estimatedArrivalTime,
        this.destinationTimezone,
      )

      return dateFnsTz.format(estimatedArrivalDateTime, 'h:mm bbbb zzz', {
        timeZone: this.destinationTimezone,
      })
    }

    return ''
  }

  @computed public get altitudeString(): string {
    if (this.systemOfMeasurement === 'METRIC') {
      return `${this.altitudeInMeters.toLocaleString()} m`
    }

    const altitudeInFeet = metersToFeet(this.altitudeInMeters)

    return `${Math.round(altitudeInFeet).toLocaleString()} ft`
  }

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
    this.fetchPositions()
    this.initWebSocketListener()
  }

  private initWebSocketListener = (): void => {
    reaction(
      (): boolean => this.transportLayer.webSocketIsConnected,
      (webSocketIsConnected: boolean): void => {
        if (webSocketIsConnected) {
          this.transportLayer.webSocketClient.subscribe(
            '/flight-data/timings',
            this.receiveFlightDataTimingsFromServer,
          )
        }
      },
      {
        fireImmediately: true,
      },
    )
  }

  @action private fetchPositions = async (): Promise<void> => {
    try {
      const {
        positions,
        autoCameraFlightPhases,
        ...timings
      } = await this.transportLayer.getFlightPositions()
      const sampledPositions = new Cesium.SampledPositionProperty()

      // sampledPositions.backwardExtrapolationDuration = 1
      // sampledPositions.backwardExtrapolationType = Cesium.ExtrapolationType.HOLD
      // sampledPositions.setInterpolationOptions({
      //   interpolationAlgorithm: Cesium.LinearApproximation,
      //   // interpolationDegree: 10000000,
      // })

      // const sampledOrientations = new Cesium.SampledProperty(Cesium.Quaternion)

      this.receiveFlightDataTimingsFromServer(timings)

      // TODO: is this needed?
      const simulationStartTime = Cesium.JulianDate.fromDate(this.departureTime)
      const simulationEndTime = Cesium.JulianDate.addSeconds(
        simulationStartTime,
        this.estimatedDurationInSeconds,
        new Cesium.JulianDate(),
      )

      const massagedPositions: Position[] = []

      positions.forEach(
        ({
          timeInSeconds,
          longitude,
          latitude,
          altitudeInFeet,
          flightPhaseName,
        }: {
          timeInSeconds: number
          longitude: number
          latitude: number
          altitudeInFeet: number
          flightPhaseName?: string
        }): void => {
          const timeJulian = Cesium.JulianDate.addSeconds(
            simulationStartTime,
            timeInSeconds,
            new Cesium.JulianDate(),
          )
          const flightPositionCartesian3 = Cesium.Cartesian3.fromDegrees(
            longitude,
            latitude,
            altitudeInFeet * 0.8,
          )

          const position = new Position({
            timeInSeconds,
            timeJulian,
            flightPositionCartesian3,
            flightPhaseName: flightPhaseName as FlightPhaseName,
          })

          massagedPositions.push(position)
          sampledPositions.addSample(timeJulian, flightPositionCartesian3)
        },
      )

      sampledPositions.setInterpolationOptions({
        interpolationDegree: 50,
        interpolationAlgorithm: Cesium.LagrangePolynomialApproximation,
      })

      const orientation = new Cesium.VelocityOrientationProperty(sampledPositions)

      const tempTranslation = new Cesium.Cartesian3(0, 0, 1.5)
      const tempRotation = Cesium.Quaternion.IDENTITY
      const tempScale = new Cesium.Cartesian3(1.0, 1.0, 1.0)

      // @ts-ignore
      const nodeTransformations = new Cesium.PropertyBag({
        // @ts-ignore
        AuxScene: new Cesium.TranslationRotationScale(tempTranslation, tempRotation, tempScale),
      })

      this.aircraftEntity = new Cesium.Entity({
        id: 'aircraft',
        availability: new Cesium.TimeIntervalCollection([
          new Cesium.TimeInterval({
            start: simulationStartTime,
            stop: simulationEndTime,
          }),
        ]),
        position: sampledPositions,
        orientation,
        // @ts-ignore
        // model: Cesium.Model.fromGltf({
        //   url: `${Constants.REACT_APP_PILOT_URL}/static/aircraft-model`,

        // })
        // @ts-ignore
        model: new Cesium.ModelGraphics({
          uri: `${Constants.REACT_APP_PILOT_URL}/static/aircraft-model`,
          minimumPixelSize: 96,
          shadows: Cesium.ShadowMode.RECEIVE_ONLY,
          runAnimations: false,
          nodeTransformations,
          // @ts-ignore
          // colorBlendMode: Cesium.ColorBlendMode.MIX,
          colorBlendAmount: 1,

          // scale: 0.25,
          // colorBlendAmount: 1,
        }),
      })

      // this.aircraftEntity.model.dequantizeInShader = true
      // this.aircraftEntity.model.debugWireframe = true

      const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

      if (!isiOS) {
        this.aircraftEntity.path = new Cesium.PathGraphics({
          resolution: 30,
          material: new Cesium.ColorMaterialProperty(new Cesium.Color(1, 1, 1, 0.2)),
          width: 10,
          leadTime: 0,
        })
      }

      // ;(this.aircraftEntity.position as Cesium.SampledPositionProperty).setInterpolationOptions({
      //   interpolationDegree: 50,
      //   interpolationAlgorithm: Cesium.LagrangePolynomialApproximation,
      // })

      this.autoCameraFlightPhases = autoCameraFlightPhases

      runInAction(
        (): void => {
          this.simulationStartTime = simulationStartTime
          this.simulationEndTime = simulationEndTime
          this.positions = massagedPositions
          this.loaded = true
        },
      )
    } catch (e) {
      // TODO: ?
      console.log('e: ', e)
    }
  }

  @action private receiveFlightDataTimingsFromServer = (
    message: FlightDataTimingsFromServer,
  ): void => {
    // if (message.reset) {
    //   console.log('RESET')
    //   this.shouldRefresh = true
    //   // window.location.reload(true)
    // } else {
    //   this.shouldRefresh = false
    // }

    let locationDiscrepancyIsTooLarge = false

    if (this.currentLatitude && this.currentLongitude) {
      const coordinateDiscrepancy = Math.sqrt(
        (message.currentLatitude - this.currentLatitude) ** 2 +
          (message.currentLongitude - this.currentLongitude) ** 2,
      )

      if (coordinateDiscrepancy >= 0.02) {
        locationDiscrepancyIsTooLarge = true
      }
    }

    let timeDiscrepancyIsTooLarge = false

    if (this.currentTime) {
      const secondsDiscrepancy = Math.abs(
        dateFns.differenceInSeconds(this.currentTime, new Date(message.currentTime)),
      )

      if (secondsDiscrepancy >= 10) {
        timeDiscrepancyIsTooLarge = true
      }
    }

    if (locationDiscrepancyIsTooLarge || timeDiscrepancyIsTooLarge) {
      this.shouldRefresh = true
      window.location.reload(true)
    } else {
      this.shouldRefresh = false
    }

    this.originName = message.originName
    this.originCodeIATA = message.originCodeIATA
    this.originLatitude = message.originLatitude
    this.originLongitude = message.originLongitude
    this.originTimezone = message.originTimezone
    this.destinationName = message.destinationName
    this.destinationCodeIATA = message.destinationCodeIATA
    this.destinationLatitude = message.destinationLatitude
    this.destinationLongitude = message.destinationLongitude
    this.destinationTimezone = message.destinationTimezone
    this.flightName = message.flightName
    this.estimatedDurationInSeconds = message.estimatedDurationInSeconds
    this.departureTime = new Date(message.departureTime)
    this.altitudeInMeters = message.altitudeInMeters
    this.airspeedInKnots = message.airspeedInKnots
    this.currentTime = new Date(message.currentTime)
    this.aircraftTimezone = message.aircraftTimezone
    this.currentLatitude = message.currentLatitude
    this.currentLongitude = message.currentLongitude
    this.flightPhase = message.flightPhase
    this.timeElapsedInSeconds = message.timeElapsedInSeconds
    this.verticalSpeedInMetersPerSecond = message.verticalSpeedInMetersPerSecond
    this.headingInDegrees = message.headingInDegrees
    this.distanceFromOriginInMeters = message.distanceFromOriginInMeters
    this.distanceFromDestinationInMeters = message.distanceFromDestinationInMeters
    this.estimatedArrivalTime = new Date(message.estimatedArrivalTime)
  }
}
