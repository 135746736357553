import 'whatwg-fetch'
import { observable, runInAction } from 'mobx'
import Nes from '@hapi/nes/lib/client'
// import JSONts from '#lib/JSONts'
import Constants from '#constants/index'
import { FlightPhaseName } from '#stores/AutoCameraStore'

export interface FlightDataAutoCameraFlightPhaseFromServer {
  id: string
  flightPhaseName: FlightPhaseName
  subPhaseName: string
  startTime: string
  endTime: string
  durationInSeconds: number
  startingHeading: number
  startingPitch: number
  startingRange: number
  deltaHeading: number
  deltaPitch: number
  deltaRange: number
}

export interface FlightPositionsFromServer extends FlightDataTimingsFromServer {
  positions: {
    timeInSeconds: number
    longitude: number
    latitude: number
    altitudeInFeet: number
    flightPhaseName?: FlightPhaseName
  }[]
  autoCameraFlightPhases: FlightDataAutoCameraFlightPhaseFromServer[]
}

export interface FlightDataTimingsFromServer {
  originName: string
  originCodeIATA: string
  originLatitude: number
  originLongitude: number
  originTimezone: string
  destinationName: string
  destinationCodeIATA: string
  destinationLatitude: number
  destinationLongitude: number
  destinationTimezone: string
  flightName: string
  estimatedDurationInSeconds: number
  departureTime: string
  altitudeInMeters: number
  airspeedInKnots: number
  currentTime: string
  aircraftTimezone: string
  currentLatitude: number
  currentLongitude: number
  flightPhase: FlightPhaseName
  timeElapsedInSeconds: number
  verticalSpeedInMetersPerSecond: number
  headingInDegrees: number
  distanceFromOriginInMeters: number
  distanceFromDestinationInMeters: number
  estimatedArrivalTime: string
  reset: boolean
}

export interface MusicTrackFromServer {
  _id: string
  musicAlbum_id: string
  title: string
  artistName: string
  assetPath: string
  durationInSeconds: number
  trackNumberInAlbum: number
}

export interface MusicAlbumFromServer {
  _id: string
  title: string
  artistName: string
  albumArtworkPath: string
  tracks: MusicTrackFromServer[]
}

export interface MovieFromServer {
  _id: string
  title: string
  subtitle: string
  gojiBox_id: string
  posterArtworkPath: string
  assetPath: string
  year: number
  durationInSeconds: number
  description: string
}

interface ErrorResponseBody {
  message?: string
}

// type RequestBody
// | GetGitHubAccessTokenWithCodeRequestBody
// | GetRepositoriesRequestBody
// | ProcessImagesForRepositoryRequestBody

type ResponseBody = ErrorResponseBody | {}
// | AuthorizeResponseBody
// | GetRepositoriesResponseBody
// | ProcessImagesForRepositoryResponseBody

export default class TransportLayer {
  public webSocketClient = new Nes.Client(Constants.REACT_APP_PILOT_WEB_SOCKET_URL)
  @observable public webSocketIsConnected = false

  private GENERIC_ERROR = 'Something went wrong. Please try again.'
  private authToken = window.localStorage.getItem('GojiPassengerToken')

  public constructor() {
    this.connectWebSocketClient()
  }

  public getFlightPositions = (): Promise<FlightPositionsFromServer> =>
    this.get('flight-data/positions') as Promise<FlightPositionsFromServer>

  // public getFlightData = (): Promise<FlightDataFromServer> =>
  //   this.get('flight-data') as Promise<FlightDataFromServer>

  public getMusicAlbums = (): Promise<MusicAlbumFromServer[]> =>
    this.get('music-albums?populateTracks=true') as Promise<MusicAlbumFromServer[]>

  public getMovies = (): Promise<MovieFromServer[]> =>
    this.get('movies') as Promise<MovieFromServer[]>

  private connectWebSocketClient = async (): Promise<void> => {
    try {
      this.webSocketClient.onConnect = (): void =>
        runInAction(
          (): void => {
            this.webSocketIsConnected = true
          },
        )
      await this.webSocketClient.connect()
    } catch (e) {
      console.log('e: ', e)
      // TODO:
    }
  }

  // private setToken = (token: string, username: string): void => {
  //   this.authToken = token
  //   window.localStorage.setItem('GojiPassengerToken', token)
  //   window.localStorage.setItem('GojiPassengerUsername', username)
  // }

  // private removeToken = (): void => {
  //   this.authToken = null
  //   window.localStorage.removeItem('GojiPassengerToken')
  //   window.localStorage.removeItem('GojiPassengerUsername')
  // }

  private buildHeaders = (): { [key: string]: string } => {
    const defaultHeaders = {
      'Content-Type': 'application/json',
    }

    if (this.authToken) {
      return {
        ...defaultHeaders,
        Authorization: `Bearer ${this.authToken}`,
      }
    }

    return defaultHeaders
  }

  private checkStatusAndParseJSON = (response: Response): Promise<ResponseBody> =>
    new Promise(
      async (resolve, reject): Promise<void> => {
        try {
          const json = (await response.json()) as ResponseBody

          if (response.status >= 200 && response.status <= 300) {
            return resolve(json)
          }

          if (response.status === 401) {
            // this.removeToken()
            window.location.href = '/'
            // TODO: refresh token
          }

          return reject((json as ErrorResponseBody).message || this.GENERIC_ERROR)
        } catch (err) {
          if (response.status >= 200 && response.status <= 300) {
            // return resolve({})
          }

          return reject(err || this.GENERIC_ERROR)
        }
      },
    )

  private get = (endpoint: string): Promise<ResponseBody> =>
    new Promise(
      async (resolve, reject): Promise<void> => {
        try {
          const response = await window.fetch(`${Constants.REACT_APP_PILOT_URL}/${endpoint}`, {
            method: 'GET',
            headers: {
              ...this.buildHeaders(),
            },
          })

          const responseBody = await this.checkStatusAndParseJSON(response)

          return resolve(responseBody)
        } catch (err) {
          if (err instanceof Error) {
            return reject(this.GENERIC_ERROR)
          }

          return reject(err)
        }
      },
    )

  // private post = (endpoint: string, data: RequestBody): Promise<ResponseBody> =>
  //   new Promise(
  //     async (resolve, reject): Promise<void> => {
  //       try {
  //         const response = await window.fetch(`${Constants.REACT_APP_PILOT_URL}/${endpoint}`, {
  //           method: 'POST',
  //           body: JSONts.stringify(data),
  //           mode: 'cors',
  //           headers: {
  //             ...this.buildHeaders(),
  //           },
  //         })

  //         const responseBody = await this.checkStatusAndParseJSON(response)

  //         return resolve(responseBody)
  //       } catch (err) {
  //         if (err instanceof Error) {
  //           return reject(this.GENERIC_ERROR)
  //         }

  //         return reject(err)
  //       }
  //     },
  //   )
}
