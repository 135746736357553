import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled.a`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  min-width: 160px;
  ${Fonts.Caption.Normal};
  color: ${Colors.offWhite};
  cursor: pointer;

  &:hover {
    background: ${Colors.util.fade({ color: Colors.lightWhite, amount: 0.75 })};
  }
`

export const LeftIconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`

export const RightIconContainer = styled.div`
  width: 40px;
  height: 24px;
  margin-left: 16px;
`

export const Content = styled.div`
  flex: 1;
  text-align: left;
`
