import { NavLink } from 'react-router-dom'
import styled, { createGlobalStyle, css } from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled('div')`
  background: ${Colors.backgroundGradient1};
  display: flex;
  justify-items: space-between;
  z-index: 10;
  grid-area: nav;
  padding-bottom: env(safe-area-inset-bottom);
`

export const LeftRightContainer = css`
  display: none;
  color: ${Colors.offWhite};
  padding: 8px 16px;
  flex: 1;

  @media (min-width: 480px) {
    display: inline-block;
  }
`

export const LeftContainer = styled.div`
  ${LeftRightContainer};
`

export const RightContainer = styled.div`
  ${LeftRightContainer};
  text-align: right;
`

export const Title = styled.div`
  ${Fonts.TagBold.Normal};
`

export const Value = styled.div`
  ${Fonts.H3.Secondary};
`

export const NavItems = styled('div')`
  margin: 0 auto;
  display: inline-block;
`

export const NavItem = styled(NavLink)`
  width: 96px;
  height: 56px;
  display: inline-block;
  text-decoration: none;
`

export const IconContainer = styled('div')`
  color: ${Colors.offWhite};
  width: 24px;
  height: 24px;
  margin: 8px auto 4px auto;
`

export const NavItemLabel = styled('div')`
  ${Fonts.Tag.Normal};
  color: ${Colors.offWhite};
  text-align: center;
`

export const activeNavItemClassName = 'active-nav-item'

export const GlobalActiveNavStyle = createGlobalStyle`
  .active-nav-item {
    ${NavItemLabel}, ${IconContainer} {
     color: ${Colors.accent1};
    }
  }
`
