import Constants from '#constants/index'

export default [
  {
    id: 5,
    title: 'Welcome to California',
    subtitle: 'The Golden State',
    photoId: 5,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_california_is_22094799.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_california_is_22094799.jpg`,
    description:
      'It is a state with everything: idyllic beaches, world-class skiing, Hollywood glitz, vineyards and stunning national parks. If it were independent, it would be the 33rd-largest nation in the world.',
    lat: '36.7783',
    lon: '-119.4179',
    country: 'us',
    state: 'ca',
  },
  {
    id: 20,
    title: 'Los Angeles',
    subtitle: 'The City of Angels',
    photoId: 24,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_los_angeles_ss_130501514.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_los_angeles_ss_130501514.jpg`,
    description:
      'A sprawling city and home of the movie industry, L.A. is surrounded by mountain ranges and beautiful beaches.',
    lat: '34.052234',
    lon: '-118.243685',
    country: 'us',
    state: 'ca',
  },
  {
    id: 16,
    title: 'Hollywood',
    subtitle: '',
    photoId: 19,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_hollywood_is_17782451.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_hollywood_is_17782451.jpg`,
    description:
      "The splashy home of the TV and movie industry, filled with celebrities, glitz and glamour. Home to the Hollywood Walk of Fame and Grauman's Chinese Theatre.",
    lat: '34.092809',
    lon: '-118.328661',
    country: 'us',
    state: 'ca',
  },
  {
    id: 46,
    title: 'Southern California',
    subtitle: '',
    photoId: 64,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_southern_california_ss_156399356.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_southern_california_ss_156399356.jpg`,
    description:
      'From Bakersfield south to the border and including Los Angeles and San Diego, this land is justifiably famous for its pleasant year-round weather, beautiful beaches, movie stars, and a laid-back attitude.',
    lat: '33.681',
    lon: '-117.528',
    country: 'us',
    state: 'ca',
  },
  {
    id: 35,
    title: 'San Fernando',
    subtitle: 'Valley',
    photoId: 50,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_san_fernando_ss_205574500.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_san_fernando_ss_205574500.jpg`,
    description:
      '"The Valley" is home to movie stars and almost two million L.A. Suburbanites. Here are Burbank and Universal Studios. It was made famous in Frank Zappa\'s song "Valley Girl."',
    lat: '34.234',
    lon: '-118.489',
    country: 'us',
    state: 'ca',
  },
  {
    id: 12,
    title: 'Edwards',
    subtitle: 'Air Force Base',
    photoId: 15,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/ca/us_ca_edwards_ss_39332380.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_edwards_ss_39332380.jpg`,
    description:
      "Home to the US Air Force Test Pilot School and NASA's Neil Armstrong Flight Research Center, as well as test activities governed by America's aerospace industry.",
    lat: '34.924031',
    lon: '-117.891208',
    country: 'us',
    state: 'ca',
  },
  {
    id: 1,
    title: 'Bakersfield',
    subtitle: '',
    photoId: 1,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_bakersfield_ss_188598035.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_bakersfield_ss_188598035.jpg`,
    description:
      '"The Country Music capital of the West Coast," Bakersfield is home to country music singers Merle Haggard and Buck Owens. It also has the largest Basque population outside Spain.',
    lat: '35.373293',
    lon: '-119.018712',
    country: 'us',
    state: 'ca',
  },
  {
    id: 5,
    title: 'California',
    subtitle: 'The Golden State',
    photoId: 5,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_california_is_22094799.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_california_is_22094799.jpg`,
    description:
      'It is a state with everything: idyllic beaches, world-class skiing, Hollywood glitz, vineyards and stunning national parks. If it were independent, it would be the 33rd-largest nation in the world.',
    lat: '36.7783',
    lon: '-119.4179',
    country: 'us',
    state: 'ca',
  },
  {
    id: 37,
    title: 'San Joaquin',
    subtitle: 'Valley',
    photoId: 53,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_san_joaquin_ss_1582675.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_san_joaquin_ss_1582675.jpg`,
    description:
      "The breadbasket of California, the valley stretches from the Tehachapi Mountains to Sacramento, and provides many of America's fruits and vegetables.",
    lat: '36.598',
    lon: '-120.167',
    country: 'us',
    state: 'ca',
  },
  {
    id: 6,
    title: 'Catalina',
    subtitle: 'Island',
    photoId: 8,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/ca/us_ca_catalina_ss_98465000.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_catalina_ss_98465000.jpg`,
    description:
      'A haven for snorkeling and diving, Catalina is surrounded by clear waters with an abundance of marine life. There are also luxury resorts and fine dining.',
    lat: '33.385',
    lon: '-118.4167',
    country: 'us',
    state: 'ca',
  },
  {
    id: 13,
    title: 'Fresno',
    subtitle: '',
    photoId: 16,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/ca/us_ca_fresno_is_20668967.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_fresno_is_20668967.jpg`,
    description:
      'Anchor of the Mid San Joaquin Valley, this is "The Best Little City in the USA." It\'s the gateway to the national parks of the Sierra Nevada mountains: Yosemite, Sequoia, and Kings Canyon.',
    lat: '36.746842',
    lon: '-119.772587',
    country: 'us',
    state: 'ca',
  },
  {
    id: 24,
    title: 'Mount Whitney',
    subtitle: '',
    photoId: 29,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/ca/us_ca_mount_is_13542778.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_mount_is_13542778.jpg`,
    description:
      "You're looking at the highest point in the contiguous United States. At 14,505 feet, Mount Whitney towers over Sequoia National Park. But it's only 76 miles away from the lowest point in North America, in Death Valley.",
    lat: '36.578',
    lon: '-118.292',
    country: 'us',
    state: 'ca',
  },
  {
    id: 44,
    title: 'Sierra Nevada',
    subtitle: '',
    photoId: 61,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_sierra_nevada_is_37585128.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_sierra_nevada_is_37585128.jpg`,
    description:
      'Running half the length of the state, this magnificent range has the largest alpine lake in America in Tahoe, the highest point in the contiguous US on Mount Whitney, and famed Yosemite Valley.',
    lat: '37.295',
    lon: '-119.296',
    country: 'us',
    state: 'ca',
  },
  {
    id: 3326,
    title: 'Calaveras Big Trees',
    subtitle: '',
    photoId: 3892,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_calaveras_big_trees_is_13551228.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_calaveras_big_trees_is_13551228.jpg`,
    description:
      'A world-famous tourist traction since 1852, these woods have trails winding through a grove of Giant Sequoias, some as ancient as 3,000 years old and soaring to 300 feet.',
    lat: '38.2775',
    lon: '-120.30944',
    country: 'us',
    state: 'ca',
  },
  {
    id: 3536,
    title: 'Welcome to Nevada',
    subtitle: 'The Silver State',
    photoId: 4250,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/nv/us_nv_nevada_is_26284360.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/nv/us_nv_nevada_is_26284360.jpg`,
    description:
      'This desert state has Las Vegas, the vast Mojave, Lake Tahoe, the Sierra Nevada, a nuclear weapons test site, and rich gold and silver mining history.  Nevada has no state income tax.',
    lat: '38.499',
    lon: '-117.024',
    country: 'us',
    state: 'nv',
  },
  {
    id: 3527,
    title: 'Carson City',
    subtitle: '',
    photoId: 4236,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/nv/us_nv_carson_city_is_20819118.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/nv/us_nv_carson_city_is_20819118.jpg`,
    description:
      "Nevada's capital city, a scenic town just east of Lake Tahoe along the eastern edge of the Sierra Nevada, is home to the world-famous Bunny Ranch and Love Ranch brothels.",
    lat: '39.165',
    lon: '-119.767',
    country: 'us',
    state: 'nv',
  },
  {
    id: 48,
    title: 'Yosemite',
    subtitle: 'National Park',
    photoId: 66,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/ca/us_ca_yosemite_is_46145342.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_yosemite_is_46145342.jpg`,
    description:
      'A magnificent and wild park. Here are Half Dome, Yosemite Falls, clear streams, giant sequoia groves and the grand Ahwahnee Hotel with its impressive views.',
    lat: '37.825',
    lon: '-119.542',
    country: 'us',
    state: 'ca',
  },
  {
    id: 3536,
    title: 'Nevada',
    subtitle: 'The Silver State',
    photoId: 4250,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/nv/us_nv_nevada_is_26284360.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/nv/us_nv_nevada_is_26284360.jpg`,
    description:
      'This desert state has Las Vegas, the vast Mojave, Lake Tahoe, the Sierra Nevada, a nuclear weapons test site, and rich gold and silver mining history.  Nevada has no state income tax.',
    lat: '38.499',
    lon: '-117.024',
    country: 'us',
    state: 'nv',
  },
  {
    id: 3540,
    title: 'Reno',
    subtitle: '',
    photoId: 4258,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/nv/us_nv_reno_is_52088020.jpg`,
    thumbnailURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/tn/us/nv/us_nv_reno_is_52088020.jpg`,
    description:
      'Located at the base of the Sierra Nevada Mountains, "The Biggest Little City in the World" is famous for its extravagant casinos and entertainment venues.',
    lat: '39.527',
    lon: '-119.813',
    country: 'us',
    state: 'nv',
  },
  {
    id: 3541,
    title: 'Sparks',
    subtitle: '',
    photoId: 4260,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/nv/us_nv_sparks_is_26530582.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/nv/us_nv_sparks_is_26530582.jpg`,
    description:
      'A suburb of Reno, sometimes called its twin city, known for several annual attractions in its downtown Victorian Square, drawing hundreds of thousands of visitors.',
    lat: '39.541',
    lon: '-119.749',
    country: 'us',
    state: 'nv',
  },
  {
    id: 3543,
    title: 'Western Nevada',
    subtitle: '',
    photoId: 4262,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/nv/us_nv_western_nevada_is_37080130.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/nv/us_nv_western_nevada_is_37080130.jpg`,
    description:
      'This mountainous region contains the Comstock Lode, one of the richest silver finds in history, giving Nevada its nickname "The Silver State."  Here are Virginia City, Reno, Carson City, Lake Tahoe and Fernley.',
    lat: '40.182',
    lon: '-119.388',
    country: 'us',
    state: 'nv',
  },
  {
    id: 14,
    title: 'Gold Country',
    subtitle: '',
    photoId: 17,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_gold_country_ss_90135880.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_gold_country_ss_90135880.jpg`,
    description:
      'Here in 1849, people flocked to make their fortune in the California Gold Rush. Today this picturesque region is home to a multitude of wineries.',
    lat: '38.376',
    lon: '-120.494',
    country: 'us',
    state: 'ca',
  },
  {
    id: 3542,
    title: 'U.S. Route 50',
    subtitle: '',
    photoId: 4261,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/nv/us_nv_u.s._route_50_is_9677056.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/nv/us_nv_u.s._route_50_is_9677056.jpg`,
    description:
      '"The Loneliest Road in America" traverses through Lake Tahoe, Carson City, alpine forests, petroglyphs, desert valleys and ghost towns.',
    lat: '39.241',
    lon: '-117.317',
    country: 'us',
    state: 'nv',
  },
  {
    id: 43,
    title: 'Shasta Cascades',
    subtitle: '',
    photoId: 60,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/ca/us_ca_shasta_cascades_is_15996677.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/ca/us_ca_shasta_cascades_is_15996677.jpg`,
    description:
      "California's northeast corner, centered around Mount Shasta, has volcanoes, forests, and rivers.  Here are some of the state's best fly-fishing, arts, and museums.",
    lat: '41.4092',
    lon: '-122.1949',
    country: 'us',
    state: 'ca',
  },
  {
    id: 3564,
    title: 'Welcome to Oregon',
    subtitle: 'The Beaver State',
    photoId: 4288,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/or/us_or_oregon_is_10901128.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_oregon_is_10901128.jpg`,
    description:
      'Wet in the west and dry in the east, Oregon has farms, beaches, rugged coastline, forest-covered mountains, deep canyons, desert terrain and exceptionally produced pinot noir.',
    lat: '44.134',
    lon: '-120.508',
    country: 'us',
    state: 'or',
  },
  {
    id: 3555,
    title: 'Eastern Oregon',
    subtitle: '',
    photoId: 4278,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_eastern_oregon_is_42539598.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_eastern_oregon_is_42539598.jpg`,
    description:
      "The inland half of the state is isolated from the West by the Cascades. It's a dry land of cattle and sage country, as well as beautiful scenery in the high desert and mountain areas.",
    lat: '43.996',
    lon: '-119.342',
    country: 'us',
    state: 'or',
  },
  {
    id: 3560,
    title: 'Klamath Falls',
    subtitle: '',
    photoId: 4283,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_klamath_fl_3076597228.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_klamath_fl_3076597228.jpg`,
    description:
      'With wild mountains, rivers and Klamath Lake, this town has a wealth of outdoor activities as well as a diversity of wildlife. The Klamath and Modoc Indians were the first known inhabitants of the area.',
    lat: '42.224',
    lon: '-121.782',
    country: 'us',
    state: 'or',
  },
  {
    id: 3564,
    title: 'Oregon',
    subtitle: 'The Beaver State',
    photoId: 4288,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/or/us_or_oregon_is_10901128.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_oregon_is_10901128.jpg`,
    description:
      'Wet in the west and dry in the east, Oregon has farms, beaches, rugged coastline, forest-covered mountains, deep canyons, desert terrain and exceptionally produced pinot noir.',
    lat: '44.134',
    lon: '-120.508',
    country: 'us',
    state: 'or',
  },
  {
    id: 3915,
    title: 'Christmas Valley',
    subtitle: '',
    photoId: 5198,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_christmas_valley_wm_OR-154-P-1.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_christmas_valley_wm_OR-154-P-1.jpg`,
    description:
      'Named after a Peter Christman, promoters renamed it "Christmas," and built Candy Ln, Mistletoe Rd, and Comet St.  They were sued for false advertising, and a judge described the town as "arid, dusty, windy, and isolated."',
    lat: '43.237797',
    lon: '-120.670885',
    country: 'us',
    state: 'or',
  },
  {
    id: 3569,
    title: 'Portland',
    subtitle: '',
    photoId: 4295,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/or/us_or_portland_is_5615998.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_portland_is_5615998.jpg`,
    description:
      "Oregon's cultural capital is a quirky city noted for scenic beauty, a myriad of outdoor activities, numerous microbreweries and the slogan Keep Portland Weird.",
    lat: '45.512',
    lon: '-122.676',
    country: 'us',
    state: 'or',
  },
  {
    id: 3546,
    title: 'Bend',
    subtitle: '',
    photoId: 4267,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/or/us_or_bend_is_17300319.jpg`,
    thumbnailURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/tn/us/or/us_or_bend_is_17300319.jpg`,
    description:
      'Set in the high desert at the edge of the Cascades, this crown jewel of Central Oregon offers year-round recreation, its own distillery, brew pubs and fine dining.',
    lat: '44.057',
    lon: '-121.316',
    country: 'us',
    state: 'or',
  },
  {
    id: 3550,
    title: 'Central Oregon',
    subtitle: '',
    photoId: 4272,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_central_oregon_is_2063115.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_central_oregon_is_2063115.jpg`,
    description:
      "This High Desert area, home to Bend and Redmond, is an outdoor lover's paradise with rock climbing and hiking, water and snow sports.  Here are some of the country's top craft breweries.",
    lat: '42.375959',
    lon: '-122.916431',
    country: 'us',
    state: 'or',
  },
  {
    id: 3562,
    title: 'Mount Bachelor',
    subtitle: '',
    photoId: 4285,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_mount_bachelor_is_10895443.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_mount_bachelor_is_10895443.jpg`,
    description:
      '15,000 years ago, this Cascade Range volcano rose from the ground, and today it is the home of a popular ski resort.  It got its name because it stands apart from the nearby volcanoes called the Three Sisters.',
    lat: '43.979',
    lon: '-121.688',
    country: 'us',
    state: 'or',
  },
  {
    id: 3549,
    title: 'Cascade Mountains',
    subtitle: '',
    photoId: 4271,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_cascade_mountains_is_52830470.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_cascade_mountains_is_52830470.jpg`,
    description:
      'Stretching from Northern California to British Columbia, this range of thickly forested mountains and pristine rivers is part of the Pacific Ring of Fire, and home to 12 volcanoes.',
    lat: '43.954',
    lon: '-121.923',
    country: 'us',
    state: 'or',
  },
  {
    id: 3811,
    title: 'Vancouver',
    subtitle: '',
    photoId: 5069,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/wa/us_wa_vancouver_is_29768726.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/wa/us_wa_vancouver_is_29768726.jpg`,
    description:
      "Often named one of the world's top cities for quality of life, this is BC's largest city, with mountains, forests, parks, theater, art, and sports: here were the 2010 Winter Olympics.   Many movies are shot here.",
    lat: '45.632',
    lon: '-122.672',
    country: 'us',
    state: 'wa',
  },
  {
    id: 3554,
    title: 'Detroit Lake State Recreation Area',
    subtitle: 'State Recreation Area',
    photoId: 4277,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_detroit_lake_state__is_2328184.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_detroit_lake_state__is_2328184.jpg`,
    description:
      'This park on the shores of a forest-ringed, nine-mile-long lake is popular for fishing, camping and boating, with views of snow-capped Mount Jefferson as the backdrop.',
    lat: '44.719927',
    lon: '-122.188575',
    country: 'us',
    state: 'or',
  },
  {
    id: 6408,
    title: 'Gresham',
    subtitle: '',
    photoId: 6165,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/or/us_or_gresham_is_19567047.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_gresham_is_19567047.jpg`,
    description:
      'Nestled between Mount Hood and the Columbia Gorge, hosting the renowned Mount Hood Jazz Festival as well as a historic downtown and more than 300 acres of parkland.',
    lat: '45.5062',
    lon: '-122.4354',
    country: 'us',
    state: 'or',
  },
  {
    id: 3563,
    title: 'Mount Hood',
    subtitle: '',
    photoId: 4287,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_mount_hood_is_2949420.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_mount_hood_is_2949420.jpg`,
    description:
      'Fifty miles east of and very visible from Portland, this dormant volcano is the highest point in the state, over 11,000 feet.  Here are 12 glaciers, deep forests, alpine lakes, wildflowers and lots of ski slopes.',
    lat: '45.374',
    lon: '-121.695',
    country: 'us',
    state: 'or',
  },
  {
    id: 6515,
    title: 'Southwest Washington',
    subtitle: '',
    photoId: 6333,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/wa/us_wa_sw_wash_is_88634983.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/wa/us_wa_sw_wash_is_88634983.jpg`,
    description:
      'Over 200 years ago, explorers Lewis and Clark ended their 8,000-mile journey across the United States in this rugged corner of the Pacific Northwest.',
    lat: '46.123',
    lon: '-122.9068',
    country: 'us',
    state: 'wa',
  },
  {
    id: 3559,
    title: 'Hood River',
    subtitle: 'region',
    photoId: 4282,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_hood_river_is_6156865.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_hood_river_is_6156865.jpg`,
    description:
      "Renowned for its orchards and breathtaking views of the Columbia River Gorge, this is where 45 percent of the nation's winter pears are grown.  Hood River is the Windsurfing Capital of the World.",
    lat: '45.709',
    lon: '-121.511',
    country: 'us',
    state: 'or',
  },
  {
    id: 3577,
    title: 'Willamette Valley',
    subtitle: '',
    photoId: 4304,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_willamette_valley_is_4371163.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_willamette_valley_is_4371163.jpg`,
    description:
      'This fertile region, surrounded by mountains to the east, west and south, is home to more than 300 wineries, best known for producing world-class pinot noir.',
    lat: '44.759',
    lon: '-122.652',
    country: 'us',
    state: 'or',
  },
  {
    id: 3633,
    title: 'Olympia',
    subtitle: '',
    photoId: 4373,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/wa/us_wa_olympia_is_8373973.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/wa/us_wa_olympia_is_8373973.jpg`,
    description:
      'The capitol of Washington State is a beautiful city with a diverse culture, home to Evergreen State College and a large, active arts and music scene.',
    lat: '47.039',
    lon: '-122.891',
    country: 'us',
    state: 'wa',
  },
  {
    id: 3551,
    title: 'Columbia Gorge',
    subtitle: '',
    photoId: 4273,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/or/us_or_columbia_gorge_is_672662.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/or/us_or_columbia_gorge_is_672662.jpg`,
    description:
      "Legendary for windsurfing, parasailing and waterfalls, this is a wine lover's paradise with a multitude of wineries and vineyards in a 40-mile stretch.",
    lat: '45.585',
    lon: '-121.279',
    country: 'us',
    state: 'or',
  },
  {
    id: 3629,
    title: 'Mount Saint Helens',
    subtitle: '',
    photoId: 4369,
    assetURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/4k/us/wa/us_wa_mount_saint_helens_is_10310252.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/wa/us_wa_mount_saint_helens_is_10310252.jpg`,
    description:
      'At 8:32am on May 18, 1980, this volcano exploded and erupted, killing 57 people, destroying 250 homes, and scorching 230 square miles of forest, in the deadliest volcanic event in US history.',
    lat: '46.191',
    lon: '-122.193',
    country: 'us',
    state: 'wa',
  },
  {
    id: 3640,
    title: 'Tacoma',
    subtitle: '',
    photoId: 4384,
    assetURL: `${Constants.REACT_APP_MEDIA_ASSETS_URL}/poi/4k/us/wa/us_wa_tacoma_is_11568477.jpg`,
    thumbnailURL: `${
      Constants.REACT_APP_MEDIA_ASSETS_URL
    }/poi/tn/us/wa/us_wa_tacoma_is_11568477.jpg`,
    description:
      'At the foot of Mount Rainier and south of Seattle, this large port city has an artsy character, Victorian homes, a waterfront promenade, and the Museum of Glass.',
    lat: '47.255',
    lon: '-122.442',
    country: 'us',
    state: 'wa',
  },
]
