import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Title = styled.h1`
  ${Fonts.H1Bold.Normal};
  color: ${Colors.offWhite};
  font-size: 3.25vw;
  line-height: 1.2em;
  letter-spacing: -1px;
  margin: 0;
`

export const Subtitle = styled.h2`
  ${Fonts.Caption.Normal};
  color: ${Colors.offWhite};
  font-size: 1vw;
  line-height: 1.2em;
  margin: 0 0 0.5vw;
`

export const Description = styled.p`
  ${Fonts.Body2.Normal};
  color: ${Colors.offWhite};
  font-size: 1.5vw;
  line-height: 1.2em;
  margin: 0;
  padding-bottom: env(safe-area-inset-bottom);
`
