import React from 'react'
import * as dateFns from 'date-fns'
import { observer } from 'mobx-react'
import { connect, ConnectedComponent, getTimeDifferenceString } from '#utils/index'
import { Element as ScrollElement, scroller } from 'react-scroll'
import GeoRadioStore from '#stores/GeoRadioStore'
import Icon from '#components/Icon'
import * as S from './style'

interface Props {
  onClose: () => void
  isAnimating: boolean
}

interface Stores {
  geoRadioStore: GeoRadioStore
}

interface State {
  audioTrackPositionInSeconds: number
}

@connect('geoRadioStore')
@observer
export default class GeoRadioView extends ConnectedComponent<Props, Stores, State> {
  public state = {
    audioTrackPositionInSeconds: 0,
  }

  public componentDidMount(): void {
    this.stores.geoRadioStore.getGeoRadioTracks()
  }

  public componentWillUnmount(): void {
    this.stores.geoRadioStore.destroyAudioTrack()
  }

  public render(): JSX.Element {
    return (
      <S.Container>
        <S.Title>GeoRadio: Today’s Playlist</S.Title>
        <S.CloseButton onClick={this.handleCloseClick}>
          <Icon type="close" />
        </S.CloseButton>
        <S.ControlsContainer>
          {this.renderPlayPauseButton()}
          {this.renderLiveGoLive()}
        </S.ControlsContainer>
        <S.PlaylistContainer id="playlist-container">{this.renderPlaylist()}</S.PlaylistContainer>
      </S.Container>
    )
  }

  private scrollToPlaylistItem = (id: string): void => {
    scroller.scrollTo(id, {
      containerId: 'playlist-container',
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -150,
    })
  }

  private handleCloseClick = (): void => {
    const { onClose, isAnimating } = this.props

    if (!isAnimating) {
      onClose()
    }
  }

  private handlePlayPauseClick = (): void => {
    const { geoRadioStore } = this.stores

    if (geoRadioStore.currentlySelectedTrack) {
      if (geoRadioStore.isPlaying) {
        geoRadioStore.pauseTrack()
      } else {
        geoRadioStore.resumeTrack()
      }
    } else {
      geoRadioStore.changeTrack(geoRadioStore.playlist[0].id)
    }
  }

  private handlePlaylistItemClick = (id: string): void => {
    const { isAnimating } = this.props

    if (isAnimating) {
      return
    }

    this.scrollToPlaylistItem(id)

    this.stores.geoRadioStore.changeTrack(id)
  }

  private renderPlayPauseButton = (): JSX.Element => {
    return (
      <S.PlayPauseButton onClick={this.handlePlayPauseClick}>
        <Icon type={this.stores.geoRadioStore.isPlaying ? 'pause' : 'play'} />
      </S.PlayPauseButton>
    )
  }

  private renderLiveGoLive = (): JSX.Element => {
    if (this.stores.geoRadioStore.isLive) {
      return (
        <S.LiveLabel>
          <S.LiveIndicator />
          LIVE
        </S.LiveLabel>
      )
    }

    return <S.GoLiveButton onClick={this.stores.geoRadioStore.goLive}>GO LIVE</S.GoLiveButton>
  }

  private renderPlaylist = (): JSX.Element[] => {
    return this.stores.geoRadioStore.playlist.map(
      (item): JSX.Element => {
        const isActive = Boolean(
          this.stores.geoRadioStore.currentlySelectedTrack &&
            item.id === this.stores.geoRadioStore.currentlySelectedTrack.id,
        )

        return (
          <ScrollElement name={item.id} key={item.id}>
            <S.PlaylistItem
              active={isActive}
              onClick={(): void => {
                if (!isActive) {
                  this.handlePlaylistItemClick(item.id)
                }
              }}
            >
              <S.PlaylistItemTitleDescriptionDuration active={isActive}>
                <div>
                  <S.PlaylistItemTitle>{item.title}</S.PlaylistItemTitle>
                  <S.PlaylistItemDescription>{item.description}</S.PlaylistItemDescription>
                </div>

                <S.PlaylistItemDuration>
                  {getTimeDifferenceString(
                    new Date(0),
                    dateFns.addSeconds(new Date(0), item.durationInSeconds),
                    {
                      format: 'short',
                      showSeconds: true,
                    },
                  )}
                </S.PlaylistItemDuration>
              </S.PlaylistItemTitleDescriptionDuration>

              {isActive ? (
                <S.DurationProgressBar
                  progress={
                    (this.stores.geoRadioStore.audioTrackPositionInSeconds /
                      item.durationInSeconds) *
                    100
                  }
                />
              ) : null}
            </S.PlaylistItem>
          </ScrollElement>
        )
      },
    )
  }
}
