import React from 'react'
import * as THREE from 'three'
import GLTFLoader from 'three-gltf-loader'
import ThreeOrbitControls from 'three-orbit-controls'
import Constants from '#constants/index'
import * as S from './style'

const OrbitControls = ThreeOrbitControls(THREE)

class Loading extends React.PureComponent<{}> {
  private camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / (window.innerHeight - 56),
    1,
    1000,
  )

  private scene = new THREE.Scene()
  private renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
  // TODO: fix type
  private controls: any

  private constructor(props: {}) {
    super(props)

    const ambientLight = new THREE.AmbientLight(0xffffff, 1.0)

    this.camera.position.set(0, 1, 3)
    this.scene.add(ambientLight)
    this.initLighting()
    this.initControls()
  }

  public async componentDidMount(): Promise<void> {
    await this.initModel()

    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight - 56)

    const container = document.getElementById('loading-container')

    if (container) {
      container.appendChild(this.renderer.domElement)
    }

    window.addEventListener('resize', this.handleResize)

    this.renderTick()
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleResize)
  }

  public render(): JSX.Element {
    return (
      <S.Container>
        <div id="loading-container" />
        <S.LoadingText>Loading...</S.LoadingText>
      </S.Container>
    )
  }

  private handleResize = (): void => {
    this.camera.aspect = window.innerWidth / (window.innerHeight - 56)
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(window.innerWidth, window.innerHeight - 56)
  }

  private initLighting = (): void => {
    const keyLight = new THREE.DirectionalLight(new THREE.Color('hsl(30, 100%, 75%)'), 1.0)
    keyLight.position.set(-100, 0, 100)

    const fillLight = new THREE.DirectionalLight(new THREE.Color('hsl(240, 100%, 75%)'), 0.75)
    fillLight.position.set(100, 0, 100)

    const backLight = new THREE.DirectionalLight(0xffffff, 1.0)
    backLight.position.set(100, 0, -100).normalize()

    this.scene.add(keyLight)
    this.scene.add(fillLight)
    this.scene.add(backLight)
  }

  private initControls = (): void => {
    this.controls = new OrbitControls(this.camera)
    this.controls.autoRotate = true
    this.controls.enabled = true
  }

  private loadModel = (): Promise<void> =>
    new Promise(
      (resolve, reject): void => {
        const loader = new GLTFLoader()

        loader.load(
          `${Constants.REACT_APP_PILOT_URL}/static/aircraft-model`,
          // '/models/gulfstream650.gltf',
          (object): void => {
            // console.log('object: ', object)
            const aircraft = object.scene
            const scale = 0.05

            aircraft.scale.set(scale, scale, scale)
            this.scene.add(aircraft)
            resolve()
          },
          (): void => {},
          reject,
        )
      },
    )

  private initModel = (): Promise<void> =>
    new Promise(
      async (resolve, reject): Promise<void> => {
        try {
          await this.loadModel()

          resolve()
        } catch (e) {
          reject(e)
        }
      },
    )

  private renderTick = (): void => {
    requestAnimationFrame(this.renderTick)
    this.controls.update()
    this.renderer.render(this.scene, this.camera)
  }
}

export default Loading
