/* eslint-disable react/no-multi-comp */
import Cesium from 'cesium'
import React from 'react'
import * as dateFns from 'date-fns'
import { inject } from 'mobx-react'
import { RootStore } from '#stores/index' // App's root store as a singleton

export class ConnectedComponent<Props, Stores, State = {}> extends React.Component<Props, State> {
  public get stores(): Stores {
    return (this.props as {}) as Stores
  }
}

export class ConnectedPureComponent<Props, Stores, State = {}> extends React.PureComponent<
  Props,
  State
> {
  public get stores(): Stores {
    return (this.props as {}) as Stores
  }
}

export const connect = (...stores: (keyof typeof RootStore)[]): any => inject(...stores)

export const degreesToRadians = (degrees: number): number => (degrees * Math.PI) / 180

export const clamp = (min: number, value: number, max: number): number =>
  Math.min(Math.max(min, value), max)

export const normalizeRadian = (radian: number): number =>
  radian - Math.PI * 2 * Math.floor((radian + Math.PI) / (Math.PI * 2))

export const normalizeHeadingPitchRange = (
  hpr: Cesium.HeadingPitchRange,
): Cesium.HeadingPitchRange =>
  new Cesium.HeadingPitchRange(normalizeRadian(hpr.heading), normalizeRadian(hpr.pitch), hpr.range)

export const getHeadingPitchRangeDelta = (
  a: Cesium.HeadingPitchRange,
  b: Cesium.HeadingPitchRange,
): Cesium.HeadingPitchRange =>
  normalizeHeadingPitchRange(
    new Cesium.HeadingPitchRange(b.heading - a.heading, b.pitch - a.pitch, b.range - a.range),
  )

export const getTimeDifferenceString = (
  dateA: Date,
  dateB: Date,
  options: {
    format: 'long' | 'short'
    showSeconds: boolean
  } = { format: 'long', showSeconds: true },
): string => {
  const differenceInSeconds = Math.abs(dateFns.differenceInSeconds(dateA, dateB))
  const secondsInOneHour = 3600
  const hours = Math.floor(differenceInSeconds / secondsInOneHour)
  const remainingSeconds = differenceInSeconds % 3600
  const minutes = Math.floor(remainingSeconds / 60)
  const seconds = remainingSeconds % 60

  if (options.format === 'long') {
    if (hours > 0) {
      if (options.showSeconds) {
        return `${hours} hr ${minutes} min ${seconds} sec`
      }

      return `${hours} hr ${minutes} min`
    }

    // No hours
    if (minutes > 0) {
      if (options.showSeconds) {
        return `${minutes} min ${seconds} sec`
      }

      return `${minutes} min`
    }

    // No minutes - default to just showing seconds
    return `${seconds} sec`
  }

  const hoursShortStr = `${hours}`.padStart(2, '0')
  const minutesShortStr = `${minutes}`.padStart(2, '0')
  const secondsShortStr = `${seconds}`.padStart(2, '0')

  if (hours > 0) {
    if (options.showSeconds) {
      return `${hoursShortStr}:${minutesShortStr}:${secondsShortStr}`
    }

    return `${hoursShortStr}:${minutesShortStr}`
  }

  if (options.showSeconds) {
    return `${minutesShortStr}:${secondsShortStr}`
  }

  return minutesShortStr
}

export const getAircraftHeading = (
  viewer: Cesium.Viewer,
  aircraftEntity: Cesium.Entity,
  atTime?: Cesium.JulianDate,
): number => {
  const time = atTime || viewer.clock.currentTime
  const aircraftPosition = aircraftEntity.position.getValue(time)

  if (!aircraftPosition) {
    return 0
  }
  // TODO: account for last plane position
  const futureTime = Cesium.JulianDate.addSeconds(time, 1 / 60, new Cesium.JulianDate())
  const aircraftFuturePosition = aircraftEntity.position.getValue(futureTime)

  // Velocity in terms of Earth Fixed
  const worldVelocity = Cesium.Cartesian3.subtract(
    aircraftFuturePosition,
    aircraftPosition,
    new Cesium.Cartesian3(),
  )

  Cesium.Cartesian3.normalize(worldVelocity, worldVelocity)

  const worldUp = new Cesium.Cartesian3()
  const worldEast = new Cesium.Cartesian3()
  const worldNorth = new Cesium.Cartesian3()

  Cesium.Ellipsoid.WGS84.geodeticSurfaceNormal(aircraftPosition, worldUp)
  // Cesium.Cartesian3.cross({ x: 0, y: 0, z: 1 }, worldUp, worldEast) // Check that this still works
  Cesium.Cartesian3.cross(new Cesium.Cartesian3(0, 0, 1), worldUp, worldEast)
  Cesium.Cartesian3.cross(worldUp, worldEast, worldNorth)

  // Velocity in terms of local ENU
  const localVelocity = new Cesium.Cartesian3()

  localVelocity.x = Cesium.Cartesian3.dot(worldVelocity, worldEast)
  localVelocity.y = Cesium.Cartesian3.dot(worldVelocity, worldNorth)
  localVelocity.z = Cesium.Cartesian3.dot(worldVelocity, worldUp)

  // angle of travel
  // const localUp = new Cesium.Cartesian3(0, 0, 1)
  const localEast = new Cesium.Cartesian3(1, 0, 0)
  const localNorth = new Cesium.Cartesian3(0, 1, 0)

  const x = Cesium.Cartesian3.dot(localVelocity, localEast)
  const y = Cesium.Cartesian3.dot(localVelocity, localNorth)
  // const z = Cesium.Cartesian3.dot(localVelocity, localUp)

  const planeHeading = Math.atan2(x, y)
  // const planePitch = Math.asin(z)

  return planeHeading
}

export const getCameraHeightByHeadingPitchRange = (
  viewer: Cesium.Viewer,
  target: Cesium.Cartesian3,
  headingPitchRange: Cesium.HeadingPitchRange,
): number => {
  const { camera } = viewer
  const futureCamera = new Cesium.Camera(viewer.scene)

  futureCamera.position = camera.position.clone()
  futureCamera.direction = camera.direction.clone()
  futureCamera.up = camera.up.clone()
  futureCamera.right = camera.right.clone()
  // @ts-ignore TODO: fix ts
  futureCamera.frustum = camera.frustum.clone()
  futureCamera.lookAt(target, headingPitchRange)

  const futureCameraHeight = viewer.scene.globe.ellipsoid.cartesianToCartographic(
    futureCamera.positionWC,
  ).height

  return futureCameraHeight
}

export const feetToMeters = (feet: number): number => feet / 3.2802
export const feetToMiles = (feet: number): number => feet * 0.00018939
export const metersToFeet = (meters: number): number => meters * 3.2802
