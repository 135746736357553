import * as dateFns from 'date-fns'
import React from 'react'
import { RouteComponentProps, match } from 'react-router'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import Constants from '#constants/index'
import { connect, ConnectedComponent, getTimeDifferenceString } from '#utils/index'
import MusicStore, { Album } from '#stores/MusicStore'
import AVCard from '#components/AVCard'
import Button from '#components/Button'
import Icon from '#components/Icon'
import TopNav from '#components/TopNav'
import * as S from './style'

interface MatchProps extends match {
  params: {
    musicAlbum_id: string
  }
}

interface Props extends RouteComponentProps {
  match: MatchProps
}

interface Stores {
  musicStore: MusicStore
}

@connect('musicStore')
@observer
class MusicAlbumDetailView extends ConnectedComponent<Props, Stores> {
  public render(): JSX.Element | null {
    if (!this.album) {
      return null
    }

    return (
      <S.Container>
        <TopNav label="Music" to="/entertainment/music" />
        <S.ScrollContainer>
          <S.AlbumAndMetaDataContainer>
            <S.AlbumContainer>
              <AVCard
                artworkURL={`${Constants.REACT_APP_MEDIA_ASSETS_URL}/${
                  this.album.albumArtworkPath
                }`}
                showMetaData={false}
              />
            </S.AlbumContainer>
            <S.AlbumMetaData>
              <S.AlbumTitle>{this.album.title}</S.AlbumTitle>
              <S.ArtistName>{this.album.artistName}</S.ArtistName>
              <S.AlbumTracksLength>
                {this.album.tracks.length} songs,{' '}
                {getTimeDifferenceString(
                  new Date(0),
                  dateFns.addSeconds(new Date(0), this.album.duration),
                  {
                    format: 'long',
                    showSeconds: false,
                  },
                )}
              </S.AlbumTracksLength>
              <Button
                label={this.album.isSelected && this.stores.musicStore.isPlaying ? 'Pause' : 'Play'}
                onClick={this.handlePlayPauseAlbum}
              />
            </S.AlbumMetaData>
          </S.AlbumAndMetaDataContainer>
          <S.TrackListing>
            {this.album.tracks.map(
              (track, index): JSX.Element => {
                const trackIsSelected = Boolean(
                  this.stores.musicStore.currentlySelectedTrack &&
                    this.stores.musicStore.currentlySelectedTrack._id === track._id,
                )

                return (
                  <S.Track key={track._id}>
                    <button
                      type="button"
                      onClick={(): void => {
                        if (!trackIsSelected && this.album) {
                          this.stores.musicStore.changeTrack(this.album._id, track._id)
                        }
                      }}
                    >
                      <S.TrackIndex>
                        {trackIsSelected && this.stores.musicStore.isPlaying ? (
                          <S.SpeakerIcon>
                            <Icon type="speaker" />
                          </S.SpeakerIcon>
                        ) : (
                          index + 1
                        )}
                      </S.TrackIndex>
                      <S.TrackTitle selected={trackIsSelected}>{track.title}</S.TrackTitle>
                      <S.TrackDuration>
                        {dateFns.format(dateFns.addSeconds(0, track.durationInSeconds), 'm:ss')}
                      </S.TrackDuration>
                    </button>
                  </S.Track>
                )
              },
            )}
          </S.TrackListing>
        </S.ScrollContainer>
      </S.Container>
    )
  }

  @computed private get album(): Album | undefined {
    const {
      match: {
        params: { musicAlbum_id },
      },
    } = this.props

    return this.stores.musicStore.getAlbumById(musicAlbum_id)
  }

  private handlePlayPauseAlbum = (): void => {
    if (!this.album) {
      return
    }

    if (this.album.isSelected && this.stores.musicStore.isPlaying) {
      this.stores.musicStore.pauseTrack()
    } else if (this.album.isSelected) {
      this.stores.musicStore.resumeTrack()
    } else {
      this.stores.musicStore.changeTrack(this.album._id, this.album.tracks[0]._id)
    }
  }
}

export default MusicAlbumDetailView
