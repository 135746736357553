import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled('div')`
  background: ${Colors.black1};
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 24px;
  border-bottom: 1px solid ${Colors.util.fade({ color: Colors.lightWhite, amount: 0.5 })};
  padding: 16px;
`

export const Track = styled.div`
  ${Fonts.Body2.Normal};
  color: ${Colors.offWhite};
`

export const Artist = styled.div`
  ${Fonts.Caption.Normal};
  color: ${Colors.lightWhite};
`

export const ControlsContainer = styled.div`
  justify-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`

export const Controls = styled.div`
  display: flex;
  margin: 0 auto 4px;
  align-items: center;
`

export const PreviousNextButton = styled.button`
  width: 12px;
  height: 12px;
  border: none;
  background: transparent;
  color: ${props =>
    props.disabled ? Colors.util.fade({ color: Colors.offWhite, amount: 0.5 }) : Colors.offWhite};
  display: block;
  padding: 0;
  margin: 0 32px 0 0;

  &:last-child {
    margin-right: 0;
  }
`

export const PlayPauseButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background: transparent;
  color: ${props =>
    props.disabled ? Colors.util.fade({ color: Colors.offWhite, amount: 0.5 }) : Colors.offWhite};
  display: block;
  margin: 0 32px 0 0;
  padding: 0;
`

export const ScrubberContainer = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
`

export const Timestamp = styled.div`
  ${Fonts.Caption.Normal};
  color: ${Colors.lightWhite};
`

export const Scrubber = styled.div`
  background: ${Colors.black2};
  border-radius: 2px;
  height: 4px;
  margin: 0 16px;
  flex: 1;
  position: relative;
`

export const ScrubberHighlight = styled.div`
  background: ${Colors.util.fade({ color: Colors.accent2, amount: 0.5 })};
  border-radius: 2px;
  height: 4px;
  top: 0;
  left: 0;
  transition: width 1s linear;
`
