import styled from 'styled-components'
import Colors from '#styles/colors'
import Shadows from '#styles/shadows'
import Fonts from '#styles/fonts'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${Colors.backgroundGradient1};
  border-left: 1px solid ${Colors.util.fade({ color: Colors.white, amount: 0.9 })};
  ${Shadows.Shadow5OnDark};
  display: flex;
  flex-direction: column;
  color: ${Colors.offWhite};
`

export const Title = styled.div`
  ${Fonts.H1.Normal};
  padding: 16px;
`

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  border: 0;
  background: none;
  padding: 0;
  color: ${Colors.offWhite};
`

export const ControlsContainer = styled.div`
  padding: 0 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`

export const PlayPauseButton = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  padding: 0;
  margin: 0 24px 0 0;
  color: ${Colors.offWhite};
`

export const LiveLabel = styled.div`
  ${Fonts.Caption.Normal};
  display: flex;
  align-items: center;
`

export const LiveIndicator = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: ${Colors.accent3};
  margin-right: 4px;
`

export const GoLiveButton = styled.button`
  ${Fonts.CaptionBold.Normal};
  color: ${Colors.black1};
  background: ${Colors.offWhite};
  border-radius: 38px;
  height: 20px;
  border: none;
`

export const PlaylistContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`

export const PlaylistItem = styled.button<{ active: boolean }>`
  background: ${(props): string =>
    props.active ? Colors.util.lighten({ color: Colors.black1, amount: 0.4 }) : Colors.black1};
  color: ${Colors.offWhite};
  border: none;
  border-left: ${(props): string => (props.active ? `2px solid ${Colors.accent1}` : 'none')};
  text-align: left;
  width: 100%;
  padding: 0;
  margin: 0;
`

export const PlaylistItemTitleDescriptionDuration = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  padding-left: ${(props): string => (props.active ? '14px' : '16px')};
`

export const PlaylistItemTitle = styled.span`
  display: block;
  ${Fonts.Body2Bold.Normal};
`

export const PlaylistItemDescription = styled.span`
  display: block;
  ${Fonts.Caption.Secondary};
  transform: translate3d(0, 0, 0);
`

export const PlaylistItemDuration = styled.span`
  display: block;
  ${Fonts.Caption.Disabled};
  transform: translate3d(0, 0, 0);
`

export const DurationProgressBar = styled.span<{ progress: number }>`
  display: block;
  height: 2px;
  background-color: ${Colors.util.fade({ color: Colors.accent2, amount: 0.5 })};
  transition: width 1s linear;
  width: 0%;
  width: ${(props): string => `${props.progress}%`};
`
