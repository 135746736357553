import Cesium from 'cesium'
import Camera from '#lib/Camera'
import { getHeadingPitchRangeDelta, getCameraHeightByHeadingPitchRange } from '#utils/index'
import Constants from '#constants/index'

interface Props {
  camera: Camera
  duration: number
  endingHeadingPitchRange?:
    | Cesium.HeadingPitchRange
    | ((currentTime: Cesium.JulianDate) => Cesium.HeadingPitchRange)
  startingHeading?: number
  startingPitch?: number
  startingRange?: number
  deltaHeading?: number
  deltaPitch?: number
  deltaRange?: number
  headingEasingFunction?: Cesium.EasingFunction.Callback
  pitchEasingFunction?: Cesium.EasingFunction.Callback
  rangeEasingFunction?: Cesium.EasingFunction.Callback
  endingTarget?: Cesium.Cartesian3 | ((currentTime: Cesium.JulianDate) => Cesium.Cartesian3)
  onComplete?: () => void
  setFixedTargetAtEnd?: boolean
  cancelable?: boolean
}
export default class CameraAnimation {
  public isComplete = false
  public totalFrames: number
  public isCancelable: boolean

  private camera: Camera
  private isPlaying = false
  private currentFrame: number
  private endingHeadingPitchRange?:
    | Cesium.HeadingPitchRange
    | ((currentTime: Cesium.JulianDate) => Cesium.HeadingPitchRange)

  private startingHeading?: number
  private startingPitch?: number
  private startingRange?: number
  private deltaHeading?: number
  private deltaPitch?: number
  private deltaRange?: number
  private headingEasingFunction: Cesium.EasingFunction.Callback
  private pitchEasingFunction: Cesium.EasingFunction.Callback
  private rangeEasingFunction: Cesium.EasingFunction.Callback
  private startingHeadingPitchRange: Cesium.HeadingPitchRange
  // private startingTarget: Cesium.Cartesian3
  // private endingTarget?: Cesium.Cartesian3 | ((currentTime: Cesium.JulianDate) => Cesium.Cartesian3)

  private onComplete: () => void
  // private setFixedTargetAtEnd = false
  // private endingTime: Cesium.JulianDate
  // private aircraftEndingPosition: Cesium.Cartesian3

  public constructor({
    camera,
    duration,
    endingHeadingPitchRange,
    startingHeading,
    startingPitch,
    startingRange,
    deltaHeading,
    deltaPitch,
    deltaRange,
    headingEasingFunction = Cesium.EasingFunction.LINEAR_NONE,
    pitchEasingFunction = Cesium.EasingFunction.LINEAR_NONE,
    rangeEasingFunction = Cesium.EasingFunction.LINEAR_NONE,
    // endingTarget,
    // setFixedTargetAtEnd = false,
    onComplete = (): void => {},
    cancelable = true,
  }: Props) {
    this.camera = camera
    this.totalFrames = duration * Constants.MAP_FRAME_RATE
    this.currentFrame = 0
    this.endingHeadingPitchRange = endingHeadingPitchRange
    this.startingHeading = startingHeading
    this.startingPitch = startingPitch
    this.startingRange = startingRange
    this.deltaHeading = deltaHeading
    this.deltaPitch = deltaPitch
    this.deltaRange = deltaRange
    this.headingEasingFunction = headingEasingFunction
    this.pitchEasingFunction = pitchEasingFunction
    this.rangeEasingFunction = rangeEasingFunction
    // this.startingTarget = camera.currentTarget

    this.startingHeadingPitchRange = this.camera.currentHeadingPitchRange
    // this.endingTarget = endingTarget
    this.onComplete = onComplete
    this.isCancelable = cancelable
    // this.setFixedTargetAtEnd = setFixedTargetAtEnd

    return this
  }

  public play = (): CameraAnimation => {
    if (this.isPlaying) {
      return this
    }

    // this.startingTarget = this.camera.currentTarget
    const startingHeading = this.startingHeading
      ? this.camera.currentAircraftHeading + this.startingHeading
      : this.camera.currentHeadingPitchRange.heading
    const startingPitch = this.startingPitch
      ? this.startingPitch
      : this.camera.currentHeadingPitchRange.pitch
    const startingRange = this.startingRange
      ? this.startingRange
      : this.camera.currentHeadingPitchRange.range

    this.startingHeadingPitchRange = new Cesium.HeadingPitchRange(
      startingHeading,
      startingPitch,
      startingRange,
    )

    this.isPlaying = true

    return this
  }

  public seek = (frameNumber: number): CameraAnimation => {
    this.currentFrame = frameNumber

    return this
  }

  public tick = (): void => {
    if (!this.isPlaying) {
      return
    }

    this.currentFrame += 1

    const progress = this.currentFrame / this.totalFrames
    const easedHeadingProgress = this.headingEasingFunction(progress)
    const easedPitchProgress = this.pitchEasingFunction(progress)
    const easedRangeProgress = this.rangeEasingFunction(progress)

    let newHeadingPitchRange

    let deltaToEndingHeadingPitchRange
    // let deltaTarget

    if (this.endingHeadingPitchRange) {
      let endingHeadingPitchRange: Cesium.HeadingPitchRange

      if (typeof this.endingHeadingPitchRange === 'function') {
        endingHeadingPitchRange = this.endingHeadingPitchRange(this.camera.viewer.clock.currentTime)
      } else {
        endingHeadingPitchRange = this.endingHeadingPitchRange as Cesium.HeadingPitchRange
      }

      deltaToEndingHeadingPitchRange = getHeadingPitchRangeDelta(
        this.startingHeadingPitchRange,
        endingHeadingPitchRange,
      )
    }

    if (deltaToEndingHeadingPitchRange) {
      newHeadingPitchRange = new Cesium.HeadingPitchRange(
        this.startingHeadingPitchRange.heading +
          deltaToEndingHeadingPitchRange.heading * easedHeadingProgress,
        this.startingHeadingPitchRange.pitch +
          deltaToEndingHeadingPitchRange.pitch * easedPitchProgress,
        this.startingHeadingPitchRange.range +
          deltaToEndingHeadingPitchRange.range * easedRangeProgress,
      )
    } else {
      const heading = this.deltaHeading
        ? this.startingHeadingPitchRange.heading + this.deltaHeading * easedHeadingProgress
        : this.startingHeadingPitchRange.heading

      const pitch = this.deltaPitch
        ? this.startingHeadingPitchRange.pitch + this.deltaPitch * easedPitchProgress
        : this.startingHeadingPitchRange.pitch

      const range = this.deltaRange
        ? this.startingHeadingPitchRange.range + this.deltaRange * easedRangeProgress
        : this.startingHeadingPitchRange.range

      newHeadingPitchRange = new Cesium.HeadingPitchRange(heading, pitch, range)
    }

    if (newHeadingPitchRange) {
      // If the camera will be going under the globe, just fast forward the animation to the end
      if (
        getCameraHeightByHeadingPitchRange(
          this.camera.viewer,
          this.camera.currentTarget,
          newHeadingPitchRange,
        ) <= Constants.CAMERA_MIN_HEIGHT
      ) {
        // clear out this animation
        this.currentFrame = this.totalFrames
      } else {
        this.camera.currentHeadingPitchRange = newHeadingPitchRange
      }
    }

    // if (this.endingTarget) {
    //   let endingTarget: Cesium.Cartesian3

    //   if (typeof this.endingTarget === 'function') {
    //     endingTarget = this.endingTarget(this.camera.viewer.clock.currentTime)
    //   } else {
    //     endingTarget = this.endingTarget as Cesium.Cartesian3
    //   }

    //   deltaTarget = Cesium.Cartesian3.subtract(
    //     endingTarget,
    //     this.startingTarget,
    //     new Cesium.Cartesian3(),
    //   )
    // }

    // const target = deltaTarget
    //   ? new Cesium.Cartesian3(
    //       this.startingTarget.x + deltaTarget.x * progress,
    //       this.startingTarget.y + deltaTarget.y * progress,
    //       this.startingTarget.z + deltaTarget.z * progress,
    //     )
    //   : undefined

    // if (target) {
    //   // this.camera.currentTarget = target
    //   // if (this.current === 0 && this.setFixedTargetAtEnd) {
    //   //   this.camera.fixedTarget = true
    //   // }
    // }

    if (this.currentFrame === this.totalFrames) {
      this.isPlaying = false
      this.isComplete = true
      this.currentFrame = 0
      this.onComplete()
    }
  }
}
