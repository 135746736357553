import 'cesium/Widgets/widgets.css'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import querystring from 'query-string'
import { Transition } from 'react-transition-group'
import { observer } from 'mobx-react'
// @ts-ignore
import Div100vh from 'react-div-100vh'
import { connect, ConnectedComponent } from '#utils/index'
import FlightDataStore from '#stores/FlightDataStore'
import AutoCameraStore from '#stores/AutoCameraStore'
// import Nav from '#components/Nav'
import MainNav from '#components/MainNav'
import Constants from '#constants/index'
import Map from '#components/Map'
import MapLoading from '#components/MapLoading'
import * as S from './style'

interface Stores {
  flightDataStore: FlightDataStore
  autoCameraStore: AutoCameraStore
}

interface State {
  mapHasLoaded: boolean
  contentContainerHeight: number
}

@connect(
  'flightDataStore',
  'autoCameraStore',
)
@observer
class MapView extends ConnectedComponent<RouteComponentProps, Stores, State> {
  public state = {
    mapHasLoaded: false,
    contentContainerHeight: 100,
  }

  private mapRef: React.RefObject<Map> = React.createRef()
  private navRef: React.RefObject<HTMLDivElement> = React.createRef()

  public componentDidMount(): void {
    setTimeout(this.checkIfMapHasLoaded, 3000)

    // TODO: debounce this
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  public render(): JSX.Element {
    // const { flightPositions, geoRadioPlaylist, currentlySelectedGeoRadioTrackIndex } = this.props
    // console.log(
    //   'this.stores.flightDataStore.flightIsRunning: ',
    //   this.stores.flightDataStore.flightIsRunning,
    // )
    const { location, history, match } = this.props
    const { contentContainerHeight } = this.state

    const isBulkhead = Boolean(querystring.parse(location.search).bulkhead)

    return (
      <Div100vh>
        <S.Container>
          <S.ContentContainer style={{ height: `${contentContainerHeight}px` }}>
            {this.stores.flightDataStore.flightIsRunning &&
            this.stores.flightDataStore.loaded &&
            this.stores.autoCameraStore.loaded ? (
              <>
                <Map
                  ref={this.mapRef}
                  isBulkhead={isBulkhead}
                  showGeoRadio={location.pathname === '/map/geo-radio'}
                  showPOI={location.pathname === '/map/poi'}
                  location={location}
                  history={history}
                  match={match}
                  // flightPositions={flightPositions}
                  // geoRadioPlaylist={geoRadioPlaylist}
                  // currentlySelectedGeoRadioTrackIndex={currentlySelectedGeoRadioTrackIndex}
                />
                {this.renderLoadingScreen()}
              </>
            ) : null}
          </S.ContentContainer>
          {isBulkhead ? null : <MainNav />}
        </S.Container>
      </Div100vh>
    )
  }

  private handleWindowResize = (): void => {
    let navHeight = 0

    if (this.navRef && this.navRef.current) {
      // console.log('this.navRefL ', this.navRef.current.clientHeight)
      navHeight = this.navRef.current.clientHeight
    }

    const contentContainerHeight = window.innerHeight - navHeight

    this.setState({ contentContainerHeight })
  }

  private checkIfMapHasLoaded = (): void => {
    if (this.mapRef.current && this.mapRef.current.mapHasLoaded) {
      this.setState({ mapHasLoaded: true })
    } else {
      setTimeout(this.checkIfMapHasLoaded, Constants.MAP_LOADING_CHECK_FREQUENCY)
    }
  }

  private renderLoadingScreen = (): JSX.Element => {
    const { mapHasLoaded } = this.state

    return (
      <Transition in={!mapHasLoaded} timeout={1000} unmountOnExit enter={false}>
        {(state: 'entered'): JSX.Element => {
          return (
            <div
              style={{
                ...(S.LoadingContainerStyle as React.CSSProperties),
                ...(S.LoadingContainerTransitionStyle[state] as React.CSSProperties),
              }}
            >
              <MapLoading />
            </div>
          )
        }}
      </Transition>
    )
  }
}

export default MapView
