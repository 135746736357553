import React from 'react'
import Icon from '#components/Icon'
import * as S from './style'

interface Props {
  label: string
  to: string
}

const TopNav = ({ label, to }: Props) => (
  <S.Container>
    <S.Link to={to}>
      <S.BackIconContainer>
        <Icon type="arrow-left" />
      </S.BackIconContainer>
      {label}
    </S.Link>
  </S.Container>
)

export default TopNav
