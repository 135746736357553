import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react'
// @ts-ignore
import Div100vh from 'react-div-100vh'
import { connect, ConnectedComponent } from '#utils/index'
import MovieStore from '#stores/MovieStore'
import MainNav from '#components/MainNav'
import MovieDetailView from '#views/MovieDetailView'
import MovieListView from '#views/MovieListView'
import MoviePlayerView from '#views/MoviePlayerView'
import * as S from './style'

type Props = RouteComponentProps

interface Stores {
  movieStore: MovieStore
}

@connect('movieStore')
@observer
class MoviesView extends ConnectedComponent<Props, Stores> {
  public componentDidMount(): void {
    this.stores.movieStore.getMovies()
  }

  public render(): JSX.Element {
    const { match } = this.props
    const {
      movieStore: { movies, getMovieById },
    } = this.stores

    return (
      <Div100vh>
        <S.Container>
          <S.ContentContainer>
            <Switch>
              <Route
                exact
                path={match.path}
                render={(): JSX.Element => <MovieListView movies={movies} />}
              />
              <Route
                exact
                path="/entertainment/movies/:id"
                render={(props): JSX.Element => {
                  const { id } = props.match.params
                  const movie = getMovieById(id)

                  return <MovieDetailView movie={movie} {...props} />
                }}
              />
              <Route
                exact
                path="/entertainment/movies/:id/play"
                render={(props): JSX.Element => {
                  const { id } = props.match.params
                  const movie = getMovieById(id)

                  return <MoviePlayerView movie={movie} />
                }}
              />
            </Switch>
          </S.ContentContainer>
          <MainNav />
        </S.Container>
      </Div100vh>
    )
  }
}

export default MoviesView
