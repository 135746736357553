import React from 'react'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react'
import { connect, ConnectedComponent } from '#utils/index'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import PointOfInterestStore from '#stores/PointOfInterestStore'
import Icon from '#components/Icon'
import POIMeta from '#components/POIMeta'
import Constants from '#constants/index'
import * as S from './style'

interface Props {
  onClose: () => void
}

interface Stores {
  pointOfInterestStore: PointOfInterestStore
}

interface State {
  isPlaying: boolean
  currentSlideIndex: number
}

@connect('pointOfInterestStore')
@observer
export default class POIView extends ConnectedComponent<Props, Stores, State> {
  public state = {
    isPlaying: true,
    currentSlideIndex: 0,
  }

  private thumbnailSlider: React.RefObject<Slider> = React.createRef()
  private largeSlider: React.RefObject<Slider> = React.createRef()
  private debouncedInitSlideAnimation?: (index: number | Event) => void
  private slideShowPlayInterval?: number

  public componentDidMount(): void {
    this.debouncedInitSlideAnimation = debounce(this.initSlideAnimation, 150)

    window.addEventListener('resize', this.debouncedInitSlideAnimation)
  }

  public componentWillUnmount(): void {
    if (this.debouncedInitSlideAnimation) {
      window.removeEventListener('resize', this.debouncedInitSlideAnimation)
    }

    this.clearSlideShowInterval()
  }

  public render(): JSX.Element {
    const { isPlaying, currentSlideIndex } = this.state
    const currentPOI = this.stores.pointOfInterestStore.pointsOfInterest[currentSlideIndex]

    const slideMetaData = {
      title: currentPOI.title,
      subtitle: currentPOI.subtitle,
      description: currentPOI.description,
    }

    return (
      <S.Container>
        <S.GlobalStyle />
        <S.LargeSliderContainer id="poi-view-large">
          <Slider
            ref={this.largeSlider}
            onInit={this.initSlideAnimation}
            afterChange={this.initSlideAnimation}
            speed={1}
            fade={false}
            arrows={false}
            lazyLoad="progressive"
          >
            {this.renderLargeSlides()}
          </Slider>
        </S.LargeSliderContainer>
        <S.MetaAndThumbnailContainer>
          <S.MetaContainer>
            {/* <Title>{slideMetaData.title}</Title>
            <Subtitle>{slideMetaData.subtitle}</Subtitle>
            <Description>{slideMetaData.description}</Description> */}
            <POIMeta
              title={slideMetaData.title}
              subtitle={slideMetaData.subtitle}
              description={slideMetaData.description}
            />
          </S.MetaContainer>
          <div id="poi-view-thumbnail">
            <Slider
              ref={this.thumbnailSlider}
              arrows={false}
              infinite={false}
              focusOnSelect
              swipeToSlide
              className="slider variable-width"
              variableWidth
              beforeChange={this.handleThumbnailChange}
            >
              {this.renderThumbnailSlides()}
            </Slider>
          </div>
        </S.MetaAndThumbnailContainer>
        <S.ControlsContainer>
          <S.IconButton onClick={this.handlePreviousClick}>
            <Icon type="arrow-left" />
          </S.IconButton>
          <S.IconButton onClick={this.handlePlayPauseClick}>
            {isPlaying ? <Icon type="pause" /> : <Icon type="play" />}
          </S.IconButton>
          <S.IconButton onClick={this.handleNextClick}>
            <Icon type="arrow-right" />
          </S.IconButton>
        </S.ControlsContainer>
        <S.CloseButtonContainer>
          <S.IconButton onClick={this.handleCloseClick}>
            <Icon type="close" />
          </S.IconButton>
        </S.CloseButtonContainer>
      </S.Container>
    )
  }

  private initSlideAnimation = (index?: number | Event): void => {
    const slide = document.querySelector('#poi-view-large .slick-current')
    const image = document.querySelector('#poi-view-large .slick-current img') as HTMLElement
    const headElement = document.querySelector('head')

    if (slide && image) {
      image.style.animation = 'none'

      const previousStyleElement = document.getElementById('poi-slide-style')

      if (previousStyleElement && headElement) {
        headElement.removeChild(previousStyleElement)
      }

      if (typeof index === 'number' && this.thumbnailSlider.current) {
        this.thumbnailSlider.current.slickGoTo(index)
        this.setState({ currentSlideIndex: index })
      }

      this.clearSlideShowInterval()

      const slideHeight = slide.clientHeight
      const slideWidth = slide.clientWidth
      const ratio = 1280 / 720
      const imageWidth = slideHeight * ratio
      const remainingWidth = imageWidth - slideWidth
      const scale1 = slideWidth / imageWidth
      const scale2 = 1
      const css = `
        @keyframes poi-slide {
          0% {
            transform: scale3d(${scale1}, ${scale1}, ${scale1}) translate3d(0, 0, 0);
            opacity: 0;
          }
          5% {
            transform: scale3d(${scale1}, ${scale1}, ${scale1}) translate3d(0, 0, 0);
            opacity: 1;
          }
          30% {
            transform: scale3d(${scale1}, ${scale1}, ${scale1}) translate3d(0, 0, 0);
            opacity: 1;
          }
          45% {
            transform: scale3d(${scale2}, ${scale2}, ${scale2}) translate3d(0, 0, 0);
            opacity: 1;
          }
          70% {
            transform: scale3d(${scale2}, ${scale2}, ${scale2}) translate3d(-${remainingWidth}px, 0, 0);
            opacity: 1;
          }
          85% {
            transform: scale3d(${scale1}, ${scale1}, ${scale1}) translate3d(0, 0, 0);
            opacity: 1;
          }
          95% {
            transform: scale3d(${scale1}, ${scale1}, ${scale1}) translate3d(0, 0, 0);
            opacity: 1;
          }
          100% {
            transform: scale3d(${scale1}, ${scale1}, ${scale1}) translate3d(0, 0, 0);
            opacity: 0;
          }
        }
      `

      const styleElement = document.createElement('style')

      styleElement.id = 'poi-slide-style'
      styleElement.type = 'text/css'
      styleElement.appendChild(document.createTextNode(css))

      if (headElement) {
        headElement.appendChild(styleElement)
      }

      image.style.animation = `poi-slide ${
        Constants.POI_SLIDE_DURATION
      }s ease-in-out infinite forwards`

      this.createSlideShowInterval()
    } else {
      // Wait for image to load
      // TODO: make sure we stop this when the component gets unloaded
      setTimeout(this.initSlideAnimation, 25)
    }
  }

  private createSlideShowInterval = (): void => {
    this.slideShowPlayInterval = setInterval((): void => {
      if (this.largeSlider.current) {
        this.largeSlider.current.slickNext()
      }
    }, Constants.POI_SLIDE_DURATION * 1000)
  }

  private clearSlideShowInterval = (): void => {
    if (this.slideShowPlayInterval) {
      clearInterval(this.slideShowPlayInterval)
      this.slideShowPlayInterval = undefined
    }
  }

  private handlePreviousClick = (): void => {
    if (this.largeSlider.current) {
      this.largeSlider.current.slickPrev()
    }
  }

  private handlePlayPauseClick = (): void => {
    const { isPlaying } = this.state

    if (isPlaying) {
      this.clearSlideShowInterval()
      this.setState({ isPlaying: false })
    } else {
      this.createSlideShowInterval()
      this.setState({ isPlaying: true })
    }
  }

  private handleNextClick = (): void => {
    if (this.largeSlider.current) {
      this.largeSlider.current.slickNext()
    }
  }

  private handleCloseClick = (): void => {
    const { onClose } = this.props

    onClose()
  }

  private handleThumbnailChange = (oldIndex: number, index: number): void => {
    if (this.largeSlider.current) {
      this.largeSlider.current.slickGoTo(index)
    }
  }

  private renderLargeSlides = (): JSX.Element[] => {
    return this.stores.pointOfInterestStore.pointsOfInterest.map(
      (poi): JSX.Element => (
        <S.LargeImageContainer key={poi.id}>
          <S.LargeImage src={poi.assetURL} />
        </S.LargeImageContainer>
      ),
    )
  }

  private renderThumbnailSlides = (): JSX.Element[] => {
    return this.stores.pointOfInterestStore.pointsOfInterest.map(
      (poi): JSX.Element => (
        <S.ThumbnailImageContainer key={poi.id}>
          <S.ThumbnailImage style={{ backgroundImage: `url(${poi.thumbnailURL})` }} />
        </S.ThumbnailImageContainer>
      ),
    )
  }
}
