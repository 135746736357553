import styled from 'styled-components'
import Colors from '#styles/colors'

export const Container = styled.div`
  background: ${Colors.black2};
  display: grid;
  height: 100%;
  grid-template-areas:
    'content'
    'nav';
  grid-template-rows: 1fr min-content;
`

export const ContentContainer = styled.div`
  grid-area: content;
  overflow: hidden;
  color: ${Colors.offWhite};
`
