import styled from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled.div`
  background: ${Colors.black2};
  display: grid;
  height: 100%;
  grid-template-areas:
    'content'
    'nav';
  grid-template-rows: 1fr min-content;
`

export const ContentContainer = styled.div`
  display: flex;
  grid-area: content;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  color: ${Colors.offWhite};
`

export const CustomerLogo = styled.img`
  height: 40px;
  margin: 64px 0 32px 0;
`

export const Heading = styled.div`
  text-align: center;
`

export const FlightRoute = styled.div`
  ${Fonts.Display.Normal};
`

export const FlightNumber = styled.div`
  ${Fonts.Body2.Normal};
`

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  text-align: center;
  align-self: stretch;
  justify-content: space-around;

  > div {
    flex: 1;
  }
`

export const Label = styled.div`
  ${Fonts.H3.Normal};
`

export const Value = styled.div`
  ${Fonts.Display.Secondary};
  margin-bottom: 24px;
`

export const BrandingContainer = styled.div`
  margin-bottom: 48px;

  img {
    margin: 0 16px;
    height: 16px;
  }
`
