import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, observer } from 'mobx-react'
// import MobxReactDevTools from 'mobx-react-devtools'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { Transition } from 'react-transition-group'
// import Div100vh from 'react-div-100vh'

import Colors from '#styles/colors'
import MapView from '#views/MapView'
import EntertainmentView from '#views/EntertainmentView'
import MusicView from '#views/MusicView'
import MoviesView from '#views/MoviesView'
import FlightInfoView from '#views/FlightInfoView'
import POIMeta from '#components/POIMeta'
import { RootStore } from '#stores/index'
import { MetaContainer } from '#components/AutoplayPOIOverlay/style'

import * as serviceWorker from './serviceWorker'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Heebo-Regular';
    src: url('/fonts/Heebo-Regular.ttf');
  }

  @font-face {
    font-family: 'Heebo-Medium';
    src: url('/fonts/Heebo-Medium.ttf');
  }

  html {
    height: 100vh;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Heebo-Regular', sans-serif;
    height: 100vh;
    background: ${Colors.black2};
  }

  #root {
    height: 100vh;
    display: grid;
  }

  button {
    outline: none;
  }
`

const Container = styled('div')`
  display: grid;
  position: relative;
`

const AutoplayPOIContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 100px;
  left: 0;
  background-size: cover;
  background-position: center center;
`

const renderAutoplayPOI = (): JSX.Element | undefined => {
  const duration = 1000
  const baseStyle = {
    // backgroundImage: 'url(http://gojiair.com:3003/poi/4k/us/fl/us_fl_orlando_is_21290339.jpg)',
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
  }

  return (
    <Transition in timeout={1000} unmountOnExit>
      {(state): JSX.Element => (
        <AutoplayPOIContainer
          style={{ ...baseStyle, ...transitionStyles[state as 'entering' | 'entered'] }}
        >
          <MetaContainer>
            <POIMeta
              title="Central Florida"
              subtitle="Final launch of Space Shuttle Endeavour, 2011"
              description="The world's theme park capital region hosts Walt Disney World, SeaWorld, Universal Studios Florida, Daytona International Speedway, Kennedy Space Center, and Gatorland."
            />
          </MetaContainer>
        </AutoplayPOIContainer>
      )}
    </Transition>
  )
}

const App: React.FC = observer(
  (): JSX.Element => {
    return (
      <Provider {...RootStore}>
        <>
          <GlobalStyle />
          <Router>
            {/* <Div100vh> */}
            <Container>
              <Switch>
                <Route exact path="/" render={(): JSX.Element => <Redirect to="/map" />} />
                <Route
                  path="/poi"
                  render={(): JSX.Element => (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: 'red',
                        position: 'relative',
                      }}
                    >
                      {renderAutoplayPOI()}
                    </div>
                  )}
                />
                <Route path="/map" component={MapView} />
                <Route exact path="/entertainment" component={EntertainmentView} />
                <Route path="/entertainment/music" component={MusicView} />
                <Route path="/entertainment/movies" component={MoviesView} />
                <Route path="/flight-info" component={FlightInfoView} />
              </Switch>
            </Container>
            {/* </Div100vh> */}
          </Router>
          {/* TODO: make sure this isn't rendered in production */}
          {/* <MobxReactDevTools /> */}
        </>
      </Provider>
    )
  },
)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
