import styled, { keyframes } from 'styled-components'
import Colors from '#styles/colors'
import Fonts from '#styles/fonts'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

const pulse = keyframes`
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }
`

export const LoadingText = styled.div`
  text-align: center;
  ${Fonts.Body2.Normal};
  color: ${Colors.offWhite};
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 100;
  animation: ${pulse} 4s infinite;
  opacity: 0.25;
`
