import { css } from 'styled-components'
import Colors from './colors'

export default {
  Shadow0: css`
    box-shadow: none;
  `,
  Shadow1: css`
    box-shadow: 0 2px 4px 0 ${Colors.util.fade({ color: Colors.black1, amount: 0.9 })};
  `,
  Shadow2: css`
    box-shadow: 0 4px 8px 0 ${Colors.util.fade({ color: Colors.black1, amount: 0.9 })};
  `,
  Shadow3: css`
    box-shadow: 0 8px 16px 0 ${Colors.util.fade({ color: Colors.black1, amount: 0.9 })};
  `,
  Shadow4: css`
    box-shadow: 0 16px 24px 0 ${Colors.util.fade({ color: Colors.black1, amount: 0.9 })};
  `,
  Shadow5: css`
    box-shadow: 0 24px 32px 0 ${Colors.util.fade({ color: Colors.black1, amount: 0.9 })};
  `,
  Shadow5OnDark: css`
    box-shadow: 0 24px 32px 0 ${Colors.util.fade({ color: Colors.black1, amount: 0.5 })};
  `,
}
