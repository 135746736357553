import { action, computed, observable, runInAction } from 'mobx'
import { Howl } from 'howler'
import TransportLayer from '#services/TransportLayer'
import playlistData from '#constants/playlist-lax-sea'

export class GeoRadioTrack {
  public id: string
  public title: string
  public description: string
  public assetURL: string
  public distancePercentage: number
  public durationInSeconds: number

  public constructor(
    id: string,
    title: string,
    description: string,
    assetURL: string,
    distancePercentage: number,
    durationInSeconds: number,
  ) {
    this.id = id
    this.title = title
    this.description = description
    this.assetURL = assetURL
    this.distancePercentage = distancePercentage
    this.durationInSeconds = durationInSeconds
  }
}

export default class GeoRadioStore {
  @observable public tracksByIdMap = observable.map<string, GeoRadioTrack>()
  @observable public isPlaying = false
  @observable public isLive = false
  @observable public currentlySelectedTrack?: GeoRadioTrack
  @observable public currentlySelectedTrackSelectedByUserAction = true
  @observable public audioTrackPositionInSeconds = 0

  private transportLayer: TransportLayer
  private audioTrack?: Howl
  private audioTrackPositionInterval?: number

  public constructor(transportLayer: TransportLayer) {
    this.transportLayer = transportLayer
  }

  @computed public get playlist(): GeoRadioTrack[] {
    return Object.values(this.tracksByIdMap.toJSON())
  }

  @computed private get nextTrackInPlaylist(): GeoRadioTrack | undefined {
    if (this.currentlySelectedTrack) {
      const currentSelectedTrackId = this.currentlySelectedTrack.id
      const currentTrackIndex = this.playlist.findIndex(
        (t): boolean => t.id === currentSelectedTrackId,
      )
      const nextTrack = this.playlist[currentTrackIndex + 1]

      return nextTrack
    }

    return undefined
  }

  @action public getGeoRadioTracks = async (): Promise<void> => {
    try {
      runInAction(
        (): void => {
          playlistData.forEach((track): void => this.updateTrackFromServer(track))
        },
      )
    } catch (e) {
      console.log('e: ', e)
      runInAction(
        (): void => {
          // this.loading = false
        },
      )

      // TODO: show error
    }
  }

  @action public destroyAudioTrack = (): void => {
    this.pauseTrack()
    this.unloadAudioTrack()
    this.currentlySelectedTrack = undefined
  }

  @action public pauseTrack = (): void => {
    if (this.audioTrack) {
      this.isPlaying = false
      this.isLive = false

      this.audioTrack.pause()
      this.stopAudioTrackInterval()
    }
  }

  @action public resumeTrack = (): void => {
    if (this.audioTrack) {
      this.isPlaying = true
      this.currentlySelectedTrackSelectedByUserAction = true

      this.audioTrack.play()
      this.startAudioTrackInterval()
    }
  }

  @action public goLive = (): void => {
    // TODO: this is just hardcoded for now
    this.changeTrack(this.playlist[2].id, true, true)
  }

  @action public changeTrack = (id: string, byUserAction = true, isLive = false): void => {
    this.unloadAudioTrack()

    const track = this.getTrackById(id)

    if (!track) {
      return
    }

    this.currentlySelectedTrack = track
    this.currentlySelectedTrackSelectedByUserAction = byUserAction
    this.isLive = isLive
    this.isPlaying = true

    this.startAudioTrack()
  }

  public getTrackById = (id: string): GeoRadioTrack | undefined => {
    return this.tracksByIdMap.get(id)
  }

  private unloadAudioTrack = (): void => {
    if (this.audioTrack) {
      this.audioTrack.unload()
      this.audioTrack = undefined

      this.resetAudioTrackPosition()
    }
  }

  private startAudioTrackInterval = (): void => {
    this.stopAudioTrackInterval()
    this.tickAudioTrackInterval()
    this.audioTrackPositionInterval = setInterval(this.tickAudioTrackInterval, 1000)
  }

  private stopAudioTrackInterval = (): void => {
    if (this.audioTrackPositionInterval) {
      clearInterval(this.audioTrackPositionInterval)
      this.audioTrackPositionInterval = undefined
    }
  }

  @action private tickAudioTrackInterval = (): void => {
    this.audioTrackPositionInSeconds += 1

    if (
      this.currentlySelectedTrack &&
      this.audioTrackPositionInSeconds >= this.currentlySelectedTrack.durationInSeconds
    ) {
      this.unloadAudioTrack()
      const nextTrack = this.nextTrackInPlaylist

      if (nextTrack) {
        this.changeTrack(nextTrack.id, false)
      } else {
        runInAction(
          (): void => {
            this.destroyAudioTrack()
          },
        )
      }
    }
  }

  @action private resetAudioTrackPosition = (): void => {
    this.audioTrackPositionInSeconds = 0
  }

  private startAudioTrack = (): void => {
    if (this.currentlySelectedTrack) {
      this.audioTrack = new Howl({
        src: this.currentlySelectedTrack.assetURL,
      })

      this.resetAudioTrackPosition()
      this.audioTrack.play()

      this.audioTrack.on(
        'load',
        (): void => {
          this.startAudioTrackInterval()
        },
      )

      // TODO:
      this.audioTrack.on(
        'end',
        (): void => {
          this.unloadAudioTrack()
          const nextTrack = this.nextTrackInPlaylist

          if (nextTrack) {
            this.changeTrack(nextTrack.id, false)
          } else {
            runInAction(
              (): void => {
                this.destroyAudioTrack()
              },
            )
          }
        },
      )
    }
  }

  private updateTrackFromServer = (trackFromServer: {
    id: string
    title: string
    description: string
    assetURL: string
    distancePercentage: number
    durationInSeconds: number
  }): void => {
    const trackInStore = this.tracksByIdMap.get(trackFromServer.id)

    if (!trackInStore) {
      const track = new GeoRadioTrack(
        trackFromServer.id,
        trackFromServer.title,
        trackFromServer.description,
        trackFromServer.assetURL,
        trackFromServer.distancePercentage,
        trackFromServer.durationInSeconds,
      )

      this.tracksByIdMap.set(track.id, track)
    } else {
      // TODO:
      // albumInStore.updateFromServer(albumFromServer)
    }
  }
}
